import Acrofresh from "../assets/product-data/Acrofresh-Big.jpg";
import Empire from "../assets/product-data/Empire-Nature.jpg";
import BabyCare from "../assets/product-data/African-Baby-Care.jpg";

const productPlacholder = [
	{
		_id: "60d0aae80ad1a20984b4b7f9", // Replace with actual product ID
		batchNumber: "ABC123",
		barCode: "123456789",
		name: "Acrofresh",
		description:
			"Acrofresh is a body cream that keeps your skin fresh and smooth.",
		howToUse: "Apply on your body after bath.",
		images: [
			{
				public_id: "image1",
				url: Acrofresh,
			},
		],
		category: "60d0aae80ad1a20984b4b7fa", // Replace with actual category ID
		brand: "60d0aae80ad1a20984b4b7fz", // Replace with actual brand ID
		ratings: 4.5,
		supply: 100,
		numReviews: 10,
		benefits: ["To keeep you skin fresh", "To keep you skin smooth"],
		variants: [
			{
				quantityType: "single",
				item: [
					{
						price: 15499.99,
						size: "Medium",
						countInStock: 20,
					},
				],
			},
		],
		restockPoint: 5,
		reviews: [
			{
				user: "60d0aae80ad1a20984b4b7fc", // Replace with actual user ID
				name: "Jennifer Ike",
				rating: 4,
				comment: "Great product!",
			},
		],
		inStock: false,
		isHotSale: true,
		isPopular: true,
		employee: "60d0aae80ad1a20984b4b7fd", // Replace with actual employee ID
		createdAt: new Date("2022-01-01"),
	},
	{
		_id: "60d0aae80ad1a20984b4b7f8", // Replace with actual product ID
		batchNumber: "ABC123",
		barCode: "123456789",
		name: "Empire De Executive",
		description: "Perfume for your everyday use and royal feeling.",
		howToUse: "Spray on your body and feel the royal scent.",
		images: [
			{
				public_id: "image1",
				url: Empire,
			},
		],
		category: "60d0aae80ad1a20984b4b7fb", // Replace with actual category ID
		brand: "60d0aae80ad1a20984b4b7fy", // Replace with actual brand ID
		ratings: 4.5,
		supply: 100,
		numReviews: 10,
		benefits: ["To keep you fresh", "To keep you royal"],
		variants: [
			{
				quantityType: "single",
				item: [
					{
						price: 9999.99,
						size: "Medium",
						countInStock: 20,
					},
				],
			},
		],
		restockPoint: 5,
		reviews: [
			{
				user: "60d0aae80ad1a20984b4b7fc", // Replace with actual user ID
				name: "Michael Jonathan",
				rating: 4,
				comment: "Great product!",
			},
		],
		inStock: false,
		isHotSale: true,
		isPopular: true,
		employee: "60d0aae80ad1a20984b4b7fd", // Replace with actual employee ID
		createdAt: new Date("2022-01-01"),
	},
	{
		_id: "60d0aae80ad1a20984b4b7f7", // Replace with actual product ID
		batchNumber: "ABC123",
		barCode: "123456789",
		name: "African Baby Care",
		description: "Baby care product for your baby's skin.",
		howToUse: "Apply on your baby's skin after bath.",
		images: [
			{
				public_id: "image1",
				url: BabyCare,
			},
		],
		category: "60d0aae80ad1a20984b4b7fc", // Replace with actual category ID
		brand: "60d0aae80ad1a20984b4b7fx", // Replace with actual brand ID
		ratings: 4.5,
		supply: 100,
		numReviews: 10,
		benefits: ["To keep your baby fresh", "To keep your baby smooth"],
		variants: [
			{
				quantityType: "single",
				item: [
					{
						price: 5599.99,
						size: "Medium",
						countInStock: 20,
					},
				],
			},
		],
		restockPoint: 5,
		reviews: [
			{
				user: "60d0aae80ad1a20984b4b7fc", // Replace with actual user ID
				name: "Cynthia Ben",
				rating: 4,
				comment: "Great product!",
			},
		],
		inStock: false,
		isHotSale: true,
		isPopular: true,
		employee: "60d0aae80ad1a20984b4b7fd", // Replace with actual employee ID
		createdAt: new Date("2022-01-01"),
	},
];

const categoryPlaceholder = [
	{
		_id: "60d0aae80ad1a20984b4b7fa", // Replace with actual category ID
		name: "Acrofresh",
		image: {
			public_id: "image1",
			url: Acrofresh
		},
		icon: "icon1",
		color: "#ff0000",
		employee: "60d0aae80ad1a20984b4b7fd", // Replace with actual employee ID
	},
	{
		_id: "60d0aae80ad1a20984b4b7fb", // Replace with actual category ID
		name: "Empire",
		image: {
			public_id: "image2",
			url: Empire,
		},
		icon: "icon2",
		color: "#00ff00",
		employee: "60d0aae80ad1a20984b4b7fd", // Replace with actual employee ID
	},
	{
		_id: "60d0aae80ad1a20984b4b7fc", // Replace with actual category ID
		name: "Baby Care",
		image: {
			public_id: "image3",
			url: BabyCare,
		},
		icon: "icon3",
		color: "#0000ff",
		employee: "60d0aae80ad1a20984b4b7fd", // Replace with actual employee ID
	},
];

const brandPlaceholder = [
	{
		_id: "60d0aae80ad1a20984b4b7fz", // Replace with actual brand ID
		name: "Acrofresh",
		image: {
			public_id: "image1",
			url: Acrofresh,
		},
		icon: "icon1",
		color: "#ff0000",
		employee: "60d0aae80ad1a20984b4b7fd", // Replace with actual employee ID
	},
	{
		_id: "60d0aae80ad1a20984b4b7fy", // Replace with actual brand ID
		name: "Empire",
		image: {
			public_id: "image2",
			url: Empire,
		},
		icon: "icon2",
		color: "#00ff00",
		employee: "60d0aae80ad1a20984b4b7fd", // Replace with actual employee ID
	},
	{
		_id: "60d0aae80ad1a20984b4b7fx", // Replace with actual brand ID
		name: "Baby Care",
		image: {
			public_id: "image3",
			url: BabyCare,
		},
		icon: "icon3",
		color: "#0000ff",
		employee: "60d0aae80ad1a20984b4b7fd", // Replace with actual employee ID
	},
];

export { productPlacholder, categoryPlaceholder, brandPlaceholder };
