import React, { useState, useEffect, lazy, Suspense } from "react";
import Logo from "../assets/fullLogo.svg";
import SearchIcon from "@mui/icons-material/Search";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Badge from "@mui/material/Badge";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PersonIcon from "@mui/icons-material/Person";
import { Link } from "react-router-dom";
import { totalCartItem } from "../../redux/selectors/cartSelector";
import { useSelector, useDispatch } from "react-redux";
import { getAllBrands } from "services/brandCRUD";
import { getAllCategories } from "services/categoryCRUD";
import { animated, useTransition } from "@react-spring/web";
import MenuIcon from "../assets/menu.svg";
import Collapsible from "react-collapsible";
import MediaQuery from "../media-query/MediaQuery";
const Logout = lazy(() => import("./Logout"));
const Login = lazy(() => import("./Login"));
const Signup = lazy(() => import("./Signup"));
const HeaderModal = lazy(() => import("components/modal/HeaderModal"));

const Header = () => {
	const dispatch = useDispatch();
	const { carts } = useSelector((state) => state.carts);
	const totalItems = carts.reduce(
		(totalQuantity, item) => totalQuantity + item.quantity,
		0,
	);
	const { wishlistsItems } = useSelector((state) => state.wishlists);
	const loggedInUser = useSelector((state) => state.user.user);
	const [categories, setCategories] = useState([]);
	const [brands, setBrands] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [active, setActive] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");

	const [showMenu, setShowMenu] = useState(false);

	const maskTransitions = useTransition(showMenu, {
		from: { position: "absolute", opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
	});

	const menuTransitions = useTransition(showMenu, {
		from: { opacity: 0, transform: "translateX(-100%)" },
		enter: { opacity: 1, transform: "translateX(0%)" },
		leave: { opacity: 0, transform: "translateX(-100%)" },
	});

	function openModal() {
		setIsOpen(true);
	}

	function isClose() {
		setIsOpen(false);
	}

	useEffect(() => {
		setLoading(true);
		Promise.all([getAllCategories(), getAllBrands()])
			.then(([categoriesResponse, brandsResponse]) => {
				setCategories(categoriesResponse.data);
				setBrands(brandsResponse.data);
				setLoading(false);
			})
			.catch((error) => {
				console.error("Error fetching brands and categories:", error);
				setError(error.message);
				setLoading(false);
			});

		const handleScroll = () => {
			if (window.scrollY > 70) {
				setActive(true);
			} else {
				setActive(false);
			}
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const handleSearchIconClick = () => {
		setIsOpen(!isOpen);
	};

	const handleInputChange = (e) => {
		setSearchTerm(e.target.value);
	};

	const handleKeyPress = (e) => {
		if (e.key === "Enter") {
			// Perform search action, e.g. make API call or update search results
			console.log("Search submitted:", searchTerm);
		}
	};

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	window.addEventListener("scroll", () => {
		if (window.scrollY > 70) {
			setActive(true);
		} else {
			setActive(false);
		}
	});

	return (
		<Suspense fallback={<div>Loading...</div>}>
			<MediaQuery min="lg">
				<div
					className={`${
						active === true ? " w-full fixed top-0 left-0 z-10" : null
					} px-[40px] bg-white border-b-4 border-[#F7F7FC]`}
				>
					<div className="flex justify-between items-center">
						<div>
							<img src={Logo} alt="logo" className="w-[150px] h-[88px] object-fit" />
						</div>
						<div className="flex gap-8 flex-1 justify-center">
							<Link to="/">
								<button className="flex items-center text-black text-xl">
									<span>Home</span>
								</button>
							</Link>

							<button className="flex items-center text-black text-xl">
								<Link to="/products">
									{" "}
									<span>Products</span>{" "}
								</Link>
							</button>

							{/* <button className="flex items-center text-black text-xl">
                  <Link to="/brands">
                    <span>Brand</span>
                  </Link>
                </button> */}

							<button className="flex items-center text-black text-xl mx-2">
								<Link to="/distributors">
									<span>Distributors</span>
								</Link>
							</button>

							<button
								onClick={openModal}
								className="flex items-center text-black text-xl"
							>
								<span> Resources</span>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									width="24"
									height="24"
								>
									<path fill="none" d="M0 0h24v24H0z" />
									<path
										d="M12 15l-4.243-4.243 1.415-1.414L12 12.172l2.828-2.829 1.415 1.414z"
										fill="rgba(181,181,189,1)"
									/>
								</svg>
							</button>
						</div>
						<div>
							<div className="flex gap-7">
								<div>
									<Link to="/favorites">
										<IconButton>
											<Badge badgeContent={wishlistsItems?.length} color="primary">
												<FavoriteIcon sx={{ fontSize: 35 }} />
											</Badge>
										</IconButton>
									</Link>
								</div>

								<div>
									<Link to="/cart">
										<IconButton>
											<Badge badgeContent={totalItems} color="primary">
												<ShoppingCartIcon sx={{ fontSize: 35 }} />
											</Badge>
										</IconButton>
									</Link>
								</div>

								<div>
									<IconButton onClick={handleClick}>
										<PersonIcon sx={{ fontSize: 35 }} />
									</IconButton>
								</div>

								<Menu
									anchorEl={anchorEl}
									id="account-menu"
									open={open}
									onClose={handleClose}
									onClick={handleClose}
									PaperProps={{
										elevation: 0,
										sx: {
											overflow: "visible",
											padding: "20px",
											filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
											mt: 1.5,
											"& .MuiAvatar-root": {
												width: 62,
												height: 62,
												ml: -0.5,
												mr: 1,
											},
											"&:before": {
												content: '""',
												display: "block",
												position: "absolute",
												top: 0,
												right: 14,
												width: 10,
												height: 10,
												bgcolor: "background.paper",
												transform: "translateY(-50%) rotate(45deg)",
												zIndex: 0,
											},
										},
									}}
									transformOrigin={{ horizontal: "right", vertical: "top" }}
									anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
								>
									{loggedInUser && (
										<MenuItem onClick={handleClose}>
											<div className="flex gap-4">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 24 24"
													width="24"
													height="24"
												>
													<path fill="none" d="M0 0h24v24H0z" />
													<path d="M4 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H4zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z" />
												</svg>
												<Link to="/profile">
													<p className="font-light"> My Profile</p>
												</Link>
											</div>
										</MenuItem>
									)}

									{loggedInUser && (
										<MenuItem>
											<div className="flex gap-4">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 24 24"
													width="24"
													height="24"
												>
													<path fill="none" d="M0 0h24v24H0z" />
													<path d="M8.686 4l2.607-2.607a1 1 0 0 1 1.414 0L15.314 4H19a1 1 0 0 1 1 1v3.686l2.607 2.607a1 1 0 0 1 0 1.414L20 15.314V19a1 1 0 0 1-1 1h-3.686l-2.607 2.607a1 1 0 0 1-1.414 0L8.686 20H5a1 1 0 0 1-1-1v-3.686l-2.607-2.607a1 1 0 0 1 0-1.414L4 8.686V5a1 1 0 0 1 1-1h3.686zM6 6v3.515L3.515 12 6 14.485V18h3.515L12 20.485 14.485 18H18v-3.515L20.485 12 18 9.515V6h-3.515L12 3.515 9.515 6H6zm6 10a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
												</svg>
												<Link to="/settings">
													<p className="font-light"> Settings</p>
												</Link>
											</div>
										</MenuItem>
									)}

									{loggedInUser && (
										<MenuItem>
											<Logout />
										</MenuItem>
									)}

									{!loggedInUser && (
										<MenuItem>
											<Login />
										</MenuItem>
									)}
									{!loggedInUser && (
										<MenuItem>
											<Signup />
										</MenuItem>
									)}
								</Menu>
							</div>
						</div>
					</div>
				</div>
			</MediaQuery>

			{/* small screens start */}
			<MediaQuery max="lg">
				<div
					className={`${
						active === true ? "w-full fixed top-0 left-0 z-10" : null
					} bg-white flex items-center justify-between p-5 border-b-2 border-[#F7F7FC]`}
				>
					<div className="flex">
						<img
							src={MenuIcon}
							alt="menuicon"
							width={32}
							height={32}
							onClick={() => setShowMenu(!showMenu)}
						/>

						<p>
							{" "}
							<img src={Logo} alt="logo" className="w-[80%] h-[70%]" />{" "}
						</p>
					</div>
					<div className="flex gap-1">
						{" "}
						<div>
							<Link to="/favorites">
								<IconButton>
									<Badge badgeContent={wishlistsItems?.length} color="primary">
										<FavoriteIcon sx={{ fontSize: 25 }} />
									</Badge>
								</IconButton>
							</Link>
						</div>
						<Link to="/cart">
							<IconButton>
								<Badge badgeContent={totalItems} color="primary">
									<ShoppingCartIcon sx={{ fontSize: 25 }} />
								</Badge>
							</IconButton>
						</Link>
						<p>
							{" "}
							<IconButton onClick={handleClick}>
								<PersonIcon sx={{ fontSize: 25 }} />
							</IconButton>
						</p>{" "}
						<Menu
							anchorEl={anchorEl}
							id="account-menu"
							open={open}
							onClose={handleClose}
							onClick={handleClose}
							PaperProps={{
								elevation: 0,
								sx: {
									overflow: "visible",
									padding: "20px",
									filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
									mt: 1.5,
									"& .MuiAvatar-root": {
										width: 62,
										height: 62,
										ml: -0.5,
										mr: 1,
									},
									"&:before": {
										content: '""',
										display: "block",
										position: "absolute",
										top: 0,
										right: 14,
										width: 10,
										height: 10,
										bgcolor: "background.paper",
										transform: "translateY(-50%) rotate(45deg)",
										zIndex: 0,
									},
								},
							}}
							transformOrigin={{ horizontal: "right", vertical: "top" }}
							anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
						>
							{loggedInUser && (
								<MenuItem onClick={handleClose}>
									<div className="flex gap-4">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											width="24"
											height="24"
										>
											<path fill="none" d="M0 0h24v24H0z" />
											<path d="M4 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H4zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z" />
										</svg>
										<Link to="/profile">
											<p className="font-light"> My Profile</p>
										</Link>
									</div>
								</MenuItem>
							)}

							{loggedInUser && (
								<MenuItem>
									<div className="flex gap-4">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											width="24"
											height="24"
										>
											<path fill="none" d="M0 0h24v24H0z" />
											<path d="M8.686 4l2.607-2.607a1 1 0 0 1 1.414 0L15.314 4H19a1 1 0 0 1 1 1v3.686l2.607 2.607a1 1 0 0 1 0 1.414L20 15.314V19a1 1 0 0 1-1 1h-3.686l-2.607 2.607a1 1 0 0 1-1.414 0L8.686 20H5a1 1 0 0 1-1-1v-3.686l-2.607-2.607a1 1 0 0 1 0-1.414L4 8.686V5a1 1 0 0 1 1-1h3.686zM6 6v3.515L3.515 12 6 14.485V18h3.515L12 20.485 14.485 18H18v-3.515L20.485 12 18 9.515V6h-3.515L12 3.515 9.515 6H6zm6 10a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
										</svg>
										<Link to="/settings">
											<p className="font-light"> Settings</p>
										</Link>
									</div>
								</MenuItem>
							)}

							{loggedInUser && (
								<MenuItem>
									<Logout />
								</MenuItem>
							)}

							{!loggedInUser && (
								<MenuItem>
									<Login />
								</MenuItem>
							)}
							{!loggedInUser && (
								<MenuItem>
									<Signup />
								</MenuItem>
							)}
						</Menu>
					</div>

					{maskTransitions(
						(styles, item) =>
							item && (
								<animated.div
									className=" bg-black fixed top-0 left-0 w-full h-full z-50 opacity-50 "
									onClick={() => setShowMenu(false)}
								></animated.div>
							),
					)}

					{menuTransitions(
						(styles, item) =>
							item && (
								<animated.div
									style={styles}
									className="fixed bg-white top-0 left-0 w-3/5 z-50 h-full shadow "
								>
									<div className="py-[20px] px-[30px] ">
										<img src={Logo} alt="logo" />
									</div>
									<div className="text-[16px] text-vahlid-gray">
										<div className="pl-[32px] py-[20px]">
											<Link to="/">Home</Link>
										</div>

										<Collapsible
											trigger={
												<div className="px-[32px] py-[20px] flex justify-between">
													<Link to="/products">
														<p> Products</p>
													</Link>
													<p>
														{" "}
														<svg
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 24 24"
															width="24"
															height="24"
														>
															<path fill="none" d="M0 0h24v24H0z" />
															<path
																d="M12 15l-4.243-4.243 1.415-1.414L12 12.172l2.828-2.829 1.415 1.414z"
																fill="rgba(181,181,189,1)"
															/>
														</svg>
													</p>{" "}
												</div>
											}
										>
											<div className="bg-[#EFEFEF]">
												{categories?.map((item, index) => (
													<Link to={`/product/category/${item._id}`}>
														<p key={item._id} className="pl-[32px] py-[20px]">
															{item.name}{" "}
														</p>
													</Link>
												))}
											</div>
										</Collapsible>

										{/* <Collapsible
                      trigger={
                        <div className="px-[32px] py-[20px] flex justify-between">
                          <p> Brands</p>{" "}
                          <p>
                            {" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path
                                d="M12 15l-4.243-4.243 1.415-1.414L12 12.172l2.828-2.829 1.415 1.414z"
                                fill="rgba(181,181,189,1)"
                              />
                            </svg>
                          </p>{" "}
                        </div>
                      }
                    >
                      <div className="bg-[#EFEFEF]">
                        {brands?.map((item, index) => (
                          <p key={item._id} className="pl-[32px] py-[20px]">
                            {item.name}{" "}
                          </p>
                        ))}
                      </div>
                    </Collapsible> */}

										<div className="pl-[32px] py-[20px]">
											<Link to="/distributors">Distributors</Link>
										</div>
										<Collapsible
											trigger={
												<div className="px-[32px] py-[20px] flex justify-between">
													<p> Resources </p>{" "}
													<p>
														{" "}
														<svg
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 24 24"
															width="24"
															height="24"
														>
															<path fill="none" d="M0 0h24v24H0z" />
															<path
																d="M12 15l-4.243-4.243 1.415-1.414L12 12.172l2.828-2.829 1.415 1.414z"
																fill="rgba(181,181,189,1)"
															/>
														</svg>
													</p>{" "}
												</div>
											}
										>
											<div className="bg-[#EFEFEF]">
												<p className="pl-[32px] py-[20px]">
													{" "}
													<Link to="/aboutus"> About Us </Link>{" "}
												</p>
												<p className="pl-[32px] py-[20px]">
													<Link to="/contactus"> Contact Us </Link>
												</p>
												<p className="pl-[32px] py-[20px]">
													{" "}
													<Link to="/blog"> Blog </Link>{" "}
												</p>
											</div>
										</Collapsible>
										<div className="pl-[32px] py-[20px]">
											{" "}
											<Link to="/favorites">
												<p>Favourites</p>
											</Link>{" "}
										</div>
										<div className="pl-[32px] py-[20px]">
											<Link to="/cart">
												<p> Cart</p>{" "}
											</Link>{" "}
										</div>
									</div>
								</animated.div>
							),
					)}
				</div>
			</MediaQuery>
			{/* small screens end */}
			<HeaderModal isOpen={isOpen} isClose={isClose} />
		</Suspense>
	);
};

export default Header;
