import React from "react";
import FooterLogo from "../assets/footerlogo.png";
import MediaQuery from "../media-query/MediaQuery";

const Footer = () => {
	return (
		<div className="Footer">

			<div className="w-full bg-[#3F3F95]">
				<div className="py-[40px] px-[80px]">
					{/* small screen start */}
					<MediaQuery min="sm" max="md">
						<div className="">
							<div className="flex flex-col gap-5">
								<div className="">
									<div>
										<img src={FooterLogo} alt="" className="w-[130px] h-full" />
									</div>
									<p className="text-sm text-white py-5">
										Emos Best Industries Limited is a top-notch cosmetics manufacturing
										company in Nigeria.
									</p>
									<div className="mt-2">
										<p className="text-white text-lg">Follow us on:</p>
										<div className="flex gap-5 mt-2">
											<span className="p-2 bg-[#FAFAFD] rounded-full">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 24 24"
													width="18"
													height="18"
												>
													<path fill="none" d="M0 0h24v24H0z" />
													<path
														d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5z"
														fill="rgba(42,42,99,1)"
													/>
												</svg>
											</span>
											<span className="p-2 bg-[#FAFAFD] rounded-full">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 24 24"
													width="18"
													height="18"
												>
													<path fill="none" d="M0 0h24v24H0z" />
													<path
														d="M12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm0-2a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm6.5-.25a1.25 1.25 0 0 1-2.5 0 1.25 1.25 0 0 1 2.5 0zM12 4c-2.474 0-2.878.007-4.029.058-.784.037-1.31.142-1.798.332-.434.168-.747.369-1.08.703a2.89 2.89 0 0 0-.704 1.08c-.19.49-.295 1.015-.331 1.798C4.006 9.075 4 9.461 4 12c0 2.474.007 2.878.058 4.029.037.783.142 1.31.331 1.797.17.435.37.748.702 1.08.337.336.65.537 1.08.703.494.191 1.02.297 1.8.333C9.075 19.994 9.461 20 12 20c2.474 0 2.878-.007 4.029-.058.782-.037 1.309-.142 1.797-.331.433-.169.748-.37 1.08-.702.337-.337.538-.65.704-1.08.19-.493.296-1.02.332-1.8.052-1.104.058-1.49.058-4.029 0-2.474-.007-2.878-.058-4.029-.037-.782-.142-1.31-.332-1.798a2.911 2.911 0 0 0-.703-1.08 2.884 2.884 0 0 0-1.08-.704c-.49-.19-1.016-.295-1.798-.331C14.925 4.006 14.539 4 12 4zm0-2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2z"
														fill="rgba(42,42,99,1)"
													/>
												</svg>
											</span>
											<span className="p-2 bg-[#FAFAFD] rounded-full">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 24 24"
													width="18"
													height="18"
												>
													<path fill="none" d="M0 0h24v24H0z" />
													<path
														d="M22.162 5.656a8.384 8.384 0 0 1-2.402.658A4.196 4.196 0 0 0 21.6 4c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 0 0-7.126 3.814 11.874 11.874 0 0 1-8.62-4.37 4.168 4.168 0 0 0-.566 2.103c0 1.45.738 2.731 1.86 3.481a4.168 4.168 0 0 1-1.894-.523v.052a4.185 4.185 0 0 0 3.355 4.101 4.21 4.21 0 0 1-1.89.072A4.185 4.185 0 0 0 7.97 16.65a8.394 8.394 0 0 1-6.191 1.732 11.83 11.83 0 0 0 6.41 1.88c7.693 0 11.9-6.373 11.9-11.9 0-.18-.005-.362-.013-.54a8.496 8.496 0 0 0 2.087-2.165z"
														fill="rgba(42,42,99,1)"
													/>
												</svg>
											</span>
											<span className="p-2 bg-[#FAFAFD] rounded-full">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 24 24"
													width="18"
													height="18"
												>
													<path fill="none" d="M0 0h24v24H0z" />
													<path
														d="M6.94 5a2 2 0 1 1-4-.002 2 2 0 0 1 4 .002zM7 8.48H3V21h4V8.48zm6.32 0H9.34V21h3.94v-6.57c0-3.66 4.77-4 4.77 0V21H22v-7.93c0-6.17-7.06-5.94-8.72-2.91l.04-1.68z"
														fill="rgba(42,42,99,1)"
													/>
												</svg>
											</span>
										</div>
									</div>
								</div>

								<div className="">
									<h4 className="py-2 text-white text-lg font-bold">Contact Us</h4>

									<div className="flex gap-5 mb-4">
										<span>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 24 24"
												width="24"
												height="24"
											>
												<path fill="none" d="M0 0h24v24H0z" />
												<path
													d="M21 19h2v2H1v-2h2V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v15h4v-8h-2V9h3a1 1 0 0 1 1 1v9zM5 5v14h8V5H5zm2 6h4v2H7v-2zm0-4h4v2H7V7z"
													fill="rgba(255,255,255,1)"
												/>
											</svg>
										</span>
										<p className="text-white">
											5 Adewunmi Abudu Street off Osolo way, Ajao Estate, Lagos, Nigeria
										</p>
									</div>
									<div className="flex gap-5 mb-4">
										<span>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 24 24"
												width="24"
												height="24"
											>
												<path fill="none" d="M0 0h24v24H0z" />
												<path
													d="M21 16.42v3.536a1 1 0 0 1-.93.998c-.437.03-.794.046-1.07.046-8.837 0-16-7.163-16-16 0-.276.015-.633.046-1.07A1 1 0 0 1 4.044 3H7.58a.5.5 0 0 1 .498.45c.023.23.044.413.064.552A13.901 13.901 0 0 0 9.35 8.003c.095.2.033.439-.147.567l-2.158 1.542a13.047 13.047 0 0 0 6.844 6.844l1.54-2.154a.462.462 0 0 1 .573-.149 13.901 13.901 0 0 0 4 1.205c.139.02.322.042.55.064a.5.5 0 0 1 .449.498z"
													fill="rgba(255,255,255,1)"
												/>
											</svg>
										</span>
										<p className="text-white">+234 803 529 9325</p>
									</div>
									<div className="flex gap-5 mb-4">
										<span>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 24 24"
												width="24"
												height="24"
											>
												<path fill="none" d="M0 0h24v24H0z" />
												<path
													d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm17 4.238l-7.928 7.1L4 7.216V19h16V7.238zM4.511 5l7.55 6.662L19.502 5H4.511z"
													fill="rgba(255,255,255,1)"
												/>
											</svg>
										</span>
										<p className="text-white">info@emosbestgroup.com</p>
									</div>
								</div>
							</div>
						</div>
					</MediaQuery>
					{/* small screen end */}

					{/* medium screen start */}
					<MediaQuery min="md" max="lg">
						<div className="">
							<div className="flex flex-wrap gap-5">
								<div className="">
									<div>
										<img src={FooterLogo} alt="" className="w-[130px] h-full" />
									</div>
									<p className="text-sm text-white py-5">
										Emos Best Industries Limited is a top-notch cosmetics manufacturing
										company in Nigeria.
									</p>
									<div className="mt-2">
										<p className="text-white text-lg">Follow us on:</p>
										<div className="flex gap-5 mt-2">
											<span className="p-2 bg-[#FAFAFD] rounded-full">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 24 24"
													width="18"
													height="18"
												>
													<path fill="none" d="M0 0h24v24H0z" />
													<path
														d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5z"
														fill="rgba(42,42,99,1)"
													/>
												</svg>
											</span>
											<span className="p-2 bg-[#FAFAFD] rounded-full">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 24 24"
													width="18"
													height="18"
												>
													<path fill="none" d="M0 0h24v24H0z" />
													<path
														d="M12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm0-2a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm6.5-.25a1.25 1.25 0 0 1-2.5 0 1.25 1.25 0 0 1 2.5 0zM12 4c-2.474 0-2.878.007-4.029.058-.784.037-1.31.142-1.798.332-.434.168-.747.369-1.08.703a2.89 2.89 0 0 0-.704 1.08c-.19.49-.295 1.015-.331 1.798C4.006 9.075 4 9.461 4 12c0 2.474.007 2.878.058 4.029.037.783.142 1.31.331 1.797.17.435.37.748.702 1.08.337.336.65.537 1.08.703.494.191 1.02.297 1.8.333C9.075 19.994 9.461 20 12 20c2.474 0 2.878-.007 4.029-.058.782-.037 1.309-.142 1.797-.331.433-.169.748-.37 1.08-.702.337-.337.538-.65.704-1.08.19-.493.296-1.02.332-1.8.052-1.104.058-1.49.058-4.029 0-2.474-.007-2.878-.058-4.029-.037-.782-.142-1.31-.332-1.798a2.911 2.911 0 0 0-.703-1.08 2.884 2.884 0 0 0-1.08-.704c-.49-.19-1.016-.295-1.798-.331C14.925 4.006 14.539 4 12 4zm0-2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2z"
														fill="rgba(42,42,99,1)"
													/>
												</svg>
											</span>
											<span className="p-2 bg-[#FAFAFD] rounded-full">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 24 24"
													width="18"
													height="18"
												>
													<path fill="none" d="M0 0h24v24H0z" />
													<path
														d="M22.162 5.656a8.384 8.384 0 0 1-2.402.658A4.196 4.196 0 0 0 21.6 4c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 0 0-7.126 3.814 11.874 11.874 0 0 1-8.62-4.37 4.168 4.168 0 0 0-.566 2.103c0 1.45.738 2.731 1.86 3.481a4.168 4.168 0 0 1-1.894-.523v.052a4.185 4.185 0 0 0 3.355 4.101 4.21 4.21 0 0 1-1.89.072A4.185 4.185 0 0 0 7.97 16.65a8.394 8.394 0 0 1-6.191 1.732 11.83 11.83 0 0 0 6.41 1.88c7.693 0 11.9-6.373 11.9-11.9 0-.18-.005-.362-.013-.54a8.496 8.496 0 0 0 2.087-2.165z"
														fill="rgba(42,42,99,1)"
													/>
												</svg>
											</span>
											<span className="p-2 bg-[#FAFAFD] rounded-full">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 24 24"
													width="18"
													height="18"
												>
													<path fill="none" d="M0 0h24v24H0z" />
													<path
														d="M6.94 5a2 2 0 1 1-4-.002 2 2 0 0 1 4 .002zM7 8.48H3V21h4V8.48zm6.32 0H9.34V21h3.94v-6.57c0-3.66 4.77-4 4.77 0V21H22v-7.93c0-6.17-7.06-5.94-8.72-2.91l.04-1.68z"
														fill="rgba(42,42,99,1)"
													/>
												</svg>
											</span>
										</div>
									</div>
								</div>

								<div className="flex gap-5 mb-4">
									<span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											width="24"
											height="24"
										>
											<path fill="none" d="M0 0h24v24H0z" />
											<path
												d="M21 19h2v2H1v-2h2V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v15h4v-8h-2V9h3a1 1 0 0 1 1 1v9zM5 5v14h8V5H5zm2 6h4v2H7v-2zm0-4h4v2H7V7z"
												fill="rgba(255,255,255,1)"
											/>
										</svg>
									</span>
									<p className="text-white w-[150px]">
										5 Adewunmi Abudu Street off Osolo way, Ajao Estate, Lagos, Nigeria
									</p>
								</div>
								<div className="flex gap-5 mb-4">
									<span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											width="24"
											height="24"
										>
											<path fill="none" d="M0 0h24v24H0z" />
											<path
												d="M21 16.42v3.536a1 1 0 0 1-.93.998c-.437.03-.794.046-1.07.046-8.837 0-16-7.163-16-16 0-.276.015-.633.046-1.07A1 1 0 0 1 4.044 3H7.58a.5.5 0 0 1 .498.45c.023.23.044.413.064.552A13.901 13.901 0 0 0 9.35 8.003c.095.2.033.439-.147.567l-2.158 1.542a13.047 13.047 0 0 0 6.844 6.844l1.54-2.154a.462.462 0 0 1 .573-.149 13.901 13.901 0 0 0 4 1.205c.139.02.322.042.55.064a.5.5 0 0 1 .449.498z"
												fill="rgba(255,255,255,1)"
											/>
										</svg>
									</span>
									<p className="text-white">+234 803 529 9325</p>
								</div>
								<div className="flex gap-5 mb-4">
									<span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											width="24"
											height="24"
										>
											<path fill="none" d="M0 0h24v24H0z" />
											<path
												d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm17 4.238l-7.928 7.1L4 7.216V19h16V7.238zM4.511 5l7.55 6.662L19.502 5H4.511z"
												fill="rgba(255,255,255,1)"
											/>
										</svg>
									</span>
									<p className="text-white">info@emosbestgroup.com</p>
								</div>
							</div>
						</div>
					</MediaQuery>

					{/* medium screen end */}

					{/* large screen start */}
					<MediaQuery min="lg">
						<div className="flex flex-wrap gap-20">
							<div className="">
								<div>
									<img src={FooterLogo} alt="" className="w-[200px] h-full" />
								</div>
								<p className="w-[200px] text-lg text-white py-[20px]">
									Emos Best Industries Limited is a top-notch cosmetics manufacturing
									company in Nigeria.
								</p>
							</div>

							<div className="mt-5">
								<h4 className="py-2 text-white text-lg font-bold">Explore</h4>
								<p className="py-2 mx-4 text-white text-lg">Home</p>
								<p className="py-2 mx-4 text-white text-lg">Brand</p>
								<p className="py-2 mx-4 text-white text-lg">Products</p>
								<p className="py-2 mx-4 text-white text-lg">Distributor</p>
							</div>

							<div className="mt-5">
								<h4 className="py-2 text-white text-lg font-bold">Resource</h4>
								<p className="py-2 mx-4 text-white text-lg">About</p>
								<p className="py-2 mx-4 text-white text-lg">FAQ</p>
								<p className="py-2 mx-4 text-white text-lg">Gallery</p>
								<p className="py-2 mx-4 text-white text-lg">Blog</p>
							</div>

							<div className="w-[250px] mt-5">
								<h4 className="py-2 text-white text-lg font-bold">Contact Us</h4>

								<div className="flex gap-5 mb-4">
									<span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											width="24"
											height="24"
										>
											<path fill="none" d="M0 0h24v24H0z" />
											<path
												d="M21 19h2v2H1v-2h2V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v15h4v-8h-2V9h3a1 1 0 0 1 1 1v9zM5 5v14h8V5H5zm2 6h4v2H7v-2zm0-4h4v2H7V7z"
												fill="rgba(255,255,255,1)"
											/>
										</svg>
									</span>
									<p className="text-white">
										5 Adewunmi Abudu Street off Osolo way, Ajao Estate, Lagos, Nigeria
									</p>
								</div>
								<div className="flex gap-5 mb-4">
									<span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											width="24"
											height="24"
										>
											<path fill="none" d="M0 0h24v24H0z" />
											<path
												d="M21 16.42v3.536a1 1 0 0 1-.93.998c-.437.03-.794.046-1.07.046-8.837 0-16-7.163-16-16 0-.276.015-.633.046-1.07A1 1 0 0 1 4.044 3H7.58a.5.5 0 0 1 .498.45c.023.23.044.413.064.552A13.901 13.901 0 0 0 9.35 8.003c.095.2.033.439-.147.567l-2.158 1.542a13.047 13.047 0 0 0 6.844 6.844l1.54-2.154a.462.462 0 0 1 .573-.149 13.901 13.901 0 0 0 4 1.205c.139.02.322.042.55.064a.5.5 0 0 1 .449.498z"
												fill="rgba(255,255,255,1)"
											/>
										</svg>
									</span>
									<p className="text-white">+234 803 529 9325</p>
								</div>
								<div className="flex gap-5 mb-4">
									<span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											width="24"
											height="24"
										>
											<path fill="none" d="M0 0h24v24H0z" />
											<path
												d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm17 4.238l-7.928 7.1L4 7.216V19h16V7.238zM4.511 5l7.55 6.662L19.502 5H4.511z"
												fill="rgba(255,255,255,1)"
											/>
										</svg>
									</span>
									<p className="text-white">info@emosbestgroup.com</p>
								</div>
							</div>
							<div className="mt-5">
								<p className="text-white text-lg">Follow us on:</p>
								<div className="flex gap-5 mt-7">
									<span className="p-4 bg-[#FAFAFD] rounded-full">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											width="24"
											height="24"
										>
											<path fill="none" d="M0 0h24v24H0z" />
											<path
												d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5z"
												fill="rgba(42,42,99,1)"
											/>
										</svg>
									</span>
									<span className="p-4 bg-[#FAFAFD] rounded-full">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											width="24"
											height="24"
										>
											<path fill="none" d="M0 0h24v24H0z" />
											<path
												d="M12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm0-2a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm6.5-.25a1.25 1.25 0 0 1-2.5 0 1.25 1.25 0 0 1 2.5 0zM12 4c-2.474 0-2.878.007-4.029.058-.784.037-1.31.142-1.798.332-.434.168-.747.369-1.08.703a2.89 2.89 0 0 0-.704 1.08c-.19.49-.295 1.015-.331 1.798C4.006 9.075 4 9.461 4 12c0 2.474.007 2.878.058 4.029.037.783.142 1.31.331 1.797.17.435.37.748.702 1.08.337.336.65.537 1.08.703.494.191 1.02.297 1.8.333C9.075 19.994 9.461 20 12 20c2.474 0 2.878-.007 4.029-.058.782-.037 1.309-.142 1.797-.331.433-.169.748-.37 1.08-.702.337-.337.538-.65.704-1.08.19-.493.296-1.02.332-1.8.052-1.104.058-1.49.058-4.029 0-2.474-.007-2.878-.058-4.029-.037-.782-.142-1.31-.332-1.798a2.911 2.911 0 0 0-.703-1.08 2.884 2.884 0 0 0-1.08-.704c-.49-.19-1.016-.295-1.798-.331C14.925 4.006 14.539 4 12 4zm0-2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2z"
												fill="rgba(42,42,99,1)"
											/>
										</svg>
									</span>
									<span className="p-4 bg-[#FAFAFD] rounded-full">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											width="24"
											height="24"
										>
											<path fill="none" d="M0 0h24v24H0z" />
											<path
												d="M22.162 5.656a8.384 8.384 0 0 1-2.402.658A4.196 4.196 0 0 0 21.6 4c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 0 0-7.126 3.814 11.874 11.874 0 0 1-8.62-4.37 4.168 4.168 0 0 0-.566 2.103c0 1.45.738 2.731 1.86 3.481a4.168 4.168 0 0 1-1.894-.523v.052a4.185 4.185 0 0 0 3.355 4.101 4.21 4.21 0 0 1-1.89.072A4.185 4.185 0 0 0 7.97 16.65a8.394 8.394 0 0 1-6.191 1.732 11.83 11.83 0 0 0 6.41 1.88c7.693 0 11.9-6.373 11.9-11.9 0-.18-.005-.362-.013-.54a8.496 8.496 0 0 0 2.087-2.165z"
												fill="rgba(42,42,99,1)"
											/>
										</svg>
									</span>
									<span className="p-4 bg-[#FAFAFD] rounded-full">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											width="24"
											height="24"
										>
											<path fill="none" d="M0 0h24v24H0z" />
											<path
												d="M6.94 5a2 2 0 1 1-4-.002 2 2 0 0 1 4 .002zM7 8.48H3V21h4V8.48zm6.32 0H9.34V21h3.94v-6.57c0-3.66 4.77-4 4.77 0V21H22v-7.93c0-6.17-7.06-5.94-8.72-2.91l.04-1.68z"
												fill="rgba(42,42,99,1)"
											/>
										</svg>
									</span>
								</div>
							</div>
						</div>
					</MediaQuery>

					{/* large screen end */}
				</div>
			</div>
		</div>
	);
};

export default Footer;
