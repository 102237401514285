import React, { useState, useCallback, useEffect } from "react";
import Header from "components/home/Header";
import Footer from "components/footer/Footer";
import BlogImage from "./BlogImage";
import Blog from "./Blog";
import Skin1 from "../assets/skin1.svg";
import { getABlog, getAllBlogs } from "services/blogService";
import { Link, useParams } from "react-router-dom";
import Loader from "components/loader/Loader";

const SingleBlogPost = () => {
	const [blog, setBlog] = useState(null);
	const [otherBlogs, setOtherBlogs] = useState([]);
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(true);
	const { id } = useParams();

	const fetchData = useCallback(async () => {
		try {
			// if id is single digit, it means it's a placeholder post do not fetch from the server
			if (id.length === 1) {
				setBlog(null);
				setLoading(false);
				return;
			} else {
				const response = await getABlog(id);
				const res = await getAllBlogs();
				const { data } = response;
				setBlog(data);
				setOtherBlogs(res.data);
				setLoading(false);
			}
		} catch (err) {
			console.error("Error fetching blogs", err);
			setError("Something went wrong. Please try again later.");
			setLoading(false);
		}
	}, [id]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const placeholderPost = () => {
		return (
			<div className="min-h-screen flex flex-col">
				<div className="flex-grow">
					<Header />
					<div className="m-[40px] md:ml-[80px]">
						<div className="hidden md:flex">
							<BlogImage />
						</div>
						<div className="hidden md:grid justify-center py-8">
							<p className=" text-eb-primary text-[25px]">Hair Care</p>
						</div>

						<div>
							<div className="md:grid grid-cols-2 p-4">
								<div className="grid content-center ">
									<p className="text-eb-primary text-[20px] md:text-[25px]">
										{" "}
										Skin Detoxification
									</p>

									<div>
										{" "}
										<p className="text-[14px] md:text-[20px] md:w-[500px] mt-4">
											Taking care of one of the major detoxification organs on our bodies,
											our skin, is just as imperative as the care we show the delicate skin
											on our face like the heart, stomach, and brain, your skin is an organ
											In fact, it’s the largest organ in your body, but it’s still easy to
											take skin for granted. Unless there’s a problem, you may not
										</p>{" "}
										<p> </p>
									</div>
								</div>

								<div className=" ">
									<img
										className="h-[320px] w-[500px] object-fit"
										src={Skin1}
										alt="haircare"
									/>
								</div>
							</div>

							<div className="text-[14px] md:text-[20px]">
								<p>
									Your skin is constantly protecting you. Your skin keeps infections out
									of your body and keeps you from getting sick. When you take care of
									your skin, you&#39;re helping your skin do its job. And taking care of
									your skin today with the use of LemonFresh Body Care products will help
									prevent future problems, like wrinkles and even skin cancer One simple
									way to take care of your skin is to keep it clean. Keeping your hands
									clean is especially important because your hands can spread germs to
									the skin on other parts of your body. You&#39;ll also want to use water
									that’s warm, or cold when you take a shower or bath, cleansing your
									skin removes build-up of dirt and impurities, rashes, spots, leaving it
									incredibly clean. Our LemonFresh Body Wash also available in Carrot Oil
									help skin maintain its moisture and soothing for an indulgent cleanse.
									To apply, bring your cleanser or body wash to a lather and massage over
									your skin.
								</p>
							</div>

							{/* READ NEXT */}
							<div className="flex mt-10 gap-5">
								<div>
									<p className="text-eb-primary text-[20px] md:text-[25px]">
										Read Next:
									</p>
								</div>
								<div>
									<p className="text-eb-primary my-2">Skin Detoxification</p>
									<p className="text-eb-primary my-2">Dental Care</p>
									<p className="text-eb-primary my-2">Hair Care</p>
									<p className="text-eb-primary my-2">Perfume Making</p>
								</div>
							</div>
						</div>
					</div>
					<Footer />
				</div>
			</div>
		);
	};

	const blogPost = () => {
		return (
			<div className="min-h-screen flex flex-col">
				<div className="flex-grow">
					<Header />
					<div className="m-[40px] md:ml-[80px]">
						<div className="hidden md:flex">
							<BlogImage />
						</div>
						<div className="hidden md:grid justify-center py-8">
							<p className=" text-eb-primary text-[25px]">{blog.title}</p>
						</div>

						<div>
							<div className="md:grid grid-cols-2 p-4">
								<div className="grid content-center ">
									<p className="text-eb-primary text-[20px] md:text-[25px]">
										{" "}
										{blog.title}
									</p>

									<div>
										<p>
											{" "}
											<p className="text-[14px] md:text-[20px] md:w-[500px] mt-4">
												{blog.content}
											</p>{" "}
										</p>

										<p> </p>
									</div>
								</div>

								<div className=" ">
									<img
										className="h-[320px] w-[500px] object-fit"
										src={blog.image.url}
										alt={blog.title}
									/>
								</div>
							</div>

							{/* READ NEXT */}
							<div className="flex mt-10 gap-5">
								<div>
									<p className="text-eb-primary text-[20px] md:text-[25px]">
										Read Next:
									</p>
								</div>
								<div>
									{otherBlogs.map((blg, index) => {
										return (
											<p key={index} className="text-eb-primary my-2">
												<Link to={`/blog/${blg._id}`}>{blg.title}</Link>
											</p>
										);
									})}
								</div>
							</div>
						</div>
					</div>
					<Footer />
				</div>
			</div>
		);
	};

	if (loading) {
		return <Loader />;
	}

	if (error) {
		// beautify error message by centering it and making the text bold
		return <div style={{ textAlign: "center", fontWeight: "bold" }}>{error}</div>;
	}

	return <>{blog ? blogPost() : placeholderPost()}</>;
};

export default SingleBlogPost;
