import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './App.css';
import './input.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from '../src/redux/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../src/components/loader/Loader";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<>
		<Provider store={store}>
			<PersistGate loading={<Loader />} persistor={persistor}>
				<App />
				<ToastContainer />
			</PersistGate>
		</Provider>
	</>,
);






