import React from "react";
import Header from "components/home/Header";
import SlidImg from "../../assets/bannerImg.png";
import AVATAR from "../../assets/avi_Placeholder.png";
import MyOrderTable from "./MyOrderTable";
import { useSelector } from "react-redux";
import { selectUser } from "redux/slices";
const MyOrder = () => {
	const user = useSelector(selectUser);

  return (
			<div>
				<Header />

				<div className="md:m-[40px] md:ml-[80px] m-4">
					<div className="hidden w-full md:flex">
						<div className="w-[50%]">
							<img
								src={SlidImg}
								className="w-full h-full object-fit"
								alt="bannerImg"
							/>
						</div>
						<div className="w-[50%] bg-[#D8E1E6]">
							<div className="p-[50px]"></div>
						</div>
					</div>

					<div className="hidden md:flex justify-center  ">
						<div className="">
							{/* <div className="relative w-72 h-36 rounded-bl-full rounded-br-full  z-10 "></div> */}

							<div className="relative   border-2 border-eb-primary rounded-full w-70 h-34  flex justify-center   -top-40">
								<img
									src={user?.avatar?.url || AVATAR}
									className="w-[300px] h-[300px] rounded-[100%]"
									alt="bret"
								/>
							</div>
						</div>
					</div>

					<div className=" relative  -top-10 grid md:justify-center">
						<p className="text-[30px] text-eb-primary mt-[30px] md:mt-[0px] mb-[30px] flex justify-center ">
							My Orders
						</p>

						{/* TABLE */}

						<div className=" md:w-[80vw] overflow-x-auto">
							<MyOrderTable />
						</div>
					</div>
				</div>
			</div>
		);
};

export default MyOrder;
