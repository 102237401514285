import React from 'react';
import { useFormikContext } from 'formik';
import Spinner from './Spinner';

const SubmitButton = ({ loading, disabled, children, text, type ="submit",  ...rest}) => { 
// Grab values and submitForm from context
const formikContext = useFormikContext();

  return (
  <button
   type={type}
   disabled={!formikContext?.isValid || disabled}
   {...rest}
   style={{display: 'flex', alignItems:'center', gap:'5px'}}
  >
    {
        formikContext?.isSubmitting || loading ? <Spinner /> : null 
    }
    { 
      children || text
    }
  </button>
  )
}

export default SubmitButton;