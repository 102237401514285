import React from "react";
import Loader from "components/loader/Loader";
import EmosLogo from "../../assets/emosLogo.png";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import Spinner from "components/forms/Spinner";
import { CurrencyFormatter } from "components/currency-formatter/CurrencyFormatter";
import PrimaryAccountDetails from "./PrimaryAccountDetails";

export const InvoiceComponent = React.forwardRef(
	({ invoice, loading, error }, ref) => {
		if (loading) {
			return <Loader />;
		}

		if (error) {
			return <div>Error: {error}</div>;
		}

		function formatDate(dateString) {
			const date = new Date(dateString);
			const formattedDate = date.toLocaleDateString("en-US", {
				day: "numeric",
				month: "long",
				year: "numeric",
			});
			return formattedDate;
		}

		return (
			<>
				<div className="bg-white w-full" ref={ref}>
					<div className="p-7">
						<div className="flex items-center justify-between">
							<h3 className="text-xl font-bold my-3">Invoice</h3>
							<div className="w-15 h-10">
								<img src={EmosLogo} alt="logo" className="w-full h-full object-fit" />
							</div>
						</div>
						<hr className="my-2" />
						<div className="w-[100%] flex gap-5 my-3">
							<div className="w-[50%]">
								<div className="w-[100%] flex gap-5 my-3">
									<div className="w-[50%]">
										<p className="text-sm my-2">Date:</p>
										<p className="text-sm text-[#656565] p-2 border border-gray-200 rounded">
											{formatDate(invoice.createdAt)}
										</p>
									</div>
									<div className="w-[50%]">
										<div className="my-2">
											<p className="text-sm my-2">Invoice Number:</p>
											<p className="text-sm text-[#656565]  p-2 border border-gray-200 rounded">
												{invoice.trackingId}
											</p>
										</div>
									</div>
								</div>
								<div className="my-2 py-2">
									<p className="text-sm my-2">Ship To:</p>
									<div className="border border-gray-200 rounded p-2">
										<p className="text-sm text-[#656565]">
											<span className="font-semibold">Name:</span> &nbsp;
											{invoice.user.firstName} {invoice.user.lastName}
										</p>
										<p className="text-sm text-[#656565]">
											<span className="font-semibold">Address:</span> &nbsp;
											{invoice.shippingInfo.address} {invoice.shippingInfo.city}, &nbsp;
											{invoice.shippingInfo.state}
										</p>
										<p className="text-sm text-[#656565]">
											<span className="font-semibold">Phone Number:</span> &nbsp;
											{invoice.shippingInfo.phoneNumber}
										</p>
									</div>
								</div>
							</div>
							<div className="w-[50%]">
								{/* Render Payment Method */}
								<PrimaryAccountDetails
									data={invoice.paymentInformation}
									isPaid={invoice.paid === "Yes" ? true : false}
								/>
							</div>
						</div>
						<div className="my-2">
							<TableContainer>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>
												<p className="text-[15px] text-[#898989] font-bold">Item</p>{" "}
											</TableCell>

											<TableCell>
												<p className="text-[15px] text-[#898989] font-bold">Quantity</p>{" "}
											</TableCell>
											<TableCell>
												<p className="text-[15px] text-[#898989] font-bold">Size</p>
											</TableCell>
											<TableCell>
												<p className="text-[15px] text-[#898989] font-bold">Price</p>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{error ? (
											<div className="w-full flex justify-center py-4">Error: {error}</div>
										) : loading ? (
											<div>
												<Spinner />
											</div>
										) : invoice.orderItems.length === 0 ? (
											<TableRow>
												<TableCell colSpan={6} className="text-center">
													No Items
												</TableCell>
											</TableRow>
										) : (
											invoice?.orderItems?.map((order, index) => (
												<TableRow key={order._id}>
													<TableCell>{order.name}</TableCell>
													<TableCell>{order.quantity}</TableCell>
													<TableCell>{order.size}</TableCell>
													<TableCell>₦{CurrencyFormatter(order.price)}</TableCell>
												</TableRow>
											))
										)}
									</TableBody>
								</Table>
							</TableContainer>
						</div>
						<div className="my-4">
							<div className="w-[100%] flex gap-5 my-3">
								<div className="w-[50%]">
									<div className="my-4">
										<p className="text-sm my-2">Subtotal:</p>
										<p className="text-sm text-[#656565]  p-2 border border-gray-200 rounded">
											₦{CurrencyFormatter(invoice.itemsPrice)}
										</p>
									</div>
									<div className="my-4">
										<p className="text-sm my-2">Value Added Tax (VAT):</p>
										<p className="text-sm text-[#656565]  p-2 border border-gray-200 rounded">
											₦{CurrencyFormatter(invoice.taxPrice)}
										</p>
									</div>
								</div>
								<div className="w-[50%]">
									<div className="my-4">
										<p className="text-sm my-2">Shipping Price:</p>
										<p className="text-sm text-[#656565]  p-2 border border-gray-200 rounded">
											₦{CurrencyFormatter(invoice.shippingPrice)}
										</p>
									</div>
									<div className="my-4">
										<p className="text-sm my-2">Total Amount:</p>
										<p className="text-sm text-[#656565] font-semibold p-2 border border-gray-200 rounded">
											₦{CurrencyFormatter(invoice.grandTotalPrice)}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	},
);
