import httpService from "./httpService";

export async function login({ email, password }) {
	if (!email || !password) {
		throw new Error("Cannot login with empty email or password");
	}
	try {
		const response = await httpService.post(`/auth/login`, {
			email: email.trim(),
			password,
		});

		return response;
	} catch (err) {
		throw new Error(err.message);
	}
}

export async function register(payload) {
	const { email, password, firstName, lastName, phone, country } = payload;
	try {
		const res = await httpService.post("/auth/signup", {
			email: email.trim(),
			password,
			firstName,
			lastName,
			phone,
			country,
		});
		return res;
	} catch (err) {
		throw new Error(err.message);
	}
}

export async function logoutUser() {
	try {
		const response = await httpService.get(`/auth/logout`);
		return response;
	} catch (err) {
		throw new Error(err.message);
	}
}

export async function forgotPassword({ email }) {
	try {
		const response = await httpService.post("/auth/forgot/password", {
			email,
		});
		return response;
	} catch (err) {
		throw new Error(err.message);
	}
}

export async function verifyEmailOtp(otp) {
	try {
		const response = await httpService.post(`/auth/verify/email?otp=${otp}`);
		return response;
	} catch (err) {
		throw new Error(err.message);
	}
}

export async function resetPassword({ password, token }) {
	try {
		const response = await httpService.post("/auth/reset/password", {
			password,
			otp: token,
		});
		return response;
	} catch (error) {
		throw new Error(error.message);
	}
}

export async function validateOtp(otp) {
	try {
		const response = await httpService.post("/auth/validate/otp", {
			otp,
		});
		return response;
	} catch (error) {
		throw new Error(error.message);
	}
}

export async function resendOTP(email) {
	try {
		const response = await httpService.post("/auth/resend/otp", {
			email,
		});
		return response;
	} catch (error) {
		throw new Error(error.message);
	}
}

export async function contactUs(payload) {
	try {
		const { firstname, lastname, email, country, phone, message, captcha } =
			payload;

		const response = await httpService.post("/user/contact/us", {
			firstname,
			lastname,
			email,
			country,
			phone,
			message,
			captcha,
		});
		return response;
	} catch (error) {
		throw new Error(error.message);
	}
}

export async function userProfile() {
	try {
		const response = await httpService.get("/user/profile/data");
		return response;
	} catch (error) {
		throw new Error(error.message);
	}
}

export async function updateProfile(payload) {
	try {
		const { firstName, lastName, phone, email } = payload;
		const response = await httpService.put("/user/profile/update", {
			firstName,
			lastName,
			phone,
			email,
		});
		return response;
	} catch (error) {
		throw new Error(error.message);
	}
}

// CHANGE PASSWORD

export async function changePassword(payload) {
	try {
		const { oldPassword, newPassword } = payload;
		const response = await httpService.patch("/auth/change/password", {
			oldPassword,
			newPassword,
		});
		return response;
	} catch (error) {
		throw new Error(error.message);
	}
}

// PROFILE PHOTO IMAGE

export async function updateProfilePhoto(payload) {
	try {
		const { file } = payload;
		const response = await httpService.put("/user/profile/update", {
			file,
		});
		return response;
	} catch (error) {
		throw new Error(error.message);
	}
}
