import { ErrorMessage } from "formik";


export const FormErrorMessage = ({name}) => {
    return (
        <ErrorMessage name={name}>
            {msg => (
                        <p
                          style={{
                            fontSize: 10,
                            color: 'red',
                            marginBottom: 10,  
                          }}>
                          {msg}
                        </p>
                      )}
        </ErrorMessage>
    )
}