import React, { useState } from "react";
import Header from "components/home/Header";
import SlidImg from "../../assets/bannerImg.png";
import Footer from "components/footer/Footer";
import AVATAR from "../../assets/avi_Placeholder.png";
import { updateProfile } from "../../../services/authService";
import {
	ProfileSchema,
	useInitialValues,
} from "modules/schemas/update-profile-schema";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";
import { FormErrorMessage } from "components/forms/FormErrorMessage";
import SubmitButton from "components/forms/SubmitButton";
import PhoneControl from "components/auth/CreateAccount/PhoneControl";
import { useSelector, useDispatch } from "react-redux";
import { updateUser, setUser, selectUser } from "redux/slices";
import { Link, useNavigate } from "react-router-dom";
import Combined from "../../assets/Combined.svg";
import Spinner from "components/forms/Spinner";
import httpService from "services/httpService";

const Profile = () => {
	const user = useSelector(selectUser);
	const dispatch = useDispatch();

	const [showDiv, setShowDiv] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);
	const [loading, setLoading] = useState(false);
	const [isUpdating, setIsUpdating] = useState(false);
	const navigate = useNavigate();

	const handleClick = () => {
		setShowDiv(!showDiv);
	};

	const initialValues = useInitialValues();
	const handleUpdateSubmmit = async (values) => {
		setIsUpdating(true);
		try {
			const res = await updateProfile(values);
			dispatch(updateUser(res.data));
			toast(`Profile updated successfully`, {
				type: "success",
				autoClose: 3000,
			});
			setIsUpdating(false);
			setShowDiv(!showDiv);
		} catch (e) {
			setIsUpdating(false);
			toast(`${e?.message}`, {
				type: "error",
				autoClose: 3000,
			});
			console.log(e);
		}
	};

	const handleImageChange = (event) => {
		const image = event.target.files[0];
		const reader = new FileReader();

		reader.onload = () => {
			const imageDataUrl = reader.result;
			setSelectedImage(imageDataUrl);
		};

		if (image) {
			reader.readAsDataURL(image);
		}
	};

	const dataURLtoFile = (dataURL, filename) => {
		const arr = dataURL.split(",");
		const mime = arr[0].match(/:(.*?);/)[1];
		const bstr = atob(arr[1]);
		let n = bstr.length;
		const u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new File([u8arr], filename, { type: mime });
	};

	const handlePhotoUpload = async (event) => {
		event.preventDefault();
		setLoading(true);

		if (!selectedImage) {
			toast("Please select an image to upload", {
				type: "error",
				autoClose: 3000,
			});
		}
		const formData = new FormData();
		const file = dataURLtoFile(selectedImage, "avatar.jpg");
		formData.append("avatar", file);

		try {
			const response = await httpService.put("/user/profile/update", formData);
			dispatch(setUser(response.data));
			toast(response.message, {
				type: "success",
				autoClose: 3000,
			});
			setLoading(false);
		} catch (error) {
			console.error("Error uploading image:", error);
			setLoading(false);
			toast(error.message, {
				type: "error",
				autoClose: 3000,
			});
		}
	};

	return (
		<div>
			<Header />
			<div className="m-[40px] ml-[80px]">
				<div className="hidden w-full md:flex">
					<div className="w-[50%]">
						<img src={SlidImg} className="w-full h-full object-fit" alt="bannerImg" />
					</div>
					<div className="w-[100%] lg:w-[50%] bg-[#D8E1E6]">
						<div className="p-[50px] flex flex-col items-start lg:items-end">
							<h3 className="text-[#3F3F95] my-4 font-bold text-xl self-start">
								ACOFRESH
							</h3>
							<p className="text-lg text-[#3F3F95] mb-[40px]">
								ACOFRESH hair food is a specially developed product that softens the
								hair, making it easier to style. It contains a Moisture Resistant
								Formula with paraffin oil that helps relieve dry scalp.
							</p>
							<div className="self-end lg:self-auto">
								<Link to="/products">
									<button className="bg-[#3F3F95] hover:bg-black p-2 px-4 rounded-lg text-white">
										See Products
									</button>
								</Link>
							</div>
						</div>
					</div>
				</div>

				<div className="hidden md:flex justify-center  ">
					<div className="">
						<div className="relative border-2 border-eb-primary rounded-full w-70 h-34  flex justify-center -top-20">
							<img
								src={user?.avatar.url || AVATAR}
								className="w-[200px] h-[200px] rounded-[100%]"
								alt="bret"
							/>
						</div>
					</div>
				</div>

				{/* -top-40 */}

				<div className=" relative  -top-10 grid justify-center">
					<p className="text-[25px] md:text-[30px] text-eb-primary mb-[30px] flex justify-center">
						My Personal Information
					</p>

					<hr />

					{!showDiv ? (
						<div className="">
							<div className="py-[28px] gap-2">
								<div className="flex gap-5 mb-7">
									<div className="grid justify-center content-center gap-[13px] border border-dashed border-erp-light-gray rounded-[100%]  w-[92px] h-[92px]">
										<label htmlFor="image-upload" className="relative cursor-pointer">
											<div className="grid justify-center">
												{selectedImage ? (
													<>
														<div className=" w-[88px] h-[88px]">
															<img
																src={selectedImage}
																className="item-center object-fit w-full h-full rounded-full"
																alt="upload"
															/>
															<p className="text-erp-gray text-[10px]">Select Image</p>
														</div>
													</>
												) : (
													<>
														<div className=" w-[88px] h-[88px]">
															<img
																src={user?.avatar.url || Combined}
																className="item-center w-[88px] h-[88px] object-fit rounded-md"
																alt="upload"
															/>
															<p className="text-erp-gray text-[10px]">Change Image</p>
														</div>
													</>
												)}
											</div>
											<input
												id="image-upload"
												type="file"
												className="sr-only"
												accept="JPG, JPEG, PNG"
												onChange={handleImageChange}
											/>
										</label>
									</div>
									<div>
										<div>
											<p className="text-[#718096] mb-4 text-[14px]">
												{" "}
												We only support .JPG, .JPEG, or .PNG file.
											</p>
										</div>
										<button
											onClick={handlePhotoUpload}
											disabled={loading}
											className="flex justify-center bg-eb-primary hover:bg-black rounded-md p-2 text-white"
										>
											{loading ? <Spinner /> : "Upload your photo"}
										</button>
									</div>
								</div>

								{/* FORM */}
								<div>
									<div className="grid grid-cols-2 w-[100%] gap-6">
										<div>
											<p className="text-[#718096] "> First Name</p>
											<input
												className=" h-[56px] w-[100%] bg-[#FAFAFA] text-[#111827] rounded-xl mt-2 pl-2"
												disabled
												placeholder={user?.firstName}
											/>
										</div>
										<div>
											<p className="text-[#718096] "> Last Name</p>
											<input
												className=" h-[56px] w-[100%] bg-[#FAFAFA] text-[#111827] rounded-xl mt-2 pl-2"
												disabled
												placeholder={user?.lastName}
											/>
										</div>
									</div>

									<div className="grid grid-cols-2 w-[100%] gap-6 mt-6">
										<div>
											<p className="text-[#718096] "> Email</p>
											<input
												className=" h-[56px] w-[100%] bg-[#FAFAFA] text-[#111827] rounded-xl mt-2 pl-2"
												disabled
												placeholder={user?.email}
											/>
										</div>
										<div>
											<p className="text-[#718096] "> Phone Number</p>
											<input
												className=" h-[56px] w-[100%] bg-[#FAFAFA] text-[#111827] rounded-xl mt-2 pl-2"
												disabled
												placeholder={user?.phone}
											/>
										</div>
									</div>
								</div>

								<div className="mt-8 flex justify-end">
									<div
										onClick={handleClick}
										className="text-white bg-eb-primary text-[14px] h-[48px] w-[103px] grid justify-center content-center rounded-xl"
									>
										<p> Edit Profile</p>
									</div>
								</div>

								<div className="mt-8 flex justify-between">
									<Link to="/my-order">
										<div className="text-white bg-eb-primary text-[14px] h-[48px] w-[135px] grid justify-center content-center rounded-xl">
											<p> View My Orders</p>
										</div>
									</Link>
									<Link to="/return-query">
										<div className="text-white bg-eb-primary text-[14px] h-[48px] w-[135px] grid justify-center content-center rounded-xl">
											<p> Return Goods</p>
										</div>
									</Link>
								</div>
							</div>
						</div>
					) : (
						<div>
							<div className="py-6 flex gap-5">
								<div>
									<img
										src={user?.avatar?.url || Combined}
										className="w-[88px] h-[88px] object-fit rounded-full"
										alt="bannerImg"
									/>
								</div>

								<div>
									<div>
										<p className="text-[#718096] mb-4 text-[14px]">
											{" "}
											We only support .JPG, .JPEG, or .PNG file.{" "}
										</p>
									</div>
									<div className="flex gap-6">
										<div className="text-white bg-[#6A6AA8] h-[48px] w-[152px] grid justify-center content-center rounded-xl">
											<p> Upload your photo </p>
										</div>
									</div>
								</div>
							</div>

							<Formik
								initialValues={initialValues}
								validationSchema={ProfileSchema}
								onSubmit={handleUpdateSubmmit}
							>
								<Form>
									<div className="flex justify-center py-[28px]">
										<div>
											<div className=" grid grid-cols-2 gap-6">
												<div className="mb-5">
													<p className="text-[#718096] "> First Name</p>
													<Field
														name="firstName"
														type="text"
														className="h-[56px] w-[100%] bg-[#FAFAFA] text-[#111827] rounded-xl mt-2 pl-2"
														placeholder="Your First Name"
													/>
													<div>
														<FormErrorMessage name={"firstname"} />
													</div>
												</div>

												<div className="mb-5">
													<p className="text-[#718096] ">Last Name</p>
													<Field
														name="lastName"
														type="text"
														className="h-[56px] w-[100%] bg-[#FAFAFA] text-[#111827] rounded-xl mt-2 pl-2"
														placeholder="Your Last Name"
													/>
													<div>
														<FormErrorMessage name={"lastname"} />
													</div>
												</div>
											</div>

											<div className=" grid grid-cols-2 gap-6">
												<div className="mb-5">
													<p className="text-[#718096] ">Phone Number</p>

													<PhoneControl
														name={"phone"}
														className="h-[56px] w-[100%] bg-[#FAFAFA] text-[#111827] rounded-xl mt-2 pl-2"
													/>
												</div>
												<div className="mb-5">
													<p className="text-[#718096] ">Email</p>
													<Field
														name="email"
														type="email"
														className=" h-[56px] w-[100%] bg-[#FAFAFA] text-[#111827] rounded-xl mt-2 pl-2"
														placeholder="Your Email"
													/>
													<div>
														<FormErrorMessage name={"email"} />
													</div>
												</div>
											</div>

											<div className="flex justify-center">
												<SubmitButton
													className="bg-eb-primary  flex justify-center  rounded-md items-center h-[48px] w-[106px]"
													disabled={isUpdating}
												>
													<p className="text-white">
														{isUpdating ? "Updating..." : "Update"}
													</p>
												</SubmitButton>
											</div>
										</div>
									</div>
								</Form>
							</Formik>
						</div>
					)}
				</div>
			</div>

			<Footer />
		</div>
	);
};

export default Profile;
