import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";


const initialState = {
    isLoading: false,
    wishlistsItems: localStorage.getItem("wishlistItems") ? JSON.parse(localStorage.getItem("wishlistItems")) : [],
    error: null
}

export const wishlistsSlice = createSlice({
    name: 'wishlists',
    initialState,
    reducers: {
        addToWishList: (state, action) => {
            let eachWishproductIndex = state.wishlistsItems.findIndex((item) => item?._id === action.payload?._id);

            if (eachWishproductIndex >= 0) {
                toast.error("Item has already been added", {
                    autoClose: 3000,
                    position: "top-right",
                  });
            } else {
                let assembledItem;
                assembledItem = { ...action.payload }
                state.wishlistsItems.push(assembledItem);
                localStorage.setItem("wishlistItems", JSON.stringify(state.wishlistsItems));
            }
        },

        //remove from wishlist

        removeWishlist: (state, action) => {

            const updatedWishlists = state.wishlistsItems?.filter((item) => item?.id !== action.payload?.id)

            state.wishlistsItems = updatedWishlists;

            localStorage.setItem("wishlistItems", JSON.stringify(state.wishlistsItems));

        },
        removeFromWishList: (state, action) => {
            const itemIndex = action.payload;
            state.wishlistsItems.splice(itemIndex, 1);
            localStorage.setItem(
              "wishlistItems",
              JSON.stringify(state.wishlistsItems)
            );
          },

        clearWishlists: (state, action) => {
            state.wishlistsItems = [];
            localStorage.setItem("wishlistItems", JSON.stringify(state.wishlistsItems));
        }

    },
})

// Action creators are generated for each case reducer function
// export const { } = cartsSlice.actions
export const wishList = (state) => state.wishlistsItems;

export const { addToWishList, removeWishlist, removeFromWishList, clearWishlists } = wishlistsSlice.actions

export default wishlistsSlice.reducer