import Footer from "components/footer/Footer";
import Header from "components/home/Header";
import React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import HotImg from "../../components/assets/hotproduct.png";

const steps = ["Order Placed", "Packed", "Shipped", "Delivered"];

const Order = () => {
  return (
    <>
      <Header />
      <div className="bg-white">
        <div className="px-[80px] py-[40px]">
          <div className="flex justify-between mb-[30px]">
            <div>
              <h2 className="text-2xl text-[#3F3F95] font-semibold">
                {" "}
                Order Details
              </h2>
            </div>
            <div>
              <button className="flex gap-2 bg-[#3F3F95] rounded-md px-4 py-2 hover:bg-black">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path
                      d="M21.7264 2.95706L16.2732 22.0433C16.1222 22.5718 15.7976 22.5958 15.5561 22.1127L10.9998 13.0002L1.92266 9.36931C1.41298 9.16544 1.41929 8.86034 1.9567 8.6812L21.0429 2.31913C21.5714 2.14297 21.8745 2.43878 21.7264 2.95706ZM19.0351 5.0966L6.81197 9.17097L12.4486 11.4256L15.4893 17.507L19.0351 5.0966Z"
                      fill="rgba(255,255,255,1)"
                    ></path>
                  </svg>
                </span>
                <span className="text-white">Invoice</span>
              </button>
            </div>
          </div>
          <div className="bg-[#F7F7FC] rounded-md p-4">
            <div className="flex gap-5 mb-[20px]">
              <div className="bg-white w-[70%] rounded-md">
                <div className="p-2 flex justify-between">
                  <div>
                    <p className="text-lg">Track Order:</p>
                  </div>
                  <div>
                    <p className="text-lg text-[#656565]">
                      Tracking ID: 81239499489
                    </p>
                  </div>
                </div>
                <div className="py-[30px]">
                  <Box sx={{ width: "100%" }}>
                    <Stepper activeStep={1} alternativeLabel>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Box>
                </div>
              </div>
              <div className="w-[30%] bg-white rounded-md">
                <div className="p-2">
                  <p className="text-lg font-semibold my-4">Delivery info:</p>
                  <div className="flex gap-7 items-center my-[30px]">
                    <div className="w-[120px] h-[80px]">
                      <img
                        src={HotImg}
                        alt="product-text"
                        className="w-full h-full object-fit"
                      />
                    </div>
                    <div>
                      <p>GIG </p>
                    </div>
                  </div>
                  <div className="flex gap-7 mb-[30px]">
                    <p className="text-sm text-[#656565]">Order ID</p>
                    <p className="text-sm text-[#656565]">82838499403</p>
                  </div>
                  <div className="flex gap-7 mb-[30px]">
                    <p className="text-sm text-[#656565]">Payment Mode</p>
                    <p className="text-sm text-[#656565]">Card Payment</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-5">
                          <div className="bg-white w-[70%] rounded-md">
                              <div className="p-4">
                                  <div className="flex items-center justify-between mb-[20px]">
                                      <div className="w-[120px] h-[80px]">
                                          <img src={HotImg} alt="product-img" className="w-full h-full object-fit" />
                                      </div>
                                      <div>
                                          <p className="text-sm">Lemon Fresh Body Wash</p>
                                      </div>
                                      <div>
                                          <p className="text-sm">5x</p>
                                      </div>
                                      <div>
                                          <p className="text-sm">N4500.00</p>
                                      </div>
                                    </div>
                                    <div className="flex items-center justify-between mb-[20px]">
                                      <div className="w-[120px] h-[80px]">
                                          <img src={HotImg} alt="product-img" className="w-full h-full object-fit" />
                                      </div>
                                      <div>
                                          <p className="text-sm">Lemon Fresh Body Wash</p>
                                      </div>
                                      <div>
                                          <p className="text-sm">5x</p>
                                      </div>
                                      <div>
                                          <p className="text-sm">N4500.00</p>
                                      </div>
                                    </div>
                                    <div className="flex items-center justify-between mb-[20px]">
                                      <div className="w-[120px] h-[80px]">
                                          <img src={HotImg} alt="product-img" className="w-full h-full object-fit" />
                                      </div>
                                      <div>
                                          <p className="text-sm">Lemon Fresh Body Wash</p>
                                      </div>
                                      <div>
                                          <p className="text-sm">5x</p>
                                      </div>
                                      <div>
                                          <p className="text-sm">N4500.00</p>
                                      </div>
                                  </div>
                                  
                                  <div className="flex justify-between">
                                      <div></div>
                                      <div>
                                          <div className="flex gap-8 justify-between mb-8">
                                              <p className="text-lg">Sub Total:</p>
                                              <p className="text-lg"> N13650.00</p>
                                          </div>
                                          <div className="flex gap-8 justify-between mb-8">
                                              <p className="text-lg">Shipping Charge:</p>
                                              <p className="text-lg"> N13650.00</p>
                                          </div>
                                          <div className="flex gap-8 justify-between mb-8">
                                              <p className="text-lg">Estimated Tax:</p>
                                              <p className="text-lg"> N13650.00</p>
                                          </div>
                                          <div className="flex gap-8 justify-between mb-[40px]">
                                              <p className="text-xl font-bold">Total:</p>
                                              <p className="text-lg"> N13650.00</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
              </div>
                          <div className="w-[30%] rounded-md">
                              <div className="bg-white rounded-md mb-[30px]">
                                  <div className="p-4">
                                      <p className="text-lg font-semibold my-4">Shipping Information:</p>
                                      <p className="text-sm mb-4 text-[#656565]">First Name</p>
                                    <p className="text-sm text-[#656565] mb-4">House 47, Wuse Avenue, Behind Bank Plaza, Zone 2, Abuja, Nigeria.</p>
                                      <div className="flex justify-between mb-4">
                                          <p className="text-sm text-[#656565]">Phone</p>
                                          <p className="text-sm text-[#656565]">07032145887</p>
                                      </div>
                                      <div className="flex justify-between mb-4">
                                          <p className="text-sm text-[#656565]">Mobile</p>
                                          <p className="text-sm text-[#656565]">07032145887</p>
                                    </div>
                                  </div>
                              </div>
                              <div className="bg-white rounded-md">
                                  <div className="p-4">
                                    <p className="text-lg font-semibold my-4">Billing Information:</p>
                                      <div className="flex justify-between mb-2">
                                          <p className="text-sm text-[#656565]">Payment Type:</p>
                                          <p className="text-sm text-[#656565]">Credit Card</p>
                                      </div>
                                      <div className="flex justify-between mb-2">
                                          <p className="text-sm text-[#656565]">Provider:</p>
                                          <p className="text-sm text-[#656565]">Master Card</p>
                                      </div>
                                      <div className="flex justify-between mb-2">
                                          <p className="text-sm text-[#656565]">Validity:</p>
                                          <p className="text-sm text-[#656565]">Master Card</p>
                                    </div>
                                    <div className="flex justify-between mb-2">
                                          <p className="text-sm text-[#656565]">CVV:</p>
                                          <p className="text-sm text-[#656565]">Master Card</p>
                                    </div>
                                    <div className="flex justify-between mb-2">
                                          <p className="text-sm text-[#656565]">Payment Status:</p>
                                          <p className="text-sm text-[#656565]">Successful</p>
                                      </div>
                                      <div className="flex justify-between mb-2">
                                          <p className="text-sm text-[#656565]">Time Paid:</p>
                                          <p className="text-sm text-[#656565]">Master Card</p>
                                      </div>
                                      <div className="flex justify-between mb-2">
                                          <p className="text-sm text-[#656565]">Currency:</p>
                                          <p className="text-sm text-[#656565]">Master Card</p>
                                    </div>
                                    <div className="flex justify-between mb-2">
                                          <p className="text-sm text-[#656565]">Reference Code:</p>
                                          <p className="text-sm text-[#656565]">Master Card</p>
                                    </div>
                                 </div>
                              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Order;
