import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import Footer from "components/footer/Footer";
import Header from "components/home/Header";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Rating from "@mui/material/Rating";
import FavoriteIcon from "@mui/icons-material/Favorite";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Dashpic from "../../components/assets/dashpic.png";
import { useSelector, useDispatch } from "react-redux";
import { addToCarts } from "redux/slices/cartSlice";
import { addToWishList } from "redux/slices/wishListSlice";
import { Link, useNavigate } from "react-router-dom";
import {
	removeFromCart,
	increaseQuantity,
	decreaseQuantity,
	clearCart,
} from "redux/slices/cartSlice";
import { selectUser } from "redux/slices/authSlice";
import { updateLocalStorage } from "redux/slices/cartSlice";
import MediaQuery from "components/media-query/MediaQuery";
import httpService from "services/httpService";
import { productPlacholder } from "components/home/data";
import { getAllProducts } from "services/productCRUD";

const Cart = () => {
	const dispatch = useDispatch();
	const address = useRef();
	const navigate = useNavigate();
	const [products, setProducts] = useState([]);
	const productsPerPage = 4;
	const [currentPage, setCurrentPage] = useState(1);
	const [locations, setLocations] = useState([]);
	const [selectedState, setSelectedState] = useState("");
	const [selectedCity, setSelectedCity] = useState("");
	const [zip, setZip] = useState("");
	const [country, setCountry] = useState("");
	const [shippingPrice, setShippingPrice] = useState(0);
	const [amount, setAmount] = useState(0);
	const [loading, setLoading] = useState(false);

	// fetch all products
	const fetchAllProducts = async () => {
		try {
			const response = await getAllProducts();
			const { data } = response;
			const productData = data.length > 0 ? data : productPlacholder;
			setProducts(productData);
		} catch (error) {
			console.error("Error fetching products:", error);
		}
	};

	useEffect(() => {
		fetchAllProducts();
	}, []);

	const { carts } = useSelector((state) => state.carts);
	const user = useSelector(selectUser);
	const subTotal = carts
		.map((item) => item.price * item.quantity)
		.reduce((a, b) => a + b, 0)
		.toFixed(2);
	const tax = subTotal * (7.5 / 100).toFixed(2);

	const removeItem = (itemId) => {
		dispatch(removeFromCart({ id: itemId }));
		updateLocalStorage(carts.updatedCarts);
	};
	useEffect(() => {
		httpService
			.get("/location/fetch/all")
			.then((response) => {
				setLocations(response.locations);
			})
			.catch((error) => console.error("Error fetching data:", error));
	}, []);

	// Handle state selection
	const handleStateChange = (event) => {
		setSelectedState(event.target.value);
		setSelectedCity("");
		setShippingPrice("");
	};

	// Handle city selection
	const handleCityChange = (event) => {
		setSelectedCity(event.target.value);
		const selectedLocation = locations.find(
			(location) => location.state === selectedState,
		);
		const selectedCityData = selectedLocation.cities.find(
			(city) => city.name === event.target.value,
		);
		setShippingPrice(selectedCityData.shipping_price);
	};

	useEffect(() => {
		const newTotalAmount =
			parseFloat(subTotal) + parseFloat(shippingPrice) + parseFloat(tax);
		// ensure that if NaN or undefined is returned, it should be converted to 0
		if (isNaN(newTotalAmount) || newTotalAmount === undefined) {
			setAmount(0);
			return;
		}
		setAmount(newTotalAmount);
	}, [subTotal, shippingPrice, tax]);

	const userPhone = user?.phone ?? "DefaultPhoneNumber";

	const orderList = carts?.map((item) => {
		return {
			product: item.id,
			quantity: item.quantity,
			price: item.price,
			size: item.size,
			name: item.name,
			image: item.image[0],
		};
	});

	const sendData = (e) => {
		e.preventDefault();
		setLoading(true);
		if (shippingPrice === 0) {
			toast.error("Please choose your delivery location", {
				autoClose: 3000,
				position: "top-right",
			});
			setLoading(false);
			return;
		}
		if (
			amount === 0 ||
			amount === undefined ||
			isNaN(amount) ||
			amount === null
		) {
			toast.error("No zero amount is allowed", {
				autoClose: 3000,
				position: "top-right",
			});
			setLoading(false);
			return;
		} else {
			const data = {
				shippingInfo: {
					address: address.current.value,
					city: selectedCity,
					state: selectedState,
					country: country,
					zipCode: zip,
					phoneNumber: userPhone,
				},
				orderItems: orderList,
				itemsPrice: subTotal,
				taxPrice: tax,
				shippingPrice: shippingPrice,
				grandTotalPrice: amount,
			};

			httpService
				.post("/user-order/init/order", data)
				.then((response) => {
					if (response.status === "success") {
						setLoading(false);
						navigate(`/my-order/invoice/${response?.data._id}?open=true`);
						dispatch(clearCart());
					}
					setLoading(false);
				})
				.catch((error) => {
					console.error("Error occurred while sending data:", error);
					setLoading(false);
				});
		}
	};
	const addToCart = (itemId) => {
		if (!itemId) {
			console.error("Invalid item data:", itemId);
			return;
		}

		let item = {
			id: itemId._id,
			quantity: 1,
			price: itemId?.variants[0].item[0].price,
			size: itemId?.variants[0].item[0].size,
			name: itemId.name,
			image: itemId.images?.map((img) => img.url),
		};
		dispatch(addToCarts(item));
	};

	const addToWishHandler = (product) => {
		dispatch(addToWishList(product));
	};

	const indexOfLastProduct = currentPage * productsPerPage;
	const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
	const currentProducts = products.slice(
		indexOfFirstProduct,
		indexOfLastProduct,
	);

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<div className="min-h-screen flex flex-col">
			<div className="flex-grow">
				<Header />
				{!user ? (
					<div className="flex justify-center items-center py-[30px]">
						<h2 className="text-xl">Please Log in to continue.</h2>
						<Link className="ml-4 font-semibold text-xl text-red-500" to="/login">
							Login
						</Link>
					</div>
				) : (
					<>
						{/* Small screen start */}
						<MediaQuery min="sm" max="sm-end">
							<div className="px-4 py-6 mb-12">
								<h2 className="text-2xl text-[#3F3F95] py-4">SHOPPING CART</h2>
								{!carts.length && (
									<div className="flex flex-col items-center py-6">
										<h2 className="text-xl text-center mb-4">
											You don't have any products in carts.
										</h2>
										<Link className="font-semibold text-xl text-red-500" to="/products">
											Checkout our Products
										</Link>
									</div>
								)}

								<div className="flex flex-col gap-6 mb-8">
									<div className="w-full h-auto flex flex-col gap-4">
										<div className="border bg-[#FAFAFD] rounded-md">
											<div className="flex flex-col gap-2 p-4">
												<div className="flex items-center gap-4">
													<Avatar
														src={Dashpic}
														alt="Profile Picture"
														sx={{ width: 60, height: 60 }}
													/>
													<div>
														<p className="text-lg uppercase mb-2">
															{user?.firstName ?? "no data"} {user?.lastName ?? "no data"}
														</p>
														<p className="text-lg mb-2">+{user?.phone ?? "no data"}</p>
														<p className="text-lg">{user?.email ?? "no data"}</p>
													</div>
												</div>
											</div>
										</div>
										<div className="p-4 bg-[#FAFAFD] rounded-md">
											<div className="flex items-center gap-2">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 24 24"
													width="24"
													height="24"
												>
													<path fill="none" d="M0 0h24v24H0z" />
													<path d="M18.364 17.364L12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
												</svg>
											</div>
											<div className="flex flex-col p-4">
												<div className="w-full mb-4">
													<div className="flex flex-col gap-2">
														<label htmlFor="address" className="text-lg">
															SHIPPING ADDRESS
														</label>
														<textarea
															id="address"
															className="border resize-none w-full p-2"
															cols="26"
															rows="2"
															ref={address}
														></textarea>
													</div>
												</div>
												<div className="w-full mb-4">
													<div className="flex flex-col gap-4">
														<div className="flex justify-between items-center">
															<label htmlFor="country" className="text-lg">
																Country
															</label>
															<input
																id="country"
																type="text"
																className="border w-[200px] p-2"
																onChange={(e) => setCountry(e.target.value)}
																value={country}
															/>
														</div>
														<div className="flex justify-between items-center">
															<label htmlFor="zip" className="text-lg">
																Zip
															</label>
															<input
																id="zip"
																type="text"
																className="border w-[200px] p-2"
																onChange={(e) => setZip(e.target.value)}
																value={zip}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="border bg-[#FAFAFD] rounded-md p-4">
											<div className="flex flex-col gap-4">
												<div className="flex items-center gap-2">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 24 24"
														width="24"
														height="24"
													>
														<path fill="none" d="M0 0h24v24H0z" />
														<path d="M18.364 17.364L12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
													</svg>
												</div>
												<p className="text-lg mb-4">CHOOSE A DELIVERY LOCATION</p>
												<div className="flex flex-col gap-2">
													<Select
														label="State"
														value={selectedState}
														onChange={handleStateChange}
														fullWidth
													>
														<MenuItem disabled value="">
															Select a State
														</MenuItem>
														{locations?.map((location) => (
															<MenuItem key={location._id} value={location.state}>
																{location.state}
															</MenuItem>
														))}
													</Select>
													<Select
														label="City"
														value={selectedCity}
														onChange={handleCityChange}
														fullWidth
													>
														<MenuItem disabled value="">
															Select a City
														</MenuItem>
														{locations?.map((location) => {
															if (location.state === selectedState) {
																return location.cities.map((city) => (
																	<MenuItem key={city.name} value={city.name}>
																		{city.name}
																	</MenuItem>
																));
															}
															return null;
														})}
													</Select>
												</div>
											</div>
										</div>
										<div className="text-center mt-4">
											<Link to="/products">
												<button className="bg-[#3F3F95] text-white py-2 px-4 rounded-md hover:bg-black">
													Continue Shopping
												</button>
											</Link>
										</div>
									</div>
									{carts.length > 0 && (
										<>
											<div className="w-full bg-[#FAFAFD] border rounded-md h-auto overflow-y-auto my-3">
												<div className="flex flex-col p-4">
													{carts.map((item, index) => (
														<div key={item.id} className="flex flex-col gap-4 mb-4">
															<div className="flex gap-4 justify-between">
																<div className="flex gap-4">
																	<div className="w-[100px] h-[90px] relative">
																		<img
																			src={item.image[0]}
																			alt="productImg"
																			className="w-full h-full rounded-md object-cover"
																		/>
																		<div className="flex items-center justify-center bg-[#EBEBF8] border border-[#3F3F95] text-center w-8 h-8 p-2 rounded-full absolute -top-2 -right-4 text-black">
																			{item.quantity}
																		</div>
																	</div>
																	<div className="mt-4">
																		<p className="text-lg text-[#6C6C7B] mb-3">{item.name}</p>
																		<p className="text-lg">{item.size}</p>
																	</div>
																</div>
																<div>
																	<p className="text-sm">
																		N
																		{(item.price * item.quantity)
																			.toFixed(2)
																			.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
																	</p>
																	<button
																		className="py-1 px-2 mt-2 text-white bg-red-500 rounded-md flex items-center justify-center"
																		onClick={() => removeFromCart(item.id)}
																	>
																		<svg
																			xmlns="http://www.w3.org/2000/svg"
																			fill="none"
																			viewBox="0 0 24 24"
																			stroke="currentColor"
																			className="w-5 h-5"
																		>
																			<path
																				strokeLinecap="round"
																				strokeLinejoin="round"
																				strokeWidth="2"
																				d="M6 18L18 6M6 6l12 12"
																			/>
																		</svg>
																	</button>
																</div>
															</div>
															<hr className="h-px bg-gray-200 border-0 dark:bg-gray-700" />
														</div>
													))}
												</div>
												<div className="p-4">
													<div className="flex justify-between">
														<p className="text-xl text-[#6C6C7B]">Subtotal</p>
														<p className="text-xl font-semibold">₦{subTotal}</p>
													</div>
													<div className="flex justify-between">
														<p className="text-xl text-[#6C6C7B]">Shipping</p>
														<p className="text-xl font-semibold">₦{shippingPrice}</p>
													</div>
													<div className="flex justify-between">
														<p className="text-xl text-[#6C6C7B]">Tax (7.5%)</p>
														<p className="text-xl font-semibold">₦{tax}</p>
													</div>
													<hr className="h-px bg-gray-200 border-0 dark:bg-gray-700 my-4" />
													<div className="flex justify-between">
														<p className="text-xl">Total</p>
														<p className="text-xl font-semibold">
															N
															{Number(amount)
																.toFixed(2)
																.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
														</p>
													</div>
													<div className="text-center mt-4 py-6">
														<button
															onClick={(e) => sendData(e)}
															disabled={loading}
															className="py-2 px-6 bg-[#3F3F95] text-white text-xl rounded-md hover:bg-black"
														>
															{loading ? "Processing..." : "Place Order"}
														</button>
													</div>
												</div>
											</div>
										</>
									)}
								</div>
								<div>
									<h3 className="text-2xl text-[#3F3F95] py-4">Similar Products </h3>
									<div className="flex gap-7">
										{products?.map((item, index) => (
											<div
												key={item._id}
												className="w-[300px] bg-white shadow-xl rounded-md"
											>
												<div className="p-4">
													<Link to={`/product/${item._id}`} className="text-decoration-none">
														<div className="relative">
															{item.images?.map((img, idx) => (
																<img
																	key={idx}
																	src={img.url}
																	alt="hotimage"
																	className="w-full h-[300px] object-fit"
																/>
															))}
															<div className="bg-black/[.2] p-2 rounded-md absolute bottom-1 right-1 text-white">
																{item.inStock === true ? "In Stock" : "Out of Stock"}
															</div>
														</div>
													</Link>

													<div className="flex justify-between py-4">
														<p>{item.name}</p>

														<p className="text-black font-semibold">
															₦{" "}
															{item.variants
																.find((variant) => variant.quantityType === "single")
																?.item?.[0]?.price.toFixed(2)
																.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
														</p>
													</div>
													<p>
														Brand: <span className="font-semibold">{item.brand.name}</span>
													</p>
													<div>
														<Rating name="read-only" value={item.ratings} readOnly />
													</div>
													<div className="flex justify-between gap-4 items-center">
														<div>
															<button
																onClick={() => addToCart(item)}
																disabled={!item.inStock}
																className="mt-2 p-2 px-4 rounded-md border border-[#3F3F95] text-[#3F3F95] hover:bg-[#3F3F95] hover:text-white"
															>
																Add to Cart
															</button>
														</div>
														<div>
															<IconButton
																onClick={() => addToWishHandler(item)}
																aria-label="add to favorites"
															>
																<FavoriteIcon />
															</IconButton>
														</div>
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
						</MediaQuery>
						{/* small screen end */}

						{/* medium screen start */}
						<MediaQuery min="md" max="lg">
							<div className="px-10 py-10 mb-12">
								<h2 className="text-3xl text-[#3F3F95] py-4">SHOPPING CART</h2>
								{!carts.length && (
									<div className="flex justify-center items-center py-8">
										<h2 className="text-xl">You don't have any products in your cart.</h2>
										<Link
											className="ml-4 font-semibold text-xl text-red-500"
											to="/products"
										>
											Checkout our Products
										</Link>
									</div>
								)}
								<div className="flex flex-col gap-8 mb-8">
									<div className="w-full h-[600px] mb-4 flex flex-col gap-5">
										<div className="border bg-[#FAFAFD] rounded-md">
											<div className="flex gap-7 p-4">
												<Avatar
													src={Dashpic}
													alt="Profile Picture"
													sx={{ width: 60, height: 60 }}
												/>
												<div>
													<p className="text-lg uppercase mb-4">
														{user?.firstName} {user?.lastName}
													</p>
													<p className="text-lg mb-4">+{user?.phone}</p>
													<p className="text-lg mb-4">{user?.email}</p>
												</div>
											</div>
										</div>
										<div className="p-4 bg-[#FAFAFD] rounded-md">
											<div className="flex gap-2 items-center">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 24 24"
													width="24"
													height="24"
												>
													<path fill="none" d="M0 0h24v24H0z" />
													<path d="M18.364 17.364L12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
												</svg>
												<label htmlFor="address" className="text-lg ml-2">
													SHIPPING ADDRESS
												</label>
											</div>
											<textarea
												id="address"
												className="border resize-none w-full p-2 mt-2"
												cols="30"
												rows="2"
												ref={address}
											></textarea>
											<div className="flex justify-between items-center mt-4">
												<div className="w-[45%]">
													<label htmlFor="country" className="text-lg">
														Country
													</label>
													<input
														id="country"
														type="text"
														className="border w-full p-2 mt-2"
														onChange={(e) => setCountry(e.target.value)}
														value={country}
													/>
												</div>
												<div className="w-[45%]">
													<label htmlFor="zip" className="text-lg">
														Zip
													</label>
													<input
														id="zip"
														type="text"
														className="border w-full p-2 mt-2"
														onChange={(e) => setZip(e.target.value)}
														value={zip}
													/>
												</div>
											</div>
										</div>
										<div className="border bg-[#FAFAFD] rounded-md p-4">
											<div className="flex gap-7">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 24 24"
													width="24"
													height="24"
												>
													<path fill="none" d="M0 0h24v24H0z" />
													<path d="M18.364 17.364L12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
												</svg>
												<div>
													<p className="text-lg mb-4">CHOOSE A DELIVERY LOCATION</p>
													<div className="flex gap-4 w-full">
														<Select
															label="State"
															value={selectedState}
															onChange={handleStateChange}
															fullWidth
														>
															<MenuItem disabled value="">
																Select a State
															</MenuItem>
															{locations?.map((location) => (
																<MenuItem key={location._id} value={location.state}>
																	{location.state}
																</MenuItem>
															))}
														</Select>
														<Select
															label="City"
															value={selectedCity}
															onChange={handleCityChange}
															fullWidth
														>
															<MenuItem disabled value="">
																Select a City
															</MenuItem>
															{locations?.map((location) => {
																if (location.state === selectedState) {
																	return location.cities.map((city) => (
																		<MenuItem key={city.name} value={city.name}>
																			{city.name}
																		</MenuItem>
																	));
																}
																return null;
															})}
														</Select>
													</div>
												</div>
											</div>
										</div>
										<div className="text-center mt-4">
											<Link to="/products">
												<button className="bg-[#3F3F95] text-white py-2 px-4 rounded-md hover:bg-black">
													Continue Shopping
												</button>
											</Link>
										</div>
									</div>
									{carts.length > 0 && (
										<div className="w-full bg-[#FAFAFD] border rounded-md h-[600px] mt-4 overflow-y-auto">
											<div className="flex flex-col p-4">
												{carts.map((item) => (
													<div key={item.id} className="flex gap-7 justify-between mb-4">
														<div className="flex gap-7">
															<div className="w-[100px] h-[90px] relative">
																<img
																	src={item.image[0]}
																	alt="productImg"
																	className="w-full h-full rounded-md object-cover"
																/>
																<div className="flex items-center justify-center bg-[#EBEBF8] border border-[#3F3F95] text-center w-8 h-8 p-2 rounded-full absolute -top-2 -right-4 text-black">
																	{item.quantity}
																</div>
															</div>
															<div className="mt-4">
																<p className="text-lg text-[#6C6C7B] mb-3">{item.name}</p>
																<p className="text-lg">{item.size}</p>
															</div>
														</div>
														<div>
															<p className="text-sm">
																N
																{(item.price * item.quantity)
																	.toFixed(2)
																	.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
															</p>
															<button
																className="py-1 px-2 mt-2 text-white bg-red-500 rounded-md flex items-center justify-center"
																onClick={() => removeFromCart(item.id)}
															>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	fill="none"
																	viewBox="0 0 24 24"
																	stroke="currentColor"
																	className="w-5 h-5"
																>
																	<path
																		strokeLinecap="round"
																		strokeLinejoin="round"
																		strokeWidth="2"
																		d="M6 18L18 6M6 6l12 12"
																	/>
																</svg>
															</button>
														</div>
													</div>
												))}
											</div>
											<div className="px-4">
												<div className="flex justify-between p-4">
													<p className="text-xl text-[#6C6C7B]">Subtotal</p>
													<p className="text-xl font-semibold">₦{subTotal}</p>
												</div>
												<div className="flex justify-between p-4">
													<p className="text-xl text-[#6C6C7B]">Shipping</p>
													<p className="text-xl font-semibold">₦{shippingPrice}</p>
												</div>
												<div className="flex justify-between p-4">
													<p className="text-xl text-[#6C6C7B]">Tax (7.5%)</p>
													<p className="text-xl font-semibold">₦{tax}</p>
												</div>
												<hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
												<div className="flex justify-between p-4">
													<p className="text-xl">Total</p>
													<p className="text-xl font-semibold">
														N
														{Number(amount)
															.toFixed(2)
															.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
													</p>
												</div>
												<div className="text-center mt-4 py-5">
													<button
														onClick={(e) => sendData(e)}
														disabled={loading}
														className="py-2 px-6 bg-[#3F3F95] text-white text-xl rounded-md hover:bg-black"
													>
														{loading ? "Processing..." : "Place Order"}
													</button>
												</div>
											</div>
										</div>
									)}
								</div>
							</div>
							<div>
								<h3 className="text-2xl text-[#3F3F95] py-4">Similar Products </h3>
								<div className="flex justify-center flex-wrap gap-7">
									{currentProducts?.map((item, index) => (
										<div
											key={item._id}
											className="w-[300px] bg-white shadow-xl rounded-md"
										>
											<div className="p-4">
												<Link to={`/product/${item._id}`} className="text-decoration-none">
													<div className="relative">
														{item.images?.map((img, idx) => (
															<img
																key={idx}
																src={img.url}
																alt="hotimage"
																className="w-full h-[300px] object-fit"
															/>
														))}
														<div className="bg-black/[.2] p-2 rounded-md absolute bottom-1 right-1 text-white">
															{item.inStock === true ? "In Stock" : "Out of Stock"}
														</div>
													</div>
												</Link>

												<div className="flex justify-between py-4">
													<p>{item.name}</p>

													<p className="text-black font-semibold">
														₦{" "}
														{item.variants
															.find((variant) => variant.quantityType === "single")
															?.item?.[0]?.price.toFixed(2)
															.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
													</p>
												</div>
												<p>
													Brand: <span className="font-semibold">{item.brand.name}</span>
												</p>
												<div>
													<Rating name="read-only" value={item.ratings} readOnly />
												</div>
												<div className="flex justify-between gap-4 items-center">
													<div>
														<button
															onClick={() => addToCart(item)}
															disabled={!item.inStock}
															className="mt-2 p-2 px-4 rounded-md border border-[#3F3F95] text-[#3F3F95] hover:bg-[#3F3F95] hover:text-white"
														>
															Add to Cart
														</button>
													</div>
													<div>
														<IconButton
															onClick={() => addToWishHandler(item)}
															aria-label="add to favorites"
														>
															<FavoriteIcon />
														</IconButton>
													</div>
												</div>
											</div>
										</div>
									))}
								</div>
							</div>
						</MediaQuery>
						{/* medium screen end */}

						{/* large screen start */}
						<MediaQuery min="lg">
							<div className="px-[80px] py-[40px] mb-[50px]">
								<h2 className="text-2xl text-[#3F3F95] py-4">SHOPPING CART</h2>
								{!carts.length && (
									<div className="flex justify-center items-center py-[30px]">
										<h2 className="text-xl">You don't have any products in carts.</h2>
										<Link
											className="ml-4 font-semibold text-xl text-red-500"
											to="/products"
										>
											Checkout our Products
										</Link>
									</div>
								)}

								<div className="flex flex-col md:flex-row gap-7 mb-8">
									<div className="w-[100%] md:w-[60%]  h-[600px] flex flex-col gap-[20px]">
										<div className="border bg-[#FAFAFD] rounded-md">
											<div className="flex gap-7 p-4">
												<div>
													<Avatar
														src={Dashpic}
														alt="Profile Picture"
														sx={{ width: 60, height: 60 }}
													/>
												</div>
												<div>
													<p className="text-lg uppercase mb-4">
														{user?.firstName} {user?.lastName}
													</p>
													<p className="text-lg mb-4">+{user?.phone}</p>
													<p className="text-lg mb-4">{user?.email}</p>
												</div>
											</div>
										</div>
										<div className="p-4 bg-[#FAFAFD] rounded-md">
											<div>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 24 24"
													width="24"
													height="24"
												>
													<path fill="none" d="M0 0h24v24H0z" />
													<path d="M18.364 17.364L12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
												</svg>
											</div>
											<div className="flex flex-col p-4">
												<div className="w-[400px] mb-4">
													<div className="flex flex-col gap-2">
														<label htmlFor="address" className="text-lg">
															SHIPPING ADDRESS
														</label>
														<textarea
															id="address"
															className="border resize-none w-full p-2"
															cols="26"
															rows="2"
															ref={address}
														></textarea>
													</div>
												</div>
												<div className="w-[400px] mb-4">
													<div className="flex justify-between items-center mb-4">
														<label htmlFor="country" className="text-lg">
															Country
														</label>
														<input
															id="country"
															type="text"
															className="border w-[200px] p-2"
															onChange={(e) => setCountry(e.target.value)}
															value={country}
														/>
													</div>
													<div className="flex justify-between items-center">
														<label htmlFor="zip" className="text-lg">
															Zip
														</label>
														<input
															id="zip"
															type="text"
															className="border w-[200px] p-2"
															onChange={(e) => setZip(e.target.value)}
															value={zip}
														/>
													</div>
												</div>
											</div>
										</div>

										<div className="border bg-[#FAFAFD] rounded-md p-4">
											<div className="flex gap-7">
												<div>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 24 24"
														width="24"
														height="24"
													>
														<path fill="none" d="M0 0h24v24H0z" />
														<path d="M18.364 17.364L12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
													</svg>
												</div>
												<div>
													<p className="text-lg mb-4">CHOOSE A DELIVERY LOCATION</p>
													<div className="flex gap-2 w-[400px]">
														<Select
															label="State"
															value={selectedState}
															onChange={handleStateChange}
															fullWidth
														>
															<MenuItem disabled value="">
																Select a State
															</MenuItem>
															{locations?.map((location) => (
																<MenuItem key={location._id} value={location.state}>
																	{location.state}
																</MenuItem>
															))}
														</Select>

														<Select
															label="City"
															value={selectedCity}
															onChange={handleCityChange}
															fullWidth
														>
															<MenuItem disabled value="">
																Select a City
															</MenuItem>
															{locations?.map((location) => {
																if (location.state === selectedState) {
																	return location.cities.map((city) => (
																		<MenuItem key={city.name} value={city.name}>
																			{city.name}
																		</MenuItem>
																	));
																}
																return null;
															})}
														</Select>
													</div>
												</div>
											</div>
											<div className="flex gap-7"></div>
										</div>
									</div>
									{carts.length > 0 && (
										<div className="w-[100%] md:w-[40%] bg-[#FAFAFD] border rounded-md h-[600px] overflow-y-auto">
											<div className="flex flex-col p-4">
												{carts.length > 0 &&
													carts?.map((item, index) => {
														return (
															<div key={item.id}>
																<div className="flex gap-7 justify-between mb-4">
																	<div className="flex gap-7">
																		<div className="w-[100px] h-[90px] relative">
																			<img
																				src={item.image[0]}
																				alt="productImg"
																				className="w-full h-full rounded-md object-fit"
																			/>
																			<div className="flex items-center justify-center bg-[#EBEBF8] border border-[#3F3F95] text-center w-8 h-8 p-2 rounded-full absolute -top-2 -right-4 text-black">
																				{item.quantity}
																			</div>
																		</div>
																		<div className="mt-4">
																			<p className="text-lg text-[#6C6C7B] mb-3">{item.name}</p>
																			<p className="text-lg">{item.size}</p>
																		</div>
																	</div>
																	<div className="mt-4">
																		<p className="text-lg">
																			₦
																			{(item.price * item.quantity)
																				.toFixed(2)
																				.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
																		</p>
																	</div>
																</div>
																<hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
															</div>
														);
													})}
											</div>
											<div>
												<div className="flex justify-between p-4">
													<p className="text-xl text-[#6C6C7B]">Subtotal</p>
													<p className="text-xl font-semibold">₦{subTotal}</p>
												</div>
												<div className="flex justify-between p-4">
													<p className="text-xl text-[#6C6C7B]">Shipping</p>
													<p className="text-xl font-semibold">₦{shippingPrice}</p>
												</div>
												<div className="flex justify-between p-4">
													<p className="text-xl text-[#6C6C7B]">Tax(7.5%)</p>
													<p className="text-xl font-semibold">₦{tax}</p>
												</div>
												<hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
												<div className="flex justify-between p-4">
													<p className="text-xl">Total</p>
													<p className="text-xl font-semibold">
														₦
														{Number(amount)
															.toFixed(2)
															.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
													</p>
												</div>
												<div className="text-center mt-4 py-[20px]">
													<button
														onClick={(e) => sendData(e)}
														disabled={loading}
														className="py-2 px-6 bg-[#3F3F95] text-white text-xl rounded-md hover:bg-black"
													>
														{loading ? "Processing..." : "Place Order"}
													</button>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className="flex flex-col gap-[30px]">
									{carts.length > 0 &&
										carts?.map((item, index) => {
											return (
												<div key={item.id} className="bg-[#FAFAFD] p-8 mb-4">
													<div className="flex justify-between">
														<div className="flex gap-7">
															<div className="w-[100px] h-[100px] md:w-[270px] md:h-[150px]">
																<img
																	src={item.image[0]}
																	alt="hot-imgs"
																	className="w-full h-full object-fit"
																/>
															</div>
															<div>
																<p className="text-xl">{item.name}</p>
																<div className="flex gap-7 items-center py-4">
																	<div>
																		<p className="text-lg text-[#464545">Quantity</p>
																	</div>
																	<div className="flex items-center">
																		<button
																			onClick={() => dispatch(decreaseQuantity(item))}
																			className="p-2 border border-[#F7F7FC] bg-white"
																		>
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				viewBox="0 0 24 24"
																				width="24"
																				height="24"
																			>
																				<path fill="none" d="M0 0h24v24H0z" />
																				<path d="M5 11h14v2H5z" />
																			</svg>
																		</button>
																		<button className="py-2 px-6 border border-[#F7F7FC] bg-white">
																			{item.quantity}
																		</button>
																		<button
																			onClick={() => dispatch(increaseQuantity(item))}
																			className="p-2 border border-[#F7F7FC] bg-white"
																		>
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				viewBox="0 0 24 24"
																				width="24"
																				height="24"
																			>
																				<path fill="none" d="M0 0h24v24H0z" />
																				<path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" />
																			</svg>
																		</button>
																	</div>
																</div>
															</div>
														</div>
														<div className="flex flex-col items-center gap-[20px]">
															<p className="text-xl font-semibold">
																N
																{(item.price * item.quantity)
																	.toFixed(2)
																	.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
															</p>
															<button onClick={() => removeItem(item.id)}>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	viewBox="0 0 24 24"
																	width="24"
																	height="24"
																>
																	<path fill="none" d="M0 0h24v24H0z" />
																	<path
																		d="M4 8h16v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8zm3-3V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v2h5v2H2V5h5zm2-1v1h6V4H9zm0 8v6h2v-6H9zm4 0v6h2v-6h-2z"
																		fill="rgba(144,144,156,1)"
																	/>
																</svg>
															</button>
														</div>
													</div>
												</div>
											);
										})}
								</div>
								<div className="text-center mt-4">
									<Link to="/products">
										<button className="bg-[#3F3F95] text-white py-2 px-4 rounded-md hover:bg-black">
											Continue Shopping
										</button>
									</Link>
								</div>
								<div>
									<h3 className="text-2xl text-[#3F3F95] py-4">Similar Products </h3>
									<div className="flex gap-7">
										{products?.map((item, index) => (
											<div
												key={item._id}
												className="w-[300px] bg-white shadow-xl rounded-md"
											>
												<div className="p-4">
													<Link to={`/product/${item._id}`} className="text-decoration-none">
														<div className="relative">
															{item.images?.map((img, idx) => (
																<img
																	key={idx}
																	src={img.url}
																	alt="hotimage"
																	className="w-full h-[300px] object-fit"
																/>
															))}
															<div className="bg-black/[.2] p-2 rounded-md absolute bottom-1 right-1 text-white">
																{item.inStock === true ? "In Stock" : "Out of Stock"}
															</div>
														</div>
													</Link>

													<div className="flex justify-between py-4">
														<p>{item.name}</p>

														<p className="text-black font-semibold">
															₦{" "}
															{item.variants
																.find((variant) => variant.quantityType === "single")
																?.item?.[0]?.price.toFixed(2)
																.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
														</p>
													</div>
													<p>
														Brand: <span className="font-semibold">{item.brand.name}</span>
													</p>
													<div>
														<Rating name="read-only" value={item.ratings} readOnly />
													</div>
													<div className="flex justify-between gap-4 items-center">
														<div>
															<button
																onClick={() => addToCart(item)}
																disabled={!item.inStock}
																className="mt-2 p-2 px-4 rounded-md border border-[#3F3F95] text-[#3F3F95] hover:bg-[#3F3F95] hover:text-white"
															>
																Add to Cart
															</button>
														</div>
														<div>
															<IconButton
																onClick={() => addToWishHandler(item)}
																aria-label="add to favorites"
															>
																<FavoriteIcon />
															</IconButton>
														</div>
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
						</MediaQuery>
						{/* large screen end */}

						{/* pagination */}
						<div className="flex flex-wrap justify-center mt-4">
							{Array.from(
								{ length: Math.ceil(products.length / productsPerPage) },
								(_, index) => (
									<button
										key={index}
										onClick={() => paginate(index + 1)}
										className={`mx-2 px-4 py-2 border ${
											currentPage === index + 1 ? "bg-gray-300" : "bg-white"
										}`}
									>
										{index + 1}
									</button>
								),
							)}
						</div>
					</>
				)}
			</div>
			<Footer />
		</div>
	);
};

export default Cart;
