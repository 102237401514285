import React from "react";
import Envelop from "../../assets/envelopLogo.svg";
import AuthWrapper from "../AuthWrapper";
import Popup from "reactjs-popup";
import OtpScreen from "./OTP";
import { useLocation } from "react-router-dom";

const RegisterConfirm = () => {
  const location = useLocation();

  return (
    <AuthWrapper>
      <div>
        <div>
          <div className=" ml-6">
            <div className=" grid content-center ">
              <div className="bg-eb-secondary rounded-lg pt-5 pb-5">
                <div className="grid justify-center mt-[100px]">
                  <img src={Envelop} alt="envelop" />
                </div>
                <div className="text-[25px] font-medium text-eb-primary grid justify-center mt-[24px]">
                  Wow! you have an account now
                </div>
                <div className="font-light text-[20px] grid justify-center mt-[24px]">
                  <div> We’ve sent a confirmation email to </div>
                </div>
                <div className="font-light text-[20px] grid justify-center">
                  <div className="text-eb-primary">{location.state.email}</div>
                </div>
                <Popup
                  trigger={
                    <div className="grid justify-center mt-[24px]">
                      <button className="bg-eb-primary text-white h-[48px] w-[106px] grid justify-center content-center rounded-lg">
                         Continue
                      </button>
                    </div>
                  }
                  overlayStyle={{
                    background: "rgba(0,0,0,0.45)",
                  }}
                  contentStyle={{
                  
                    overflowY: "auto",
                    maxHeight: "calc(100vh - 100px)",
                  }}
                  modal
                >
                  <div className="flex justify-center">
                    <div className="w-[90vw] md:w-[100%] bg-white self-center p-[23px] rounded-lg ">
                      <OtpScreen email={location.state.email} />
                    </div>
                  </div>
                </Popup>

                <div>
                  <div className="font-light text-[20px] grid justify-center content-end mt-[24px]">
                    <div>Didn’t receive an email?</div>
                  </div>
                  <div className="font-light text-[20px] grid justify-center">
                    <button className="text-eb-primary">Click to resend</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthWrapper>
  );
};

export default RegisterConfirm;
