import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import Rating from "@mui/material/Rating";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Footer from "components/footer/Footer";
import Header from "components/home/Header";
import SlidImg from "../../components/assets/bannerImg.png";
import HotImg from "../../components/assets/hotproduct.png";
import { useSelector, useDispatch } from "react-redux";
import Loader from "components/loader/Loader";
import { getAllBrands } from "services/brandCRUD";

const Brands = () => {
	const dispatch = useDispatch();
	const [brands, setBrands] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const fetchAllBrands = async () => {
		try {
			const response = await getAllBrands();
			const { data } = response;
			setBrands(data);
		} catch (error) {
			console.error("Error fetching brands:", error);
			setError(error.message);
		}
	};

	useEffect(() => {
		setLoading(true);
		fetchAllBrands()
			.then(() => setLoading(false))
      .catch((err) => {
        setLoading(false);
        setError(err.message);
      });
	}, []);

	if (loading) {
		return <Loader />;
	}
	if (error) {
		return <div>Error: {error}</div>;
	}

	return (
		<div className="min-h-screen flex flex-col">
			<div className="flex-grow">
				<Header />
				<div className="px-[80px] py-[40px]">
					<div className="w-full flex">
						<div className="w-[50%] bg-[#D8E1E6]">
							<div className="p-[50px]">
								<h3 className="text-[#3F3F95] my-4 font-bold text-xl">PRECIOUS</h3>
								<p className="text-lg text-[#3F3F95] mb-[40px]">
									With a full range of high-performance shampoos, conditioners, styling,
									and treatment products, Precious has haircare to cater to every hair
									goal. With renowned collections like Bed Head Small Talk to thicken and
									volumise hair, Dumb Blonde to nourish and protect, and After Party to
									smooth frizz and flyaways, Bed Head products have you sorted.
								</p>
								<button className="bg-[#3F3F95] hover:bg-black p-2 px-4 rounded-lg  text-white">
									See All Products
								</button>
							</div>
						</div>
						<div className="w-[50%]">
							<img
								src={SlidImg}
								className="w-full h-full object-fit"
								alt="bannerImg"
							/>
						</div>
					</div>
					<div>
						<h3 className="text-xl text-center text-[#3F3F95] py-[40px]">PRECIOUS</h3>
					</div>
					{brands && (
						<div className="flex justify-center flex-wrap gap-5">
							{brands?.map((item, index) => (
								<div key={item._id} className="w-[300px] bg-white shadow-xl rounded-md">
									<div className="p-4">
										<img
											src={item.image.url}
											alt="brandimg"
											className="w-full h-[300px] object-fit"
										/>
										<p>{item.name}</p>
									</div>
								</div>
							))}
						</div>
					)}
				</div>
				<Footer />
			</div>
		</div>
	);
};

export default Brands;
