import React from "react";

const PrimaryAccountDetails = ({ data, isPaid }) => {
	return (
		<div className="my-2">
			<p className="text-sm my-2">Payment Method:</p>
			<div className="border border-gray-200 rounded p-2">
				<p className="text-sm text-[#656565]">
					<span className="font-semibold"> Bank:</span> &nbsp;
					{data?.bankName ? data.bankName : "---"}
				</p>
				<p className="text-sm text-[#656565]">
					<span className="font-semibold">Account Number:</span> &nbsp;
					{data?.accountNumber ? data.accountNumber : "---"}
				</p>
				<p className="text-sm text-[#656565]">
					<span className="font-semibold">Account Name:</span> &nbsp;
					{data?.accountName ? data.accountName : "---"}
				</p>
				<p className="text-sm text-[#656565]">
					<span className="font-semibold">Transaction Reference:</span> &nbsp;
					{data?.reference ? data.reference : "---"}
				</p>
				<hr className="my-2" />
				{!isPaid ? (
					<>
						<p className="text-sm text-[#656565] mt-2">
							Payment to be made into the above account details.
						</p>
						<p className="text-sm text-[#656565]">
							Use the transaction reference as the payment reference.
						</p>
						<p className="text-sm text-[#656565]">
							Please note that your order will not be processed until payment is
							confirmed.
						</p>
					</>
				) : (
					<div className="bg-green-500 text-white px-2 py-4 rounded text-center">
						<span className="font-bold">Paid</span>
					</div>
				)}
			</div>
		</div>
	);
};

export default PrimaryAccountDetails;
