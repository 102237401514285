export const safelyParseJson = (key) => {
     try{
        return JSON.parse(localStorage.getItem(key))
     } catch (e) {
       return null
     }
} 


export const setRememberMe = (shouldSet ) => {
     if (shouldSet) {
        localStorage.setItem('keep_me', 'always')
     }  else {
        localStorage.removeItem('keep_me')
     }
}

