import React, { useEffect, useState } from "react";
import { IconButton, Rating } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Footer from "components/footer/Footer";
import Header from "components/home/Header";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { addToWishList } from "redux/slices/wishListSlice";
import { getACategory, viewProductByCategory } from "services/categoryCRUD";
import { addToCarts } from "redux/slices/cartSlice";

const Categories = () => {
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(6);

	let { categoryId } = useParams();

	const fetchProductsByCategoryId = async (categoryId, page = 1, size = 6) => {
		try {
			setLoading(true);
			const response = await viewProductByCategory(categoryId, page, size);
			const { data } = response;
			setSelectedCategory(data);
		} catch (error) {
			console.error("Error fetching products:", error);
			setError(error.message);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchProductsByCategoryId(categoryId, currentPage, pageSize);
	}, [categoryId, currentPage, pageSize]);

	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const addToWishHandler = (product) => {
		dispatch(addToWishList(product));
	};

	const addToCart = (itemId) => {
		if (!itemId) {
			console.error("Invalid item data:", itemId);
			return;
		}

		let item = {
			id: itemId._id,
			quantity: 1,
			price: itemId?.variants[0].item[0].price,
			size: itemId?.variants[0].item[0].size,
			name: itemId.name,
			image: itemId.images?.map((img) => img.url),
		};
		dispatch(addToCarts(item));
	};

	return (
		<div className="min-h-screen flex flex-col">
			<div className="flex-grow">
				<Header />

				<div className="px-[40px] md:px-[80px] py-[40px]">
					<h2 className="text-xl font-semibold py-5">Categories</h2>

					<div className="flex flex-wrap gap-7">
						{loading ? (
							<p>Loading...</p>
						) : error ? (
							<p>Error fetching data. Please try again later</p>
						) : selectedCategory && selectedCategory?.length > 0 ? (
							selectedCategory?.map((product, index) => (
								<div className="flex flex-row gap-5 my-2" key={product._id}>
									<div className="w-[300px] bg-white shadow-xl rounded-md">
										<div className="p-4">
											<Link
												to={`/product/${product._id}`}
												className="text-decoration-none"
											>
												<div className="relative">
													{product.images?.map((img, idx) => (
														<img
															key={idx}
															src={img.url}
															alt="haircreamimage"
															className="w-full h-[300px] object-fit"
														/>
													))}
													<div className="bg-black/[.2] p-2 rounded-md absolute bottom-1 right-1 text-white">
														{product.inStock === true ? "In Stock" : "Out of Stock"}
													</div>
												</div>
											</Link>
											<div className="flex justify-between py-4">
												<p>{product.name}</p>
												<p className="text-black font-semibold">
													₦{" "}
													{product.variants
														.find((variant) => variant.quantityType === "single")
														?.item?.[0]?.price.toFixed(2)
														.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
												</p>
											</div>
											<p>
												Brand: <span className="font-semibold">{product.brand.name}</span>
											</p>
											<div>
												<Rating name="read-only" value={product.ratings} readOnly />
											</div>
											<div className="flex justify-between gap-4 items-center">
												<div>
													<button
														onClick={() => addToCart(product)}
														className="mt-2 p-2 px-4 rounded-md border border-[#3F3F95] text-[#3F3F95] hover:bg-[#3F3F95] hover:text-white"
													>
														Add to Cart
													</button>
												</div>
												<div>
													<IconButton
														onClick={() => addToWishHandler(product)}
														aria-label="add to favorites"
													>
														<FavoriteIcon />
													</IconButton>
												</div>
											</div>
										</div>
									</div>
								</div>
							))
						) : (
							<p>No Products Available for this category.</p>
						)}
					</div>
					<div className="flex flex-wrap justify-center mt-4">
						{selectedCategory &&
							Array.from(
								{ length: Math.ceil(selectedCategory.length / pageSize) },
								(_, index) => (
									<button
										key={index}
										onClick={() => paginate(index + 1)}
										className={`mx-2 px-4 py-2 border ${
											currentPage === index + 1 ? "bg-gray-300" : "bg-white"
										}`}
									>
										{index + 1}
									</button>
								),
							)}
					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
};

export default Categories;
