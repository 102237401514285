import React from "react";
import { Paper } from "@mui/material";

function Item({ item }) {
  return (
    <Paper>
      <img
        src={item.image}
        alt={item.title}
        style={{ width: "100%", height: "100%" }}
      />
    </Paper>
  );
}

export default Item;
