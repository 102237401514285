import React, { useState } from "react";
import { toast } from "react-toastify";
import Header from "components/home/Header";
import httpService from "services/httpService";

const ImageUploadForm = () => {
	const [selectedImage, setSelectedImage] = useState(null);

	const handleImageChange = (event) => {
		const image = event.target.files[0];
		setSelectedImage(image);
	};

	const formData = new FormData();
	formData.append("avatar", selectedImage);

	const handleSubmit = async (event) => {
		event.preventDefault();

		try {
			const response = await httpService.put("/user/profile/update", formData);

			toast(response.message, {
				type: "success",
				autoClose: 3000,
			});
		} catch (error) {
			console.error("Error uploading image:", error);
			toast(error.message, {
				type: "error",
				autoClose: 3000,
			});
		}
	};

	return (
		<div>
			<Header />
			<div className="m-[40px] ml-[80px]">
				<form onSubmit={handleSubmit}>
					<input type="file" onChange={handleImageChange} />
					<button type="submit" className="bg-eb-primary text-white p-3 rounded-lg ">
						Upload Image
					</button>
				</form>
			</div>
		</div>
	);
};

export default ImageUploadForm;
