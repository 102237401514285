import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { STATUS } from "../../utils/status";
import axios from "axios";
import { axiosErrorHandler } from "utils/axiosErrorHandler";
import { baseURL } from "constants/url";

//fetch all proudcts from the api end point
export const getAllProducts = createAsyncThunk(
	"products/fetchAllProducts",
	async () => {
		try {
			let response = await axios.get(`${baseURL}/product/fetch/all`);
			const { data } = response.data;
			return data;
		} catch (error) {
			return axiosErrorHandler(error);
		}
	},
);

const productSlice = createSlice({
	name: "products",
	initialState: {
		loading: false,
		products: [],
		error: null,
	},

	extraReducers: (builder) => {
		builder.addCase(getAllProducts.pending, (state, action) => {
			state.loading = true;
			state.error = null;
		});
		builder.addCase(getAllProducts.fulfilled, (state, action) => {
			state.loading = false;
			state.products = action.payload;
			state.error = null;
		});
		builder.addCase(getAllProducts.rejected, (state, action) => {
			state.loading = false;
			state.products = [];
			state.error = action.error.message;
		});
	},
});

// export const selectProductById = (state, productId) => {
//   state.products.products.status === 'success' ? state.products.products.products.find((product) => product )
// }

export default productSlice.reducer;
