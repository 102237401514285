import React from "react";

const Billing = () => {
  return (
    <div className="bg-white min-h-[546px] p-10">
      <p className="text-[30px] text-eb-primary font-smedium mb-3">Billing</p>
      <div className="border border-black flex gap-7 p-4 text-xl text-black font-medium">
        <p>Date</p>
        <p> Order No.</p>
        <p> Amount Paid </p>
        <p>Veiw Reciept </p>
        <p> Pending </p>
        <p>Action </p>
      </div>
    </div>
  );
};

export default Billing;
