import React from "react";
import Blog from "./Blog";
import Header from "components/home/Header";
import Footer from "components/footer/Footer";
import BlogPost from "./BlogPost";
import BlogImage from "./BlogImage";

const BlogPage = () => {
  return (
			<div className="min-h-screen flex flex-col">
				<div className="flex-grow">
					<Header />
					<div className="md:m-[40px] ">
						<div className="hidden md:flex">
							<BlogImage />
						</div>
						<div className="md:hidden text-eb-primary text-[25px] mt-3 grid justify-center ">
							{" "}
							BLOG
						</div>
						<div>
							<div className="hidden md:grid justify-center py-8">
								<p className=" text-eb-primary text-[25px] ">Hair Care</p>
							</div>
							<div className="px-10">
								<BlogPost />
							</div>
						</div>
						<div className="py-5">
							<Blog />
						</div>
					</div>
					<Footer />
				</div>
			</div>
		);
};

export default BlogPage;
