import React, {useState} from "react";
import Lock from "../../assets/lock.svg";
import AuthWrapper from "../AuthWrapper";
import qs from 'query-string';
import {Formik, Form, Field} from 'formik';
import { FormErrorMessage } from "components/forms/FormErrorMessage";
import { initialResetPasswordValues, resetPasswordSchema } from "modules/schemas/reset-password-schema";
import SubmitButton from "components/forms/SubmitButton";
import { resetPassword } from "services/authService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const [passwordType, setPasswordType] = useState(true);
  const togglePassword = () => {
   
    setPasswordType(!passwordType);
  };
const token = qs.parse(window.location.search)?.token;
const navigate = useNavigate();

const handleResetSubmit = async (values) => {
    try{
    
      const response = await resetPassword({...values, token});
      
      toast(
         response?.message , {
          type:'success',
          autoClose: 3000,
         }
      )
      navigate('/resetsuccess');
    } catch(e) {
     
      toast( `${e.message}`, {
        type: "error",
        autoClose: 3000,
      });
      console.log(e);
    }

   

}
  return (
    <AuthWrapper>
      <div className=" w-full max-h-[75vh]  grid content-center">

        <Formik 
          initialValues={initialResetPasswordValues}
          validationSchema={resetPasswordSchema}
          onSubmit={handleResetSubmit}
        >
        <Form>
        <div className="bg-eb-secondary max-h-[75vh] rounded-md pt-5 pb-5">
          <div className="grid justify-center">
            <img src={Lock} alt="envelop"  width={50} height={50}/>
          </div>
          <div className="text-lg font-medium text-eb-primary grid justify-center mt-[14px]">
            Set New Password
          </div>
          <div className="font-light text-md grid justify-center mt-[10px] text-eb-primary">
            <div>Your new password must be different from</div>
          </div>
          <div className="font-light text-md grid justify-center">
            <div className="text-eb-primary">previously used passwords.</div>
          </div>

          <div className="mb-[24px] mt-[24px] ml-[62px] mr-[62px] text-eb-primary">
            <div className="text-sm">New Password</div>
            <div>
              {" "}
              <Field
                className="h-[38px] w-[100%] rounded-md border border-eb-primary mt-3 pl-3"
                type={passwordType ? "password" : "text"}
                name="password"
              />{" "}
               
                  
               <div>
                  <FormErrorMessage name={"password"} />
                </div>
            </div>
          </div>

          <div className="mb-[14px] mt-[24px] ml-[62px] mr-[62px] text-eb-primary">
            <div className="text-sm">Confirm New Password</div>
            <div>
              {" "}
              <Field
                className="h-[38px] w-[100%] rounded-md border border-eb-primary mt-3 pl-3"
                name="confirm_password"
                type={passwordType ? "password" : "text"}
              />{" "}
               <div>
                  <FormErrorMessage name={"confirm_password"} />
                </div>
            </div>
          </div>

          <div  className="mb-[14px]  ml-[62px] mr-[62px] text-eb-primary" >
                    <span onClick={togglePassword}>
                      {passwordType ? (
                        <div className="flex gap-1">
                      

                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="18"
                          height="18"
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path
                            d="M12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9C2.121 6.88 6.608 3 12 3zm0 16a9.005 9.005 0 0 0 8.777-7 9.005 9.005 0 0 0-17.554 0A9.005 9.005 0 0 0 12 19zm0-2.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm0-2a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                            fill="rgba(63,63,149,1)"
                          />
                        </svg>
                        <p className="text-[12px] "> View Password</p>
                        </div>
                       
                      ) : (
                       <div className="flex gap-1">
                           
                          <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="18"
                          height="18"
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path
                            d="M17.882 19.297A10.949 10.949 0 0 1 12 21c-5.392 0-9.878-3.88-10.819-9a10.982 10.982 0 0 1 3.34-6.066L1.392 2.808l1.415-1.415 19.799 19.8-1.415 1.414-3.31-3.31zM5.935 7.35A8.965 8.965 0 0 0 3.223 12a9.005 9.005 0 0 0 13.201 5.838l-2.028-2.028A4.5 4.5 0 0 1 8.19 9.604L5.935 7.35zm6.979 6.978l-3.242-3.242a2.5 2.5 0 0 0 3.241 3.241zm7.893 2.264l-1.431-1.43A8.935 8.935 0 0 0 20.777 12 9.005 9.005 0 0 0 9.552 5.338L7.974 3.76C9.221 3.27 10.58 3 12 3c5.392 0 9.878 3.88 10.819 9a10.947 10.947 0 0 1-2.012 4.592zm-9.084-9.084a4.5 4.5 0 0 1 4.769 4.769l-4.77-4.769z"
                            fill="rgba(63,63,149,1)"
                          />
                           
                        </svg>
                        <p className="text-[12px] "> Hide Password</p>
                       </div>
                      )}
                    </span>
                  </div>

          <div className="grid justify-center ">
           

            <SubmitButton   className="bg-eb-primary hover:bg-black text-sm text-white h-[48px] w-[164px] grid justify-center content-center rounded-md">
            Reset Password
            </SubmitButton>
          </div>
        </div>
        </Form>
        </Formik>

       



      </div>
    </AuthWrapper>
  );
};

export default ResetPassword;
