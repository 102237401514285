import React from "react";
import { Box } from "@mui/material";

import "./loader.css";

const Loader = () => {
	return (
		<Box sx={container}>
			<div className="w-screen h-screen grid place-items-center">
				<div className="loader-outer">
					<div className="loader-inner"></div>
				</div>
			</div>
		</Box>
	);
};

const container = {
	width: "100vw",
	height: "100vh",
	display: "grid",
	placeItems: "center",
	position: "fixed",
	top: 0,
	left: 0,
	background: "rgba(225, 225, 225, 0.3)",
	backdropFilter: "blur(2px)",
	zIndex: 70,
};

export default Loader;
