import React from "react";
import Carousel from "react-material-ui-carousel";

import Item from "./items";
import slider from "./slider.json";

function CarouselTab() {
  return (
    <div>
      <Carousel  className=" w-[95%] h-[90%]">
        {slider.map((item) => (
          <Item key={item.id} item={item} />
        ))}
      </Carousel>

      {/* className=" w-[85%] h-[90%]" */}
    </div>
  );
}

export default CarouselTab;
