import React from "react";
import Footer from "components/footer/Footer";
import Header from "components/home/Header";
import Trophy from "../assets/trophy.svg";
import Partner1 from "../assets/partner1.svg";
import Partner2 from "../assets/partner2.svg";
import Partner3 from "../assets/partner3.svg";
import Partner4 from "../assets/partner4.svg";
import Person1 from "../assets/person1.png";
import Person2 from "../assets/person2.png";
import Person3 from "../assets/person3.png";
import Person4 from "../assets/person4.png";

const AboutUs = () => {
	return (
		<div className="min-h-screen flex flex-col">
			<div className="flex-grow">
				<Header />
				<div className="px-5 md:px-[80px] md:py-[40px] bg-white w-full">
					{/* OUR COMPANY */}
					<div className="text-semibold text-[30px] text-eb-primary">
						Our Company
					</div>
					{/* <div className="w-full flex mt-7">
          <div className="w-[100%] h-[450px] ">
            <img src={Person3} alt="person1" className="h-[450px] "  width={"100%"}/>
          </div>
        </div> */}

					<div className="w-full  mt-7 h-[450px]">
						<iframe
							className="w-full h-[450px] aspect-video "
							src="https://www.youtube.com"
							title="youtube video"
							allowFullScreen
						></iframe>
					</div>

					{/* WHAT WE DO */}
					<div>
						<div className="text-semibold text-[30px] text-eb-primary mt-7">
							What we do
						</div>
						<div className=" md:grid md:grid-cols-3 mt-10">
							<div className="  w-full grid justify-center gap-8 ">
								<div className="flex justify-center">
									<div className="grid justify-center content-center rounded-[100px] h-[60px]  w-[60px] bg-[#D9D9EA]">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											width="24"
											height="24"
										>
											<path fill="none" d="M0 0H24V24H0z" />
											<path d="M16 9l4.371 1.749c.38.151.629.52.629.928V21c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1v-9.323c0-.409.249-.777.629-.928L8 9h8zm-.385 2h-7.23L5 12.354V20h14v-1H8v-5h11v-1.646L15.615 11zM16 3c.552 0 1 .448 1 1v4H7V4c0-.552.448-1 1-1h8zm-1 2H9v1h6V5z" />
										</svg>
									</div>
								</div>

								<div className="text-eb-primary w-[277px] ">
									<div className="flex justify-center mb-3 text-[25px]">
										Manufacturer
									</div>
									<p className="flex justify-center text-[20px]">
										Production of baby, hair <br /> and body care products.
									</p>
								</div>
							</div>

							<div className="  w-full grid justify-center gap-8 ">
								<div className="flex justify-center">
									<div className="grid justify-center content-center rounded-[100px] h-[60px]  w-[60px] bg-[#D9D9EA]">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											width="24"
											height="24"
										>
											<path fill="none" d="M0 0H24V24H0z" />
											<path d="M16 9l4.371 1.749c.38.151.629.52.629.928V21c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1v-9.323c0-.409.249-.777.629-.928L8 9h8zm-.385 2h-7.23L5 12.354V20h14v-1H8v-5h11v-1.646L15.615 11zM16 3c.552 0 1 .448 1 1v4H7V4c0-.552.448-1 1-1h8zm-1 2H9v1h6V5z" />
										</svg>
									</div>
								</div>

								<div className="text-eb-primary w-[277px] ">
									<div className="flex justify-center mb-3 text-[25px]">Products</div>
									<p className="flex justify-center text-[20px]">
										100+ Registered Products
									</p>
								</div>
							</div>

							<div className="  w-full grid justify-center gap-8 ">
								<div className="flex justify-center">
									<div className="grid justify-center content-center rounded-[100px] h-[60px]  w-[60px] bg-[#D9D9EA]">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											width="24"
											height="24"
										>
											<path fill="none" d="M0 0H24V24H0z" />
											<path d="M16 9l4.371 1.749c.38.151.629.52.629.928V21c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1v-9.323c0-.409.249-.777.629-.928L8 9h8zm-.385 2h-7.23L5 12.354V20h14v-1H8v-5h11v-1.646L15.615 11zM16 3c.552 0 1 .448 1 1v4H7V4c0-.552.448-1 1-1h8zm-1 2H9v1h6V5z" />
										</svg>
									</div>
								</div>

								<div className="text-eb-primary w-[277px] ">
									<div className="flex justify-center mb-3 text-[25px]">Certified</div>
									<p className="flex justify-center text-[20px]">NAFDAC & SON</p>
								</div>
							</div>
						</div>
					</div>

					<div className="w-full flex mt-[70px]">
						<div className="w-[100%]  grid justify-center">
							<div className="md:grid md:grid-cols-2 gap-[100px]">
								<div>
									<div className="  w-full grid justify-center gap-8 ">
										<div className="flex justify-center">
											<div className="grid justify-center content-center rounded-[100px] h-[60px]  w-[60px] bg-[#D9D9EA]">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 24 24"
													width="24"
													height="24"
												>
													<path fill="none" d="M0 0H24V24H0z" />
													<path d="M16 9l4.371 1.749c.38.151.629.52.629.928V21c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1v-9.323c0-.409.249-.777.629-.928L8 9h8zm-.385 2h-7.23L5 12.354V20h14v-1H8v-5h11v-1.646L15.615 11zM16 3c.552 0 1 .448 1 1v4H7V4c0-.552.448-1 1-1h8zm-1 2H9v1h6V5z" />
												</svg>
											</div>
										</div>

										<div className="text-eb-primary w-[277px] ">
											<div className="flex justify-center mb-3 text-[25px]">Mission</div>
											<p className="flex justify-center text-[20px]">
												To manufacture world <br /> class cosmetics products <br /> that
												enhances the
												<br />
												beauty, and improves the <br /> health of our customers
											</p>
										</div>
									</div>

									<div className="  w-full  grid justify-center gap-8 mt-[70px] ">
										<div className="flex justify-center">
											<div className="grid justify-center content-center rounded-[100px] h-[60px]  w-[60px] bg-[#D9D9EA]">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 24 24"
													width="24"
													height="24"
												>
													<path fill="none" d="M0 0H24V24H0z" />
													<path d="M16 9l4.371 1.749c.38.151.629.52.629.928V21c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1v-9.323c0-.409.249-.777.629-.928L8 9h8zm-.385 2h-7.23L5 12.354V20h14v-1H8v-5h11v-1.646L15.615 11zM16 3c.552 0 1 .448 1 1v4H7V4c0-.552.448-1 1-1h8zm-1 2H9v1h6V5z" />
												</svg>
											</div>
										</div>

										<div className="text-eb-primary w-[237px] ">
											<div className="flex justify-center mb-3 text-[25px]">Vision</div>
											<p className="flex justify-center text-[20px]">
												To be a premier household cosmetic brand, having at least one of our
												products in every home across the globe.
											</p>
										</div>
									</div>
								</div>

								<div>
									<div className="  w-full  grid justify-center gap-8 m ">
										<div className="flex justify-center">
											<div className="grid justify-center content-center rounded-[100px] h-[60px]  w-[60px] bg-[#D9D9EA]">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 24 24"
													width="24"
													height="24"
												>
													<path fill="none" d="M0 0H24V24H0z" />
													<path d="M16 9l4.371 1.749c.38.151.629.52.629.928V21c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1v-9.323c0-.409.249-.777.629-.928L8 9h8zm-.385 2h-7.23L5 12.354V20h14v-1H8v-5h11v-1.646L15.615 11zM16 3c.552 0 1 .448 1 1v4H7V4c0-.552.448-1 1-1h8zm-1 2H9v1h6V5z" />
												</svg>
											</div>
										</div>

										<div className="text-eb-primary  ">
											<div className="flex justify-center text-[25px]">Core Values</div>
											<div className="flex gap-8 justify-center text-[20px]  mt-3  w-[277px]">
												<div>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 24 24"
														width="24"
														height="24"
													>
														<path
															d="M11.602 13.7599L13.014 15.1719L21.4795 6.7063L22.8938 8.12051L13.014 18.0003L6.65 11.6363L8.06421 10.2221L10.189 12.3469L11.6025 13.7594L11.602 13.7599ZM11.6037 10.9322L16.5563 5.97949L17.9666 7.38977L13.014 12.3424L11.6037 10.9322ZM8.77698 16.5873L7.36396 18.0003L1 11.6363L2.41421 10.2221L3.82723 11.6352L3.82604 11.6363L8.77698 16.5873Z"
															fill="#000"
														></path>
													</svg>
												</div>
												<p>
													{" "}
													commitment to safety of our products and in our operational
													processes
												</p>
											</div>

											<div className="flex gap-8 justify-center text-[20px]  mt-7  w-[277px]">
												<div>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 24 24"
														width="24"
														height="24"
													>
														<path
															d="M11.602 13.7599L13.014 15.1719L21.4795 6.7063L22.8938 8.12051L13.014 18.0003L6.65 11.6363L8.06421 10.2221L10.189 12.3469L11.6025 13.7594L11.602 13.7599ZM11.6037 10.9322L16.5563 5.97949L17.9666 7.38977L13.014 12.3424L11.6037 10.9322ZM8.77698 16.5873L7.36396 18.0003L1 11.6363L2.41421 10.2221L3.82723 11.6352L3.82604 11.6363L8.77698 16.5873Z"
															fill="#000"
														></path>
													</svg>
												</div>

												<p>
													{" "}
													Result- oriented approach in formulation and distribution of our
													products
												</p>
											</div>
											<div className="flex gap-8 justify-center text-[20px]  mt-7  w-[277px]">
												<div>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 24 24"
														width="24"
														height="24"
													>
														<path
															d="M11.602 13.7599L13.014 15.1719L21.4795 6.7063L22.8938 8.12051L13.014 18.0003L6.65 11.6363L8.06421 10.2221L10.189 12.3469L11.6025 13.7594L11.602 13.7599ZM11.6037 10.9322L16.5563 5.97949L17.9666 7.38977L13.014 12.3424L11.6037 10.9322ZM8.77698 16.5873L7.36396 18.0003L1 11.6363L2.41421 10.2221L3.82723 11.6352L3.82604 11.6363L8.77698 16.5873Z"
															fill="#000"
														></path>
													</svg>
												</div>

												<p>
													{" "}
													commitment to safety of our products and in our operational
													processes
												</p>
											</div>
											<div className="flex gap-8 justify-center text-[20px]  mt-7  w-[277px]">
												<div>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 24 24"
														width="24"
														height="24"
													>
														<path
															d="M11.602 13.7599L13.014 15.1719L21.4795 6.7063L22.8938 8.12051L13.014 18.0003L6.65 11.6363L8.06421 10.2221L10.189 12.3469L11.6025 13.7594L11.602 13.7599ZM11.6037 10.9322L16.5563 5.97949L17.9666 7.38977L13.014 12.3424L11.6037 10.9322ZM8.77698 16.5873L7.36396 18.0003L1 11.6363L2.41421 10.2221L3.82723 11.6352L3.82604 11.6363L8.77698 16.5873Z"
															fill="#000"
														></path>
													</svg>
												</div>
												<p>
													{" "}
													pursuit of world class standards in the quality and presentation of
													our products and excellence in our services.{" "}
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* WHY US */}

					<div className="text-semibold text-[30px] text-eb-primary mt-7">
						Why us
					</div>

					<div className="w-full  mt-4">
						<div className="w-[100%] h-[450px] ">
							{/* <div className="w-[100%] h-[450px] ">
            <img src={Person2} alt="person1" className="h-[450px] "  width={"100%"}/>
          </div> */}

							<div className="w-full  h-[450px]">
								<iframe
									className="w-full h-[450px] aspect-video "
									src="https://www.youtube.com"
									title="youtube video"
									allowFullScreen
								></iframe>
							</div>
						</div>

						<div className="md:grid  md:grid-cols-3 mt-10">
							<div className="  w-full grid justify-center gap-8 ">
								<div className="flex justify-center">
									<div className="grid justify-center content-center rounded-[100px] h-[60px]  w-[60px] bg-[#D9D9EA]">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											width="24"
											height="24"
										>
											<path fill="none" d="M0 0H24V24H0z" />
											<path d="M16 9l4.371 1.749c.38.151.629.52.629.928V21c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1v-9.323c0-.409.249-.777.629-.928L8 9h8zm-.385 2h-7.23L5 12.354V20h14v-1H8v-5h11v-1.646L15.615 11zM16 3c.552 0 1 .448 1 1v4H7V4c0-.552.448-1 1-1h8zm-1 2H9v1h6V5z" />
										</svg>
									</div>
								</div>

								<div className="text-eb-primary w-[277px] ">
									<div className="flex justify-center mb-3 text-[25px]">
										Customer Oriented
									</div>
								</div>
							</div>

							<div className="  w-full grid justify-center gap-8 ">
								<div className="flex justify-center">
									<div className="grid justify-center content-center rounded-[100px] h-[60px]  w-[60px] bg-[#D9D9EA]">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											width="24"
											height="24"
										>
											<path fill="none" d="M0 0H24V24H0z" />
											<path d="M16 9l4.371 1.749c.38.151.629.52.629.928V21c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1v-9.323c0-.409.249-.777.629-.928L8 9h8zm-.385 2h-7.23L5 12.354V20h14v-1H8v-5h11v-1.646L15.615 11zM16 3c.552 0 1 .448 1 1v4H7V4c0-.552.448-1 1-1h8zm-1 2H9v1h6V5z" />
										</svg>
									</div>
								</div>

								<div className="text-eb-primary w-[277px] ">
									<div className="flex justify-center mb-3 text-[25px]">
										High Quality Products
									</div>
								</div>
							</div>

							<div className="  w-full grid justify-center gap-8 ">
								<div className="flex justify-center">
									<div className="grid justify-center content-center rounded-[100px] h-[60px]  w-[60px] bg-[#D9D9EA]">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											width="24"
											height="24"
										>
											<path fill="none" d="M0 0H24V24H0z" />
											<path d="M16 9l4.371 1.749c.38.151.629.52.629.928V21c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1v-9.323c0-.409.249-.777.629-.928L8 9h8zm-.385 2h-7.23L5 12.354V20h14v-1H8v-5h11v-1.646L15.615 11zM16 3c.552 0 1 .448 1 1v4H7V4c0-.552.448-1 1-1h8zm-1 2H9v1h6V5z" />
										</svg>
									</div>
								</div>

								<div className="text-eb-primary w-[277px] ">
									<div className="flex justify-center mb-3 text-[25px]">
										Solution oriented
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* ACHIEVEMENTS */}

					<div>
						<div className="text-semibold text-[30px] text-eb-primary mt-7">
							Achievements
						</div>
						<div className="md:grid md:grid-cols-3 gap-10 mt-10">
							<div className="p-5 mb-5  w-full bg-eb-primary rounded-[20px] grid gap-3">
								<div className="text-white opacity-[0.6] text-sm font-bold">
									{" "}
									Chamber of Commerce Awards 2008
								</div>
								<div className="text-white text-[25px]  font-medium">
									What is the name of the Award
								</div>
								<div className=" flex justify-center">
									<img src={Trophy} alt="trophy" />
								</div>
								<div className="text-white text-[16px] font-normal">
									Chamber of Commerce Awards, organized in Enugu, Lagos, Abuja and
									Kaduna, 2008
								</div>
							</div>

							<div className="p-5 mb-5  w-full bg-eb-primary rounded-[20px] grid gap-3">
								<div className="text-white opacity-[0.6] text-sm font-bold">
									{" "}
									I. G. R. L Technologies 2011
								</div>
								<div className="text-white text-[25px]  font-medium">
									Best Cosmetics Products Award
								</div>
								<div className=" flex justify-center">
									<img src={Trophy} alt="trophy" />
								</div>
								<div className="text-white text-[16px] font-normal">
									Institute of Government Research and Leadership Technologies 2011
								</div>
							</div>

							<div className="p-5 mb-5  w-full bg-eb-primary rounded-[20px] grid gap-3">
								<div className="text-white opacity-[0.6] text-sm font-bold">
									I. G. R. L Technologies 2012
								</div>
								<div className="text-white text-[25px]  font-medium">
									Best Manufacturer of Baby, Body and Hair Care Products Award
								</div>
								<div className=" flex justify-center">
									<img src={Trophy} alt="trophy" />
								</div>
								<div className="text-white text-[16px] font-normal">
									Institute of Government Research and Leadership Technologies 2012
								</div>
							</div>
						</div>
					</div>

					{/* PARTNERS */}
					<div>
						<div className="text-semibold text-[30px] text-eb-primary mt-7">
							Partners
						</div>

						<div className="grid grid-cols-4 mt-5 ">
							<img src={Partner1} alt="partner" />
							<img src={Partner2} alt="partner" />
							<img src={Partner3} alt="partner" />
							<img src={Partner4} alt="partner" />
						</div>
					</div>

					{/* MEET THE CHAIRMAN */}

					<div>
						<div className="text-semibold text-[30px] text-eb-primary mt-7 mb-4">
							Meet the Chairman
						</div>
						{/* <div className="w-[100%] h-[450px] ">
          <div className="w-[100%] h-[450px] ">
            <img src={Person3} alt="person1" className="h-[450px] "  width={"100%"}/>
          </div>
          </div> */}

						<div className="w-full  h-[450px]">
							<iframe
								className="w-full h-[450px] aspect-video "
								src="https://www.youtube.com"
								title="youtube video"
								allowFullScreen
							></iframe>
						</div>

						<div className="grid justify-center mt-[70px]">
							{/* <div className="w-[400px] h-[450px] ">
            <img src={Person4} alt="person1" className="h-[450px] "  width={"100%"}/>
            </div> */}

							<div className="w-[400px]  h-[450px]">
								<iframe
									className="w-full h-[450px] aspect-video "
									src="https://www.youtube.com"
									title="youtube video"
									allowFullScreen
								></iframe>
							</div>

							<p className="text-eb-primary mt-2 font-bold flex justify-center">
								{" "}
								See our Amazing Company and Team
							</p>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default AboutUs;
