import React, { useState } from "react";
// import PhoneControl from "./PhoneControl";
// import CountryControl from "./CountryControl";
import SubmitButton from "components/forms/SubmitButton";
import PhoneControl from "components/auth/CreateAccount/PhoneControl";
import CountryControl from "components/auth/CreateAccount/CountryControl";
import { Formik, Form, Field } from "formik";
import { contactUs } from "services/authService";
import { toast } from "react-toastify";
import {
  initialValues,
  ContactUsSchema,
} from "modules/schemas/contact-us-schema";
import { FormErrorMessage } from "components/forms/FormErrorMessage";
import ReCAPTCHA from "react-google-recaptcha";
import MediaQuery from "components/media-query/MediaQuery";

const ContactForm = () => {
  const [captcha, setCaptcha] = useState("");

  const handleContactSubmit = async (values, FormikHelper) => {
    try {
      const { message } = await contactUs({
        ...values,
        captcha,
      });

      toast(`${message}`, {
        type: "success",
        autoClose: 3000,
      });
      FormikHelper.resetForm(initialValues);
    } catch (e) {
      toast(`${e?.message}`, {
        type: "error",
        autoClose: 3000,
      });
      console.log(e.response);
    }
  };

  return (
			<div className="w-full  p-5 ">
				<div className="text-eb-primary text-[33px] text-center font-bold mb-[10px]">
					Contact Us Today
				</div>

				{/* medium screen start */}
				<MediaQuery min="sm" max="lg">
					<div className="w-full flex justify-center">
						<Formik
							initialValues={initialValues}
							validationSchema={ContactUsSchema}
							onSubmit={handleContactSubmit}
						>
							<Form>
								<div className=" bg-eb-secondary mt-[14px] px-8  pt-[20px] font-normal text-sm text-eb-primary rounded-lg mb-7 pb-[48px]">
									<div className="grid grid-cols gap-4 mb-[24px] ">
										<div>
											<div className="text-xs">First Name</div>

											<div>
												<Field
													name="firstname"
													type="text"
													className="h-[38px] w-[100%] rounded-md border border-eb-primary mt-3 pl-3"
													placeholder="Ebuka"
												/>{" "}
												<div>
													<FormErrorMessage name={"firstname"} />
												</div>
											</div>
										</div>
										<div>
											<div className="text-xs">Last Name</div>

											<div>
												<Field
													name="lastname"
													type="text"
													className="h-[38px] w-[100%] rounded-md border border-eb-primary mt-3 pl-3"
													placeholder="Eze"
												/>{" "}
												<div>
													<FormErrorMessage name={"lastname"} />
												</div>
											</div>
										</div>
									</div>

									<div className="mb-[24px]">
										<div className="text-xs">Email Address</div>
										<div>
											{" "}
											<Field
												name="email"
												type="email"
												className="h-[38px] w-[100%] rounded-md border border-eb-primary mt-3 pl-3"
												placeholder="Your Email"
											/>{" "}
											<div>
												<FormErrorMessage name={"email"} />
											</div>
										</div>
									</div>

									<div className="mb-3">
										<div className="text-xs">Country or Region</div>
										{/* <input className='h-[38px] w-full rounded-md border border-eb-primary mt-3 pl-3'  />
										 */}
										<CountryControl name={"country"} />
									</div>

									<div className="mb-3">
										<div className="text-xs">Phone number</div>
										{/* <input className='h-[38px] w-full rounded-md border border-eb-primary mt-3 pl-3'  />
										 */}
										<PhoneControl name={"phone"} />
									</div>

									<div className="mb-3">
										<div className="text-xs">Message</div>
										<Field
											name="message"
											as="textarea"
											rows={4}
											placeholder="Your Message"
											className="w-full py-2 rounded-md border border-eb-primary mt-3 px-2"
										/>
										<FormErrorMessage name={"message"} />
									</div>

									<ReCAPTCHA
										className="g-recaptcha w-full md:w-1/2 lg:w-1/3 xl:w-1/4"
										sitekey="6LebOl4pAAAAABOMfKwegTRT2jLIO0jz1hdLipxe"
										onChange={(val) => setCaptcha(val)}
									/>

									<SubmitButton className="h-[48px] my-5 w-full bg-eb-primary hover:black rounded-lg text-white font-bold grid justify-center content-center ">
										Submit
									</SubmitButton>
								</div>
							</Form>
						</Formik>
					</div>
				</MediaQuery>
				{/* medium screen end */}

				{/* large screen start */}
				<MediaQuery min="lg">
					<div className="w-full flex justify-center">
						<Formik
							initialValues={initialValues}
							validationSchema={ContactUsSchema}
							onSubmit={handleContactSubmit}
						>
							<Form>
								<div className=" bg-eb-secondary mt-[14px] px-8 md:px-[62px] pt-[20px] font-normal text-sm text-eb-primary rounded-lg mb-7 pb-[48px]">
									<div className="grid grid-cols-2 gap-4 mb-[24px] ">
										<div>
											<div className="text-xs">First Name</div>

											<div>
												<Field
													name="firstname"
													type="text"
													className="h-[38px] w-[100%] rounded-md border border-eb-primary mt-3 pl-3"
													placeholder="Ebuka"
												/>{" "}
												<div>
													<FormErrorMessage name={"firstname"} />
												</div>
											</div>
										</div>
										<div>
											<div className="text-xs">Last Name</div>

											<div>
												<Field
													name="lastname"
													type="text"
													className="h-[38px] w-[100%] rounded-md border border-eb-primary mt-3 pl-3"
													placeholder="Eze"
												/>{" "}
												<div>
													<FormErrorMessage name={"lastname"} />
												</div>
											</div>
										</div>
									</div>

									<div className="mb-[24px]">
										<div className="text-xs">Email Address</div>
										<div>
											{" "}
											<Field
												name="email"
												type="email"
												className="h-[38px] w-[100%] rounded-md border border-eb-primary mt-3 pl-3"
												placeholder="Your Email"
											/>{" "}
											<div>
												<FormErrorMessage name={"email"} />
											</div>
										</div>
									</div>

									<div className="mb-3">
										<div className="text-xs">Country or Region</div>
										{/* <input className='h-[38px] w-full rounded-md border border-eb-primary mt-3 pl-3'  />
										 */}
										<CountryControl name={"country"} />
									</div>

									<div className="mb-3">
										<div className="text-xs">Phone number</div>
										{/* <input className='h-[38px] w-full rounded-md border border-eb-primary mt-3 pl-3'  />
										 */}
										<PhoneControl name={"phone"} />
									</div>

									<div className="mb-3">
										<div className="text-xs">Message</div>
										<Field
											name="message"
											type="message"
											placeholder="Your Message"
											className="py-2 w-full  rounded-md border border-eb-primary mt-3 px-2"
										/>
										<FormErrorMessage name={"message"} />
									</div>

									<ReCAPTCHA
										className="g-recaptcha"
										sitekey="6LebOl4pAAAAABOMfKwegTRT2jLIO0jz1hdLipxe"
										onChange={(val) => setCaptcha(val)}
									/>

									<SubmitButton className="h-[48px] my-5 w-full bg-eb-primary hover:black rounded-lg text-white font-bold grid justify-center content-center ">
										Submit
									</SubmitButton>
								</div>
							</Form>
						</Formik>
					</div>
				</MediaQuery>
				{/* large screen end */}
			</div>
		);
};

export default ContactForm;
