import React from "react";
import Register from "./Register";
import AuthWrapper from "../AuthWrapper";

const CreateAccount = () => {
  return (
    <AuthWrapper >
      <Register />
    </AuthWrapper>
  );
};

export default CreateAccount;
