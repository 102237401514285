import React, { useState, useEffect } from "react";

import Footer from "components/footer/Footer";
import Header from "components/home/Header";
import IconButton from "@mui/material/IconButton";
import Rating from "@mui/material/Rating";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ProductImg from "../../components/assets/productbanner.png";
import HotImg from "../../components/assets/hotproduct.png";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Loader from "components/loader/Loader";
import { CurrencyFormatter } from "components/currency-formatter/CurrencyFormatter";
import { Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { BsChevronDown } from "react-icons/bs";
import { addToWishList } from "redux/slices/wishListSlice";
import { getAllProducts } from "services/productCRUD";
import { getAllBrands } from "services/brandCRUD";
import { addToCarts } from "redux/slices/cartSlice";
import { brandPlaceholder, productPlacholder } from "components/home/data";

const Products = () => {
	const dispatch = useDispatch();
	const [products, setProducts] = useState([]);
	const [brands, setBrands] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [selectedFilter, setSelectedFilter] = useState("All");
	const [filteredProducts, setFilteredProducts] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const productsPerPage = 8;

	// fetch all products
	const fetchAllProducts = async () => {
		try {
			const response = await getAllProducts();
			const { data } = response;
			const productData = data.length > 0 ? data : productPlacholder;
			setProducts(productData);
		} catch (error) {
			console.error("Error fetching products:", error);
			setError(error.message);
		}
	};

	const fetchAllBrands = async () => {
		try {
			const response = await getAllBrands();
			const { data } = response;
			const brandData = data.length > 0 ? data : brandPlaceholder;
			setBrands(brandData);
		} catch (error) {
			console.error("Error fetching brands:", error);
			setError(error.message);
		}
	};

	useEffect(() => {
		setLoading(true);
		//by promise fetch all products and brands
		Promise.all([fetchAllProducts(), fetchAllBrands()])
			.then(() => {
				setLoading(false);
			})
			.catch((error) => {
				console.error("Error fetching products and brands:", error);
				setError(error.message);
			});
	}, []);

	useEffect(() => {
		if (selectedFilter === "All") {
			setFilteredProducts(products);
		} else {
			const filtered = products.filter(
				(product) => product.brand.name === selectedFilter,
			);
			setFilteredProducts(filtered);
		}
	}, [products, selectedFilter]);

	const addToWishHandler = (product) => {
		dispatch(addToWishList(product));
	};

	const indexOfLastProduct = currentPage * productsPerPage;
	const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
	const currentProducts = filteredProducts.slice(
		indexOfFirstProduct,
		indexOfLastProduct,
	);

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	if (loading) {
		return <Loader />;
	}
	if (error) {
		return <div>Error: {error}</div>;
	}
	let popoverInstance;

	const handleFilter = (brandName) => {
		if (brandName === "All") {
			setFilteredProducts(products);
		} else {
			const filtered = products.filter(
				(product) => product.brand.name === brandName,
			);
			setFilteredProducts(filtered);
		}
		popoverInstance?.close();
	};

	const addToCart = (itemId) => {
		if (!itemId) {
			console.error("Invalid item data:", itemId);
			return;
		}

		let item = {
			id: itemId._id,
			quantity: 1,
			price: itemId?.variants[0].item[0].price,
			size: itemId?.variants[0].item[0].size,
			name: itemId.name,
			image: itemId.images?.map((img) => img.url),
		};
		dispatch(addToCarts(item));
	};

	// Set initial filter to "All"

	// const defaultProductPrice = (variants) => {
	//   let price;
	//   variants.map((variant, index) => {
	//     if (index === 0) {
	//       console.log('variant price ' + variant.price);
	//       price = variant.price;
	//     }
	//     return;
	//   });
	//   return price

	// }

	return (
		<div className="min-h-screen flex flex-col">
			<div className="flex-grow">
				<Header />
				<div className="px-[40px] md:px-[80px] py-[40px]">
					<div className="w-full flex">
						<div className="w-[100%] lg:w-[50%] bg-[#D8E1E6]">
							<div className="p-[50px]">
								<h3 className="text-[#3F3F95] my-4 font-bold text-xl">OUR PRODUCTS</h3>
								<p className="text-lg text-[#3F3F95] mb-[40px]">
									Let us help you get started on all our products. You can get our
									Wholesale and Retail products in just One Click.
								</p>
								<button className="bg-[#3F3F95] hover:bg-black p-2 px-4 rounded-lg  text-white">
									See Products
								</button>
							</div>
						</div>
						<div className="hidden lg:block w-[50%]">
							<img
								src={ProductImg}
								className="w-full h-full object-fit"
								alt="bannerImg"
							/>
						</div>
					</div>

					<div className="flex items-center justify-between mt-[40px]">
						<div className="w-full max-w-sm px-4">
							<Popover className="relative">
								{({ open, close }) => {
									popoverInstance = { close };
									return (
										<>
											<Popover.Button
												className={`
                ${open ? "" : "text-opacity-90"}
                group inline-flex items-center rounded-md  px-3 py-2 text-base font-medium text-black hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
											>
												<span className="font-semibold">Filter</span>
												<BsChevronDown
													className={`${open ? "" : "text-opacity-70"}
                  ml-2 h-5 w-5 text-gray-500 transition duration-150 ease-in-out group-hover:text-opacity-80`}
													aria-hidden="true"
												/>
											</Popover.Button>
											<Transition
												as={Fragment}
												enter="transition ease-out duration-200"
												enterFrom="opacity-0 translate-y-1"
												enterTo="opacity-100 translate-y-0"
												leave="transition ease-in duration-150"
												leaveFrom="opacity-100 translate-y-0"
												leaveTo="opacity-0 translate-y-1"
											>
												<Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0">
													<div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
														<div className="relative gap-8 bg-white p-7 ">
															<div className="-m-3 flex items-center rounded-lg p-2 cursor-pointer transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
																<div className="ml-4">
																	<button
																		className="text-lg font-medium text-gray-900 mb-2"
																		onClick={() => handleFilter("All")}
																	>
																		All
																	</button>
																</div>
															</div>

															{brands?.map((item, index) => (
																<div
																	key={item._id}
																	className="-m-3 flex items-center rounded-lg p-2 cursor-pointer transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
																>
																	<div className="ml-4">
																		<button
																			onClick={() => handleFilter(item.name)}
																			className="text-lg font-medium text-gray-900 mb-2"
																		>
																			{item.name}
																		</button>
																	</div>
																</div>
															))}
														</div>
													</div>
												</Popover.Panel>
											</Transition>
										</>
									);
								}}
							</Popover>
						</div>

						<div className="flex gap-3 items-center"></div>
					</div>
					<div className="mt-[30px]">
						<div className="flex justify-center flex-wrap gap-7">
							{currentProducts?.map((item, index) => (
								<div key={item._id} className="w-[300px] bg-white shadow-xl rounded-md">
									<div className="p-4">
										<Link to={`/product/${item._id}`} className="text-decoration-none">
											<div className="relative">
												{item.images?.map((img, idx) => (
													<img
														key={idx}
														src={img.url}
														alt="hotimage"
														className="w-full h-[300px] object-fit"
													/>
												))}
												<div className="bg-black/[.2] p-2 rounded-md absolute bottom-1 right-1 text-white">
													{item.inStock === true ? "In Stock" : "Out of Stock"}
												</div>
											</div>
										</Link>

										<div className="flex justify-between py-4">
											<p>{item.name}</p>

											<p className="text-black font-semibold">
												₦{" "}
												{item.variants
													.find((variant) => variant.quantityType === "single")
													?.item?.[0]?.price.toFixed(2)
													.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
											</p>
										</div>
										<p>
											Brand: <span className="font-semibold">{item.brand.name}</span>
										</p>
										<div>
											<Rating name="read-only" value={item.ratings} readOnly />
										</div>
										<div className="flex justify-between gap-4 items-center">
											<div>
												<button
													onClick={() => addToCart(item)}
													disabled={!item.inStock}
													className="mt-2 p-2 px-4 rounded-md border border-[#3F3F95] text-[#3F3F95] hover:bg-[#3F3F95] hover:text-white"
												>
													Add to Cart
												</button>
											</div>
											<div>
												<IconButton
													onClick={() => addToWishHandler(item)}
													aria-label="add to favorites"
												>
													<FavoriteIcon />
												</IconButton>
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
					{/* pagination */}
					<div className="flex flex-wrap justify-center mt-4">
						{Array.from(
							{ length: Math.ceil(filteredProducts.length / productsPerPage) },
							(_, index) => (
								<button
									key={index}
									onClick={() => paginate(index + 1)}
									className={`mx-2 px-4 py-2 border ${
										currentPage === index + 1 ? "bg-gray-300" : "bg-white"
									}`}
								>
									{index + 1}
								</button>
							),
						)}
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export const defaultProductPrice = (variants) => {
	if (!variants || !Array.isArray(variants) || variants.length === 0) {
		return null; // return null if variants is null, undefined, not an array, or empty
	}

	const firstVariant = variants[0]; // get the first variant directly

	return firstVariant.price;
};

export default Products;
