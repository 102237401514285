import React, { useState, useRef } from "react";
import Facebook from "../../assets/facebookLogo.svg";
import Apple from "../../assets/appleLogo.svg";
import Logo from "../../assets/fullLogo.svg";
import { Link, useLocation } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { initialLoginValues, LoginSchema } from "../../../modules/schemas";
import { login } from "../../../services/authService";
import { toast } from "react-toastify";
import { FormErrorMessage } from "components/forms/FormErrorMessage";
import SubmitButton from "components/forms/SubmitButton";
import { useDispatch } from "react-redux";
import { setUser, setUserTokens } from "redux/slices";
import { useNavigate } from "react-router-dom";
import { setRememberMe } from "helpers/auth.helpers";
import ButtonGroup from "../CreateAccount/ExpandableButton";
import MediaQuery from "components/media-query/MediaQuery";
import { updateLocalAccessToken, updateLocalRefreshToken } from "services/tokenService";

const LoginForm = () => {
	const [passwordType, setPasswordType] = useState(true);
	const formRef = useRef(null);
	const togglePassword = (e) => {
		e.preventDefault();
		setPasswordType(!passwordType);
	};
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const handleLoginSubmit = async (values) => {
		try {
			const res = await login(values);
			const { data, access_token, refresh_token, message } = res;
			updateLocalAccessToken(access_token);
			updateLocalRefreshToken(refresh_token);
			dispatch(setUser(data));
			dispatch(
				setUserTokens({
					accessToken: access_token,
					refreshToken: refresh_token,
				}),
			);
			toast(message, {
				type: "success",
				autoClose: 3000,
			});
			navigate("/");
		} catch (e) {
			console.log("ERROR", e);
			toast(e?.message, {
				type: "error",
				autoClose: 3000,
			});
		}
	};

const handleKeyDown = (e) => {
	if (e.key === "Enter") {
		e.preventDefault();
		handleLoginSubmit(e);
	}
};

	return (
		<>
			{/* small screen start */}
			<MediaQuery min="sm" max="md">
				<div className=" w-full ">
					<div className="w-[150px] h-[88px]">
						<img src={Logo} alt="logo" className="w-full h-full object-fit" />
					</div>
					<div className="text-[15px] font-light text-bl grid justify-end  text-[#07070B] mb-[5px]">
						<Link to="/signup" className="text-sm">
							{" "}
							Don’t have an account yet?{" "}
							<span className="text-eb-primary"> Sign Up now</span>
						</Link>
					</div>

					<div className="text-eb-primary text-[20px] font-bold mb-[16px]">
						Sign In to Emos Best
					</div>

					<div>
						<ButtonGroup />
					</div>

					<Formik
						initialValues={initialLoginValues}
						validationSchema={LoginSchema}
						onSubmit={handleLoginSubmit}
						innerRef={formRef}
					>
						<Form>
							<div className="bg-eb-secondary mt-[24px] pl-[32px] pr-[32px] pt-[20px] font-normal text-sm text-eb-primary rounded-lg mb-7 pb-[48px] max-h-[60vh]">
								<div className="mb-[24px]">
									<div className="text-sm">Email Address</div>
									<div>
										{" "}
										<Field
											name="email"
											type="email"
											className="h-[38px] w-[100%]  rounded-md border border-eb-primary mt-3 pl-3"
											placeholder="Your Email"
										/>{" "}
										<div>
											<FormErrorMessage name={"email"} />
										</div>
									</div>
								</div>

								<div className="mb-[24px]">
									<div className="text-sm">Password</div>
									<div>
										<div className="relative">
											<Field
												name="password"
												placeholder="Enter Password"
												type={passwordType ? "password" : "text"}
												onKeyPress={handleKeyDown}
												className=" h-[38px] w-full rounded-md border border-eb-primary mt-3 pl-3"
											/>
											<div className="absolute flex items-center inset-y-0 right-2 pr-3 pointer top-3 w-[20px] ">
												<button onClick={togglePassword}>
													{passwordType ? (
														<svg
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 24 24"
															width="18"
															height="18"
														>
															<path fill="none" d="M0 0h24v24H0z" />
															<path
																d="M12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9C2.121 6.88 6.608 3 12 3zm0 16a9.005 9.005 0 0 0 8.777-7 9.005 9.005 0 0 0-17.554 0A9.005 9.005 0 0 0 12 19zm0-2.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm0-2a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
																fill="rgba(63,63,149,1)"
															/>
														</svg>
													) : (
														<svg
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 24 24"
															width="18"
															height="18"
														>
															<path fill="none" d="M0 0h24v24H0z" />
															<path
																d="M17.882 19.297A10.949 10.949 0 0 1 12 21c-5.392 0-9.878-3.88-10.819-9a10.982 10.982 0 0 1 3.34-6.066L1.392 2.808l1.415-1.415 19.799 19.8-1.415 1.414-3.31-3.31zM5.935 7.35A8.965 8.965 0 0 0 3.223 12a9.005 9.005 0 0 0 13.201 5.838l-2.028-2.028A4.5 4.5 0 0 1 8.19 9.604L5.935 7.35zm6.979 6.978l-3.242-3.242a2.5 2.5 0 0 0 3.241 3.241zm7.893 2.264l-1.431-1.43A8.935 8.935 0 0 0 20.777 12 9.005 9.005 0 0 0 9.552 5.338L7.974 3.76C9.221 3.27 10.58 3 12 3c5.392 0 9.878 3.88 10.819 9a10.947 10.947 0 0 1-2.012 4.592zm-9.084-9.084a4.5 4.5 0 0 1 4.769 4.769l-4.77-4.769z"
																fill="rgba(63,63,149,1)"
															/>
														</svg>
													)}
												</button>
											</div>
										</div>
										<div className="relative top-8">
											<FormErrorMessage name={"password"} />
										</div>
									</div>
									<div className="mt-2 grid justify-end relative top-6">
										<Link to="/forgotpass" className="text-xs">
											{" "}
											Forgot Password?
										</Link>
									</div>
								</div>

								<div className="flex gap-4 mb-4">
									<input
										onChange={(e) => {
											setRememberMe(e.target.checked);
										}}
										type="checkbox"
										className="rounded-lg"
									/>
									<p className="text-xs">Keep me signed in</p>
								</div>

								<SubmitButton
									className="h-[48px] w-full bg-eb-primary hover:bg-black rounded-lg text-white font-bold grid justify-center content-center "
									onKeyDown={handleKeyDown}
								>
									Sign In
								</SubmitButton>
							</div>
						</Form>
					</Formik>
				</div>
			</MediaQuery>
			{/* small screen end */}

			{/* large screen start */}
			<MediaQuery min="md">
				<div className=" w-full ">
					<div className="text-[15px] font-light text-bl grid justify-end  text-[#07070B] mb-[5px]">
						<Link to="/signup" className="text-sm">
							{" "}
							Don’t have an account yet?{" "}
							<span className="text-eb-primary"> Sign Up now</span>
						</Link>
					</div>

					<div className="text-eb-primary text-[20px] font-bold mb-[16px]">
						Sign In to Emos Best
					</div>

					<div>
						<ButtonGroup />
					</div>

					<Formik
						initialValues={initialLoginValues}
						validationSchema={LoginSchema}
						onSubmit={handleLoginSubmit}
					>
						<Form>
							<div className="bg-eb-secondary mt-[24px] pl-[32px] pr-[32px] pt-[20px] font-normal text-sm text-eb-primary rounded-lg mb-7 pb-[48px] max-h-[60vh]">
								<div className="mb-[24px]">
									<div className="text-sm">Email Address</div>
									<div>
										{" "}
										<Field
											name="email"
											type="email"
											className="h-[38px] w-[100%]  rounded-md border border-eb-primary mt-3 pl-3"
											placeholder="Your Email"
										/>{" "}
										<div>
											<FormErrorMessage name={"email"} />
										</div>
									</div>
								</div>

								<div className="mb-[24px]">
									<div className="text-sm">Password</div>
									<div>
										<div className="relative">
											<Field
												name="password"
												placeholder="Enter Password"
												type={passwordType ? "password" : "text"}
												onKeyPress={handleKeyDown}
												className=" h-[38px] w-full rounded-md border border-eb-primary mt-3 pl-3"
											/>
											<div className="absolute flex items-center inset-y-0 right-2 pr-3 pointer top-3 w-[20px] ">
												<button onClick={togglePassword}>
													{passwordType ? (
														<svg
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 24 24"
															width="18"
															height="18"
														>
															<path fill="none" d="M0 0h24v24H0z" />
															<path
																d="M12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9C2.121 6.88 6.608 3 12 3zm0 16a9.005 9.005 0 0 0 8.777-7 9.005 9.005 0 0 0-17.554 0A9.005 9.005 0 0 0 12 19zm0-2.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm0-2a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
																fill="rgba(63,63,149,1)"
															/>
														</svg>
													) : (
														<svg
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 24 24"
															width="18"
															height="18"
														>
															<path fill="none" d="M0 0h24v24H0z" />
															<path
																d="M17.882 19.297A10.949 10.949 0 0 1 12 21c-5.392 0-9.878-3.88-10.819-9a10.982 10.982 0 0 1 3.34-6.066L1.392 2.808l1.415-1.415 19.799 19.8-1.415 1.414-3.31-3.31zM5.935 7.35A8.965 8.965 0 0 0 3.223 12a9.005 9.005 0 0 0 13.201 5.838l-2.028-2.028A4.5 4.5 0 0 1 8.19 9.604L5.935 7.35zm6.979 6.978l-3.242-3.242a2.5 2.5 0 0 0 3.241 3.241zm7.893 2.264l-1.431-1.43A8.935 8.935 0 0 0 20.777 12 9.005 9.005 0 0 0 9.552 5.338L7.974 3.76C9.221 3.27 10.58 3 12 3c5.392 0 9.878 3.88 10.819 9a10.947 10.947 0 0 1-2.012 4.592zm-9.084-9.084a4.5 4.5 0 0 1 4.769 4.769l-4.77-4.769z"
																fill="rgba(63,63,149,1)"
															/>
														</svg>
													)}
												</button>
											</div>
										</div>
										<div className="relative top-8">
											<FormErrorMessage name={"password"} />
										</div>
									</div>
									<div className="mt-2 grid justify-end relative top-6">
										<Link to="/forgotpass" className="text-xs">
											{" "}
											Forgot Password?
										</Link>
									</div>
								</div>

								<div className="flex gap-4 mb-4">
									{/* <input
										onChange={(e) => {
											setRememberMe(e.target.checked);
										}}
										type="checkbox"
										className="rounded-lg"
									/>
									<p className="text-xs">Keep me signed in</p> */}
								</div>

								<SubmitButton
									className="h-[48px] w-full bg-eb-primary hover:bg-black rounded-lg text-white font-bold grid justify-center content-center"
									onKeyDown={handleKeyDown}
								>
									Sign In
								</SubmitButton>
							</div>
						</Form>
					</Formik>
				</div>
			</MediaQuery>
			{/* large screen end */}
		</>
	);
};

export default LoginForm;
