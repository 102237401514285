import React, { useState, useCallback, useEffect } from "react";
import HairModel from "../assets/hairmodel.jpg";
import SkinModel from "../assets/skinmodel.jpeg";
import DentalModel from "../assets/dentalmodel.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import { getAllBlogs } from "services/blogService";

const data = [
	{
		avatar: HairModel,
		title: "Hair Care",
		content:
			"This has been used by all the people in my family for many years. I much prefer the lotion to any other lotion that have come across. A little goes a long way, smells gorgeous and really does the job on very dry skin.",
	},
	{
		avatar: SkinModel,
		title: "Skin Care",
		content:
			"This has been used by all the people in my family for many years. I much prefer the lotion to any other lotion that have come across. A little goes a long way, smells gorgeous and really does the job on very dry skin.",
	},
	{
		avatar: DentalModel,
		title: "Dental Care",
		content:
			"This has been used by all the people in my family for many years. I much prefer the lotion to any other lotion that have come across. A little goes a long way, smells gorgeous and really does the job on very dry skin.",
	},
];

const Blog = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [blogs, setBlogs] = useState([]);

	const fetchData = useCallback(async () => {
		try {
			const response = await getAllBlogs();
			setBlogs(response.data);
		} catch (err) {
			console.error("Error fetching blogs", err);
		}
	}, []);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const truncateWords = (content, limit) => {
		if (!content) return "";
		const words = content.split(" ");
		return words.length > limit
			? words.slice(0, limit).join(" ") + "..."
			: content;
	};

	const BlogPost = ({ avatar, title, content, _id, image }) => (
		<div className="mx-5 md:mx-0 md:w-96 bg-white shadow-xl rounded-md">
			<div className="p-4 mb-3">
				<img
					src={avatar || image?.url}
					alt={title}
					className="w-full h-72 object-cover"
				/>
				<p className="text-center text-lg font-bold py-4">{title}</p>
				<p className="text-lg">{truncateWords(content, 20)}</p>
				<div className="text-center py-4">
					<button
						className="text-white rounded-md px-6 py-2 bg-[#3F3F95] hover:bg-black"
						onClick={_id ? () => navigate(`/blog/${_id}`) : undefined}
					>
						Read Now
					</button>
				</div>
			</div>
		</div>
	);

	return (
		<>
			{location.pathname === "/home" ? (
				<h3 className="font-bold text-xl py-5">Blog</h3>
			) : (
				<div className="grid justify-center">
					<h3 className="font-bold text-xl py-5 text-eb-primary">
						You can also read
					</h3>
				</div>
			)}
			<div className="md:flex justify-center gap-5">
				{blogs.length > 0
					? blogs.map((blog, index) => <BlogPost key={index} {...blog} />)
					: data.map((placeholder, index) => (
							<BlogPost key={index} {...placeholder} />
					  ))}
			</div>
		</>
	);
};

export default Blog;
