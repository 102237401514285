import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import countryList from "react-select-country-list";
import { useFormikContext } from "formik";

const CountryControl = ({ name }) => {
	const [value, setValue] = useState();

	const { setFieldValue, touched, setFieldTouched, values } = useFormikContext();

	const options = useMemo(() => countryList().getData(), []);

	const changeHandler = (value) => {
		setValue(value);
		if (!touched[name]) setFieldTouched(name, true);
		setFieldValue(name, value?.value, false);
	};
	///TYPE COERSION
	useEffect(() => {
		if (!!!values[name]) {
			setValue(null);
		}
	}, [values, name]);

	return (
		<div>
			<Select
				options={options}
				value={value}
				onChange={changeHandler}
				className="mt-3"
				styles={{
					control: (baseStyles, state) => ({
						...baseStyles,
						borderColor: state.isFocused ? "grey" : "#3F3F95",
						borderRadius: "8px",
						height: "38px",
					}),
					dropdownIndicator: (base) => ({
						...base,
						color: "#3F3F95",
					}),
					indicatorSeparator: (base) => ({
						...base,
						display: "none",
					}),
				}}
			/>
		</div>
	);
};

export default CountryControl;
