import React from "react";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { useFormikContext } from "formik";
import { FormErrorMessage } from "components/forms/FormErrorMessage";
import parsePhoneNumber from "libphonenumber-js/max";

const PhoneControl = ({ name }) => {
  const { values, setFieldValue, setFieldError, touched, setFieldTouched } =
    useFormikContext();

  const ValidatePhone = (value) => {
    console.log(value);
    if (!value) return false;
    try {
      const phoneNumber = parsePhoneNumber(`+${value}`);

      if (phoneNumber.isValid()) {
        setFieldError(name, undefined);
      } else {
        setFieldError(name, "Phone number is invalid");
      }
    } catch (err) {
      setFieldError(name, "Phone number is invalid");
    }
  };

  const handleOnChange = (value) => {
    if (!touched[name]) setFieldTouched(name, true);
    setFieldValue(name, value, false);
    ValidatePhone(value);
  };

  return (
    <>
      <PhoneInput
        placeholder="Enter phone number"
        value={values[name]}
        onChange={handleOnChange}
        containerStyle={{
          height: "38px",
          border: "1px solid #3F3F95",
          borderRadius: "6px",
          backgroundColor: "white",
          padding: "2px",
          marginTop: "12px",
        }}
        inputStyle={{
          height: "99%",
          border: "none",
          width: "99%",
        }}
        buttonStyle={{
          height: "70%",
          border: "none",
          marginTop: "5px",
          background: "white",
        }}
        dropdownStyle={{
          maxHeight: "100px",
        }}
      />
      <div>
        <FormErrorMessage name={name} />
      </div>
    </>
  );
};

export default PhoneControl;
