import { createSlice } from "@reduxjs/toolkit";

let storedCarts = localStorage.getItem("carts");

if (!storedCarts || storedCarts === "undefined") {
	localStorage.setItem("carts", JSON.stringify([]));
}

const initialState = {
	carts: JSON.parse(storedCarts || "[]"),
	error: null,
	loading: false,
};

const CartSlice = createSlice({
	name: "cart",
	initialState,
	reducers: {
		addToCarts: (state, action) => {
			if (!state.carts) {
				state.carts = [action.payload];
			} else {
				const existItem = state.carts.find((i) => i.id === action.payload.id);

				if (existItem) {
					let update = state.carts.map((i) => {
						if (i.id === action.payload.id) {
							return { ...i, quantity: i.quantity + action.payload.quantity };
						} else {
							return i;
						}
					});

					state.carts = update;
				} else {
					state.carts = [...state.carts, action.payload];
				}
			}

			localStorage.setItem("carts", JSON.stringify(state.carts));
		},
		increaseQuantity: (state, action) => {
			const item = state.carts.find((product) => product.id === action.payload.id);

			if (item.quantity >= 1) {
				state.carts = state.carts.map((product) =>
					product.id === action.payload.id
						? { ...product, quantity: (item.quantity += 1) }
						: product,
				);
			}

			localStorage.setItem("carts", JSON.stringify(state.carts));
		},
		decreaseQuantity: (state, action) => {
			const item = state.carts.find((product) => product.id === action.payload.id);

			if (item.quantity === 1) {
				state.carts = state.carts.filter(
					(product) => product.id !== action.payload.id,
				);
			}

			state.carts = state.carts.map((product) =>
				product.id === action.payload.id
					? { ...product, quantity: (item.quantity -= 1) }
					: product,
			);

			localStorage.setItem("carts", JSON.stringify(state.carts));
		},
		removeFromCart: (state, action) => {
			const itemId = action.payload.id;
			const updatedCarts = state.carts.filter((product) => product.id !== itemId);
			state.carts = updatedCarts;
			localStorage.setItem("carts", JSON.stringify(state.carts));
		},
		clearCart: (state) => {
			state.carts = [];
			localStorage.setItem("carts", JSON.stringify(state.carts));
		},
	},
});

export const updateLocalStorage = (carts) => {
	localStorage.setItem("carts", JSON.stringify(carts));
};

export const cartState = (state) => state.carts;

export const {
	addToCarts, 
	increaseQuantity,
	decreaseQuantity,
	removeFromCart,
	clearCart, 
} = CartSlice.actions;

export default CartSlice.reducer;
