let serverUrl;
let socketUrl;

if (process.env.NODE_ENV === "development") {
	serverUrl = "http://localhost:3300";
	socketUrl = "http://localhost:3300";
}

if (process.env.NODE_ENV === "production") {
	serverUrl = "https://api.emosbestgroup.com";
   socketUrl = "wss://socket-api-rcs8.onrender.com" || "wss://socket-api-rcs8.onrender.com"; 
}

const baseURL = `${serverUrl}/api/v1`;

export { serverUrl, baseURL, socketUrl };
