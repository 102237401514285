import * as Yup from 'yup';

export const initialResetPasswordValues = {
  password: '',
  confirm_password: '',
};

export const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Password must be at least six (6) characters.')
    .required('Password is required.'),
  confirm_password: Yup.string().oneOf([Yup.ref("password"),
  null], "Passwords don't match").required('confirm password is required')
});
