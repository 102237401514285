import React,{ useState, useEffect } from "react";
import Header from "components/home/Header";
import Footer from "components/footer/Footer";
import Bitcoin from "../../assets/bitcoin.png";
import HotImg from "../../assets/hotproduct.png";
import Rating from "@mui/material/Rating";
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Empire from "../../assets/empire.png";
import { PaystackButton } from 'react-paystack';


const Payment = () => {
  const [email, setEmail] = useState('');
  const [amount, setAmount] = useState(0);
  const [reference, setReference] = useState('');

  // Callback function for successful payment
  const handlePaymentSuccess = (response) => {
    // Perform actions on successful payment
    console.log('Payment successful!', response);
  }

  // Callback function for failed payment
  const handlePaymentError = (error) => {
    // Perform actions on failed payment
    console.log('Payment failed!', error);
  }

  // Callback function for close of payment dialog
  const handlePaymentClose = () => {
    // Perform actions on close of payment dialog
    console.log('Payment closed!');
  }

  // Function to handle payment submission
  const handlePaymentSubmit = () => {
    // Call Paystack API to initialize payment
    const paystackOptions = {
      email,
      amount,
      reference,
      callback: handlePaymentSuccess,
      onClose: handlePaymentClose,
      onError: handlePaymentError
    };
    const paystackButton = new window.PaystackPop(paystackOptions);
    paystackButton.openIframe();
  }
  return (
    <div>
      <Header />
      <div className="m-[40px] ml-[80px]">
        <div className="grid grid-cols-2">
          <div className="flex justify-center">
            <img src={Bitcoin} alt="bitcoinimage" />
          </div>

          <div className="">
          <div className='bg-[#F5F5F5] rounded-lg p-9 grid gap-10'>
        <p><input type='radio' className='mr-9' /> Pay with Bank Tranfers</p>
        <p><input type='radio' className='mr-9' /> Pay with Remita</p>
        <p><input type='radio' className='mr-9' /> Pay with Debit Card</p>
        <p><input type='radio' className='mr-9' /> Pay with USSD</p>
      </div>

      <div className='mt-4 flex justify-center gap-12'>
        <button className='bg-eb-primary rounded-md h-[48px] w-[245px] flex items-center justify-center  text-white hover:bg-black'
          onClick={handlePaymentSubmit}>
          <p>Proceed to Payment</p>
        </button>

        <button className='border border-eb-primary rounded-md h-[48px] w-[199px] flex items-center justify-center text-eb-primary hover:bg-black hover:text-white'>
          <p className=''>Purchase More</p>
        </button>
      </div>

            <div>
              <h2 className="text-xl font-semibold py-5">Other Products</h2>
              <div className="flex gap-5">
                <div className="flex gap-5">
                  <div className="w-[300px] bg-white shadow-xl rounded-md">
                    <div className="p-4">
                      <div className="relative">
                        <img
                          src={HotImg}
                          alt="hotimage"
                          className="w-full h-[300px] object-fit"
                        />
                        <div className="bg-black/[.2] p-2 rounded-md absolute bottom-1 right-1 text-white">
                          In Stock
                        </div>
                      </div>
                      <div className="flex justify-between py-4">
                        <p>Lemonfresh Advanced Therapy</p>
                        <p className="text-black font-semibold">N6,000</p>
                      </div>
                      <p>
                        Brand: <span className="font-semibold">Topsy</span>
                      </p>
                      <div>
                        <Rating name="read-only" value={3} readOnly />
                      </div>
                      <div className="flex justify-between gap-4 items-center">
                        <div>
                          <button className="mt-2 p-2 px-4 rounded-md border border-[#3F3F95] text-[#3F3F95] hover:bg-[#3F3F95] hover:text-white">
                            Buy Now
                          </button>
                        </div>
                        <div>
                          <IconButton aria-label="add to favorites">
                            <FavoriteIcon />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex gap-5">
                  <div className="w-[300px] bg-white shadow-xl rounded-md">
                    <div className="p-4">
                      <div className="relative">
                        <img
                          src={Empire}
                          alt="hotimage"
                          className="w-full h-[300px] object-fit"
                        />
                        <div className="bg-black/[.2] p-2 rounded-md absolute bottom-1 right-1 text-white">
                          In Stock
                        </div>
                      </div>
                      <div className="flex justify-between py-4">
                        <p>Lemonfresh Advanced Therapy</p>
                        <p className="text-black font-semibold">N6,000</p>
                      </div>
                      <p>
                        Brand: <span className="font-semibold">Topsy</span>
                      </p>
                      <div>
                        <Rating name="read-only" value={3} readOnly />
                      </div>
                      <div className="flex justify-between gap-4 items-center">
                        <div>
                          <button className="mt-2 p-2 px-4 rounded-md border border-[#3F3F95] text-[#3F3F95] hover:bg-[#3F3F95] hover:text-white">
                            Buy Now
                          </button>
                        </div>
                        <div>
                          <IconButton aria-label="add to favorites">
                            <FavoriteIcon />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Payment;
