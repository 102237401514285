import httpService from "./httpService";

export async function getAllBlogs() {
	try {
		const response = await httpService.get("/blog/all");
		return response;
	} catch (error) {
		throw new Error(error.message);
	}
}

export async function getABlog(id) {
	try {
		const res = await httpService.get("/blog/" + id);
		return res;
	} catch (error) {
		throw new Error(error.message);
	}
}
