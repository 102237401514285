import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/auth/Login";
import CreateAccount from "./components/auth/CreateAccount";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword/ResetPassword";
import HomePage from "./components/home/HomePage";
import ProtectedRoute from "components/ProtectedRoute";
import RegisterConfirm from "components/auth/CreateAccount/RegisterConfirm";
import ForgotTwo from "components/auth/ForgotPassword/ForgotTwo";
import ResetSuccess from "components/auth/ResetPassword/ResetSucces";
import SuccessScreen from "components/auth/CreateAccount/OTP/Success";
import AboutUs from "components/Resources/AboutUs";
import ContactUs from "components/Resources/ContactUs"; 
import Settings from "components/account/Settings/Settings";
import Profile from "components/account/Profile/Profile";
import Products from "pages/products/Products";
import Brands from "pages/brands/Brands";
import Distributors from "pages/distributors/Distributors";
import Product from "pages/single-product/Product";
import Cart from "pages/cart/Cart";
import PageNotFound from "pages/page-not-found/PageNotFound";
import Favorite from "pages/favorites/Favorite";

import BlogPage from "components/blog";
import SingleBlogPost from "components/blog/SingleBlogPost";
import Contact from "components/Resources/ContactUs/contact";
import GeneralAccount from "components/account/Settings/GeneralAccount";
import ChangePassword from "components/account/Settings/Password";
import OrderDetails from "components/account/Profile/OrderDetails";
import MyOrder from "components/account/Profile/MyOrder";
import ImageUploadForm from "components/account/Profile/testUpload";
import Payment from "components/account/payment";
import Order from "pages/order/Order";
import Categories from "pages/product-category/Categories";
import ReturnQuery from "components/account/Profile/RetuenQuery/ReturnQuery";
import ReturnOfGoods from "components/account/Profile/RetuenQuery/ReturnOfGoods";
import OAuthErrorPage from "pages/oauth-error/OauthError";
import Invoice from "components/account/Profile/Invoice";

function App() {
	// useUserProfile();
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<HomePage />} />
				<Route path="/login" element={<Login />} />
				<Route path="/signup" element={<CreateAccount />} />
				<Route path="/forgotpass" element={<ForgotPassword />} />
				<Route path="/forgottwo" element={<ForgotTwo />} />
				<Route path="/resetpassword" element={<ResetPassword />} />
				<Route path="/resetsuccess" element={<ResetSuccess />} />
				<Route path="/success" element={<SuccessScreen />} />
				<Route path="/registerconfirm" element={<RegisterConfirm />} />
				<Route path="/products" element={<Products />} />
				<Route path="/product/:productId" element={<Product />} />
				<Route path="/product/category/:categoryId" element={<Categories />} />
				<Route path="/brands" element={<Brands />} />
				<Route path="/distributors" element={<Distributors />} />
				<Route path="/cart" element={<Cart />} />
				<Route path="/aboutus" element={<AboutUs />} />
				<Route path="/contactus" element={<ContactUs />} />
				<Route path="/blog" element={<BlogPage />} />
				<Route path="/blog/:id" element={<SingleBlogPost />} />
				<Route path="/favorites" element={<Favorite />} />

				<Route element={<ProtectedRoute />}>
					<Route path="/settings" element={<Settings />} />
					<Route path="/profile" element={<Profile />} />
					<Route path="/contact" element={<Contact />} />
					<Route path="/general-account" element={<GeneralAccount />} />
					<Route path="/password-reset" element={<ChangePassword />} />
					<Route path="/my-order" element={<MyOrder />} />
					<Route path="/my-order/details/:id" element={<OrderDetails />} />
					<Route path="/my-order/invoice/:id" element={<Invoice />} />
					<Route path="/image-upload" element={<ImageUploadForm />} />
					<Route path="/payment" element={<Payment />} />
					<Route path="/order" element={<Order />} />
					<Route path="*" element={<PageNotFound />} />
					<Route path="/return-query" element={<ReturnQuery />} />
					<Route path="/return-query/rog-form" element={<ReturnOfGoods />} />
				</Route>
				<Route path="/oauth/error" element={<OAuthErrorPage />} />
				<Route path="*" element={<PageNotFound />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
