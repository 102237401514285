import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { axiosErrorHandler } from "../../utils/axiosErrorHandler";
import { baseURL } from "constants/url";

export const fetchAllCategories = createAsyncThunk(
    "categories/fetchAllCategories",
    async () => {
        try {
            const response = await axios.get(`${baseURL}/category/all`);
            const { data } = response.data;
		    return data;
        } catch (error) {
            return axiosErrorHandler(error);
        }
    }
);

const categorySlice = createSlice({
    name: "categories",
    initialState: {
        isLoading: false,
        categories: [],
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllCategories.pending, (state, action) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(fetchAllCategories.fulfilled, (state, action) => {
            state.isLoading = false;
            state.categories = action.payload;
            state.error = null;
        });
        builder.addCase(fetchAllCategories.rejected, (state, action) => {
            state.isLoading = false;
            state.categories = [];
            state.error = action.error.message;
        });
    }

});

export default categorySlice.reducer;