import React from 'react';
import AuthWrapper from '../AuthWrapper';

import ForgotForm from './ForgotForm';

const ForgotPassword = () => {
  return (
   <AuthWrapper>
       <ForgotForm />
   </AuthWrapper>


  
  )
}

export default ForgotPassword;