import React, { useState } from "react";

import OtpInput from "react18-input-otp";
import { toast } from "react-toastify";

import { verifyEmailOtp } from "services/authService";
import { useNavigate } from "react-router-dom";

const OtpScreen = ({ email }) => {
  const navigate = useNavigate();

  const [otp, setOtp] = useState("");
  const handleChange = (enteredOtp) => {
    setOtp(enteredOtp);
  };

  const handleOtpSubmit = async (values) => {
    try {
      const { message } = await verifyEmailOtp(values);
      toast(`${message}`, {
        type: "success",
        autoClose: 3000,
      });

      navigate("/success");
    } catch (e) {
      toast(`${e?.message}`, {
        type: "error",
        autoClose: 3000,
      });
    }
  };

  return (
    <div>
      <div className="text-center">
        <div className="text[#111827] text-md font-semibold">
          {" "}
          Enter verification code
        </div>
        <div className="mt-4 text-[#718096] text-sm mb-4">
          {" "}
          We have just sent a verification code to {email}
        </div>
      </div>

      <div className="flex justify-center">
        <OtpInput
          inputStyle={{
            marginLeft: "5px",
            marginRight: "5px",
            width: "36px",
            height: "36px",
            borderRadius: "5px",
            backgroundColor: "#FAFAFA",
          }}
          isInputNum={true}
          focusStyle="border border-eb-primary"
          value={otp}
          onChange={handleChange}
          numInputs={6}
        />
      </div>
      {/* inputStyle='mx-[10px] w-[20px] bg-red-500 rounded-md border border-eb-primary'  */}
      <div className="mt-8 text-eb-primary text-xs font-semibold">
        Send the code again
      </div>

      <button
        onClick={() => {
          handleOtpSubmit(otp);
        }}
        className="bg-eb-primary hover:bg-black w-full h-[40px]  rounded-md grid justify-center content-center text-sm mt-3 text-white"
      >
        {" "}
        Verify
      </button>
    </div>
  );
};

export default OtpScreen;
