import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import TestiImg from "../assets/testim1.jpg";
import TestiImg2 from "../assets/testim2.jpg";
import TestiImg3 from "../assets/testim3.jpg";
import TestiImg4 from "../assets/testim4.jpg";
import TestiImg5 from "../assets/testim5.jpg";

import "swiper/css";
import "swiper/css/navigation";

const data = [
	{
		avatar: TestiImg,
		name: "Taiye Oyebanre",
		review:
			"This has been used by all the people in my family for many years. I much prefer the lotion to any other lotion that have come across. A little goes a long way, smells gorgeous and really does the job on very dry skin.",
	},
	{
		avatar: TestiImg2,
		name: "Cynthia Morgan",
		review:
			"Just started using this relaxer like two months back, following the directions and do just what I’m supposed to, it relaxes so well, no negative result to my hair and my hair looks good, and it stays healthy and doesn’t break off. I won’t use anything...",
	},
	{
		avatar: TestiImg3,
		name: "Jude Edafe",
		review:
			"Just started using this relaxer like two months back, following the directions and do just what I’m supposed to, it relaxes so well, no negative result to my hair and my hair looks good, and it stays healthy and doesn’t break off. I won’t use anything...",
	},
	{
		avatar: TestiImg4,
		name: "Larry Gaga",
		review:
			"This has been used by all the people in my family for many years. I much prefer the lotion to any other lotion that have come across. A little goes a long way, smells gorgeous and really does the job on very dry skin.",
	},
	{
		avatar: TestiImg5,
		name: "Leonard Da Silva",
		review:
			"This has been used by all the people in my family for many years. I much prefer the lotion to any other lotion that have come across. A little goes a long way, smells gorgeous and really does the job on very dry skin.",
	},
];

function truncateWords(content, limit) {
	if (!content) return ""; // Return empty string if content is falsy
	const words = content.split(" ");
	if (words.length > limit) {
		return words.slice(0, limit).join(" ") + "...";
	} else {
		return content;
	}
}

const Testimonials = () => {
	return (
		<>
			<h3 className="text-xl font-bold text-[#151532] py-5">Testimonials</h3>
			<Swiper
				slidesPerView={2}
				breakpoints={{
					0: {
						slidesPerView: 1,
					},
					600: {
						slidesPerView: 2,
					},
				}}
				autoplay={{ delay: 5000, disableOnInteraction: false }}
				spaceBetween={10} // Adjust spacing between slides
				navigation={true}
				modules={[Navigation, Autoplay]}
				className="w-full flex justify-center"
			>
				{data.map(({ avatar, name, review }, index) => (
					<SwiperSlide className="flex justify-center" key={index}>
						<div className="w-[420px] h-[400px] border border-[#EC3237] text-center">
							<div className="p-4">
								<div className="inline-block text-center">
									<img
										src={avatar}
										alt={name}
										className="w-[100px] h-[100px] rounded-full border-2 border-[#3F3F95] object-cover"
									/>
								</div>
								<p className="text-lg text-center py-4">{truncateWords(review, 40)}</p>
								<p className="py-5 text-gray-500 italic">{name}</p>
							</div>
						</div>
					</SwiperSlide>
				))}
			</Swiper>
		</>
	);
};

export default Testimonials;
