import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { addToCarts } from "redux/slices/cartSlice";
import { removeWishlist, removeFromWishList } from "redux/slices/wishListSlice";
import { toast } from "react-toastify";
import Footer from "components/footer/Footer";
import Header from "components/home/Header";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton, Tooltip } from "@mui/material";
import MediaQuery from "components/media-query/MediaQuery";

const Favorite = () => {
	const [quantities, setQuantities] = useState({});
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { wishlistsItems } = useSelector((state) => state?.wishlists);

	const increaseQuantity = useCallback((productId) => {
		setQuantities((prevQuantities) => ({
			...prevQuantities,
			[productId]: (prevQuantities[productId] || 1) + 1,
		}));
	}, []);

	const decreaseQuantity = useCallback((productId) => {
		setQuantities((prevQuantities) => ({
			...prevQuantities,
			[productId]: Math.max((prevQuantities[productId] || 1) - 1, 0),
		}));
	}, []);

	const removeWishlistHandler = useCallback(
		(wishlist) => {
			dispatch(removeWishlist(wishlist));
		},
		[dispatch],
	);

	// add to cart in wish page
	const addToCartHandler = () => {
		// e.preventDefault();
		let item = {
			id: wishlistsItems?.find(() => true)?._id?.toString(),
			quantity: quantities[wishlistsItems?.find(() => true)?._id?.toString()] || 1,
			price: wishlistsItems
				?.find(
					(wishlist) =>
						wishlist.variants.find((variant) => variant.quantityType === "single")
							?.item?.[0]?.price,
				)
				?.variants.find((variant) => variant.quantityType === "single")?.item?.[0]
				?.price,
			size: wishlistsItems
				?.find(
					(wishlist) =>
						wishlist.variants.find((variant) => variant.quantityType === "single")
							?.item?.[0]?.price,
				)
				?.variants.find((variant) => variant.quantityType === "single")?.item?.[0]
				?.size,
			name: wishlistsItems?.find(() => true)?.name?.toString(),
			image: wishlistsItems?.map((wishlist) => wishlist.images[0].url),
		};
		dispatch(addToCarts(item));
		dispatch(removeFromWishList({ id: item._id }));

		toast.success("Product added to cart!", {
			autoClose: 2000,
			position: "top-right",
		});
		navigate("/cart");
	};
	console.log("wishes " + wishlistsItems);
	return (
		<div className="min-h-screen flex flex-col">
			<div className="flex-grow">
				<Header />
				{!wishlistsItems.length && (
					<div className="grid h-[40vh] place-items-center">
						<div className="text-center">
							<h2 className="text-xl">You don't have any products in wishlist.</h2>
							<Link className="font-semibold text-xl text-red-500" to="/products">
								Checkout our Products
							</Link>
						</div>
					</div>
				)}
				{wishlistsItems.length > 0 &&
					wishlistsItems?.map((wishlist, index) => (
						<div key={wishlist.id} className="flex flex-col gap-2 p-10 bg-gray-100">
							{/* small screen start */}
							<MediaQuery min="sm" max="md">
								<div className="flex flex-row bg-white p-4 rounded-md">
									<div className="w-full h-full flex flex-row gap-5 items-center justify-between">
										<div>
											<div className="w-[100px] h-[90px] relative">
												<img
													src={wishlist.images[0].url}
													alt={wishlist.name}
													className="w-full h-full rounded-md object-fit"
												/>
											</div>
											<div>
												<p className="font-semibold">{wishlist.name}</p>
												<p></p>
												<p>
													₦{" "}
													{wishlist.variants
														.find((variant) => variant.quantityType === "single")
														?.item?.[0]?.price.toFixed(2)
														.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
												</p>
											</div>
											<div className="w-full flex justify-center gap-2">
												<div className="">
													<Link to={`/product/${wishlist._id}`} className="mt-2">
														<Tooltip title="View Item">
															<IconButton>
																<VisibilityIcon />
															</IconButton>
														</Tooltip>
													</Link>
												</div>
												<div>
													<Tooltip title="Add to Cart">
														<IconButton onClick={addToCartHandler}>
															<ShoppingCartIcon />
														</IconButton>
													</Tooltip>
												</div>
												<div>
													<Tooltip title="Remove Item">
														<IconButton onClick={() => removeWishlistHandler(wishlist)}>
															<DeleteIcon />
														</IconButton>
													</Tooltip>
												</div>
											</div>
										</div>
										<div className="flex gap-7 items-center py-4">
											<div>
												<div className="flex items-center">
													<button
														onClick={() => decreaseQuantity(wishlist.id)}
														className="p-2 border border-[#F7F7FC] bg-white"
													>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 24 24"
															width="24"
															height="24"
														>
															<path fill="none" d="M0 0h24v24H0z" />
															<path d="M5 11h14v2H5z" />
														</svg>
													</button>
													<button className="py-2 px-6 border border-[#F7F7FC] bg-white">
														{quantities[wishlist.id] || 1}
													</button>
													<button
														onClick={() => increaseQuantity(wishlist.id)}
														className="p-2 border border-[#F7F7FC] bg-white"
													>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 24 24"
															width="24"
															height="24"
														>
															<path fill="none" d="M0 0h24v24H0z" />
															<path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" />
														</svg>
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</MediaQuery>
							{/* small screen end */}

							{/* Middle Screen Display start */}
							<MediaQuery min="md" max="lg">
								<div className="flex flex-row bg-white p-4 rounded-md">
									<div className="w-full flex flex-row gap-5 items-center justify-between">
										<div className="w-[100px] h-[90px] relative">
											<img
												src={wishlist.images[0].url}
												alt={wishlist.name}
												className="w-full h-full rounded-md object-fit"
											/>
										</div>
										<div>
											<p>{wishlist.name}</p>
											<p></p>
											<div className="flex gap-7 items-center py-4">
												<div>
													<div className="flex items-center">
														<button
															onClick={() => decreaseQuantity(wishlist.id)}
															className="p-2 border border-[#F7F7FC] bg-white"
														>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																viewBox="0 0 24 24"
																width="24"
																height="24"
															>
																<path fill="none" d="M0 0h24v24H0z" />
																<path d="M5 11h14v2H5z" />
															</svg>
														</button>
														<button className="py-2 px-6 border border-[#F7F7FC] bg-white">
															{quantities[wishlist.id] || 1}
														</button>
														<button
															onClick={() => increaseQuantity(wishlist.id)}
															className="p-2 border border-[#F7F7FC] bg-white"
														>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																viewBox="0 0 24 24"
																width="24"
																height="24"
															>
																<path fill="none" d="M0 0h24v24H0z" />
																<path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" />
															</svg>
														</button>
													</div>
												</div>
											</div>
										</div>
										<div>
											<p>
												₦{" "}
												{wishlist.variants
													.find((variant) => variant.quantityType === "single")
													?.item?.[0]?.price.toFixed(2)
													.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
											</p>
										</div>
										<div className="">
											<Link to={`/product/${wishlist._id}`} className="mt-2">
												<Tooltip title="View Item">
													<IconButton>
														<VisibilityIcon />
													</IconButton>
												</Tooltip>
											</Link>
										</div>
										<div>
											<Tooltip title="Add to Cart">
												<IconButton onClick={addToCartHandler}>
													<ShoppingCartIcon />
												</IconButton>
											</Tooltip>
										</div>
										<div>
											<Tooltip title="Remove Item">
												<IconButton onClick={() => removeWishlistHandler(wishlist)}>
													<DeleteIcon />
												</IconButton>
											</Tooltip>
										</div>
									</div>
								</div>
							</MediaQuery>
							{/* Middle Screen Display end */}

							{/* large screen start */}
							<MediaQuery min="lg">
								<div className="flex flex-row bg-white p-4 rounded-md">
									<div className="w-full flex flex-row gap-5 items-center justify-between">
										<div className="flex flex-row gap-5">
											<div className="w-[100px] h-[90px] relative">
												<img
													src={wishlist.images[0].url}
													alt={wishlist.name}
													className="w-full h-full rounded-md object-fit"
												/>
											</div>
											<div className="flex flex-col justify-between">
												<div>
													<p>{wishlist.name}</p>
													<p></p>
													<div className="flex gap-7 items-center py-4">
														<div>
															<p className="text-lg text-[#464545">Quantity</p>
														</div>
														<div className="flex items-center">
															<button
																onClick={() => decreaseQuantity(wishlist.id)}
																className="p-2 border border-[#F7F7FC] bg-white"
															>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	viewBox="0 0 24 24"
																	width="24"
																	height="24"
																>
																	<path fill="none" d="M0 0h24v24H0z" />
																	<path d="M5 11h14v2H5z" />
																</svg>
															</button>
															<button className="py-2 px-6 border border-[#F7F7FC] bg-white">
																{quantities[wishlist.id] || 1}
															</button>
															<button
																onClick={() => increaseQuantity(wishlist.id)}
																className="p-2 border border-[#F7F7FC] bg-white"
															>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	viewBox="0 0 24 24"
																	width="24"
																	height="24"
																>
																	<path fill="none" d="M0 0h24v24H0z" />
																	<path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" />
																</svg>
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div>
											<p>
												₦{" "}
												{wishlist.variants
													.find((variant) => variant.quantityType === "single")
													?.item?.[0]?.price.toFixed(2)
													.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
											</p>
										</div>
										<div className="flex gap-5 items-center">
											<Link to={`/product/${wishlist._id}`} className="mt-2">
												<button className="w-7 h-7">
													<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
														<path
															d="M1.18164 12C2.12215 6.87976 6.60812 3 12.0003 3C17.3924 3 21.8784 6.87976 22.8189 12C21.8784 17.1202 17.3924 21 12.0003 21C6.60812 21 2.12215 17.1202 1.18164 12ZM12.0003 17C14.7617 17 17.0003 14.7614 17.0003 12C17.0003 9.23858 14.7617 7 12.0003 7C9.23884 7 7.00026 9.23858 7.00026 12C7.00026 14.7614 9.23884 17 12.0003 17ZM12.0003 15C10.3434 15 9.00026 13.6569 9.00026 12C9.00026 10.3431 10.3434 9 12.0003 9C13.6571 9 15.0003 10.3431 15.0003 12C15.0003 13.6569 13.6571 15 12.0003 15Z"
															fill="rgba(0,0,0,1)"
														></path>
													</svg>
												</button>
											</Link>
											<div>
												<button
													onClick={addToCartHandler}
													className="mt-2 p-2 px-4 rounded-md border border-[#3F3F95] text-[#3F3F95] hover:bg-[#3F3F95] hover:text-white"
												>
													Add To Cart
												</button>
											</div>
										</div>

										<div>
											<button
												onClick={() => removeWishlistHandler(wishlist)}
												className="w-7 h-7"
											>
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
													<path d="M4 8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8ZM7 5V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V5H22V7H2V5H7ZM9 4V5H15V4H9ZM9 12V18H11V12H9ZM13 12V18H15V12H13Z"></path>
												</svg>
											</button>
										</div>
									</div>
								</div>
							</MediaQuery>
							{/* large screen end */}
						</div>
					))}
			</div>
			<Footer />
		</div>
	);
};

export default Favorite;
