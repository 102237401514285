import React from "react";
import Carousel from "react-material-ui-carousel";

import Item from "./items";
import slider from "./slider.json";

function SliderTab() {
  return (
    <div>
      <Carousel  className=" w-[100%] h-[100%]"  indicators={false} >
        {slider.map((item) => (
          <Item key={item.id} item={item} />
        ))}
      </Carousel>

    </div>
  );
}

export default SliderTab;
