let serverUrl;
let socketUrl;

if (process.env.NODE_ENV === "development") {
	serverUrl = "http://localhost:3300";
	socketUrl = "http://localhost:3300";
}

if (process.env.NODE_ENV === "production") {
	serverUrl = "https://emosbest-api.vercel.app";
	socketUrl =
		"https://emosbest-api.vercel.app" || "wss://emosbest-api.vercel.app";
}

const baseURL = `${serverUrl}/api/v1`;

export { serverUrl, baseURL, socketUrl };
