import * as Yup from "yup";

export const initialForgotPasswordValues = {
  email: '',
};

export const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
});
