import { baseURL } from "../shared/url";
import {
	getLocalAccessToken,
	getLocalRefreshToken,
	updateLocalAccessToken,
	removeToken,
	updateLocalRefreshToken,
} from "./tokenService";

async function fetchWithTokenRefresh(
	url,
	options,
	method = "GET",
	data = null,
) {
	const accessToken = getLocalAccessToken();
	let response;

	const headers = {
		...options?.headers,
	};

	if (!(data instanceof FormData)) {
		headers["Content-Type"] = "application/json";
	}

	if (accessToken) {
		headers["Authorization"] = `Bearer ${accessToken}`;
	}

	try {
		response = await fetch(baseURL + url, {
			method,
			...options,
			headers,
			body: data ? (data instanceof FormData ? data : JSON.stringify(data)) : null,
		});

		if (response.ok) {
			return response.json();
		} else if (response.status === 401) {
			console.log("Received 401 error. Attempting to refresh token...");

			const refreshToken = getLocalRefreshToken();
			if (!refreshToken) {
				await removeToken();
				throw new Error("Refresh token not found");
			}

			try {
				const refreshResponse = await fetch(
					baseURL + "/auth/refresh/token",
					{
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify({ refreshToken }),
					},
				);

				if (!refreshResponse.ok) {
					throw new Error("Failed to refresh token");
				}

				const refreshData = await refreshResponse.json();
				const { access_token, refresh_token } = refreshData;
				updateLocalAccessToken(access_token);
				updateLocalRefreshToken(refresh_token);

				headers["Authorization"] = `Bearer ${access_token}`;
				const retryResponse = await fetch(baseURL + url, {
					method,
					...options,
					headers,
					body: data
						? data instanceof FormData
							? data
							: JSON.stringify(data)
						: null,
				});

				if (!retryResponse.ok) {
					const retryErrorData = await retryResponse.json();
					throw new Error(retryErrorData.message || retryResponse.statusText);
				}

				return retryResponse.json();
			} catch (refreshError) {
				await removeToken();
				throw new Error(refreshError.message);
			}
		} else {
			const errorData = await response.json();
			console.log("Error data:", errorData);
			throw new Error(errorData.message || response.statusText);
		}
	} catch (error) {
		throw new Error(error.message);
	}
}

const httpService = {
	get: async (url, config) => {
		try {
			return fetchWithTokenRefresh(url, config, "GET");
		} catch (error) {
			let errorMessage = "An error occurred.";
			errorMessage = error.message || errorMessage;
			return Promise.reject({
				message: errorMessage,
			});
		}
	},
	post: async (url, data, config) => {
		try {
			return await fetchWithTokenRefresh(url, config, "POST", data);
		} catch (error) {
			let errorMessage = "An error occurred.";
			errorMessage = error.message || errorMessage;
			return Promise.reject({
				message: errorMessage,
			});
		}
	},
	put: async (url, data, config) => {
		try {
			return fetchWithTokenRefresh(url, config, "PUT", data);
		} catch (error) {
			let errorMessage = "An error occurred.";
			errorMessage = error.message || errorMessage;
			return Promise.reject({
				message: errorMessage,
			});
		}
	},
	delete: async (url, config) => {
		try {
			return fetchWithTokenRefresh(url, config, "DELETE");
		} catch (error) {
			let errorMessage = "An error occurred.";
			errorMessage = error.message || errorMessage;
			return Promise.reject({
				message: errorMessage,
			});
		}
	},
	patch: async (url, data, config) => {
		try {
			return fetchWithTokenRefresh(url, config, "PATCH", data);
		} catch (error) {
			let errorMessage = "An error occurred.";
			errorMessage = error.message || errorMessage;
			return Promise.reject({
				message: errorMessage,
			});
		}
	},
};

export default httpService;
