import React, { useEffect, useCallback } from "react";
import { removeToken, getLocalAccessToken } from "services/tokenService";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { logoutUser } from "services/authService";

const ProtectedRoute = ({ redirectPath = "/", children }) => {
	const navigate = useNavigate();

	const isAuthenticated = useCallback(() => {
		const token = getLocalAccessToken();
		if (!token) return false;
		const decodedToken = jwtDecode(token);
		const isExpired = Date.now() > decodedToken.exp * 1000;

		return !isExpired;
	}, []);

	useEffect(() => {
		const checkAuthentication = async () => {
			if (!isAuthenticated()) {
				navigate(redirectPath, { replace: true });
				await logoutUser();
				removeToken();
			}
		};

		checkAuthentication();
	}, [isAuthenticated, navigate, redirectPath]);

	if (!isAuthenticated()) {
		return <Navigate to={redirectPath} replace />;
	}

	return children || <Outlet />;
};

export default ProtectedRoute;
