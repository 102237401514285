import Footer from 'components/footer/Footer'
import Header from 'components/home/Header'
import React from 'react'
import Carousel from './Carousel';
import ContactForm from './ContactForm';

const ContactUs = () => {
  return (
			<div className="min-h-screen flex flex-col">
				<div className="flex-grow">
					<Header />
					<Carousel>
						<ContactForm />
					</Carousel>
					<Footer />
				</div>
			</div>
		);
}

export default ContactUs