import React from "react";
import Lock from "../../assets/mail.svg";
import { Link } from "react-router-dom";
import ArrowLeft from "../../assets/arrow-left.svg";
import { FormErrorMessage } from "components/forms/FormErrorMessage";
import SubmitButton from "components/forms/SubmitButton";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "services/authService";
import { toast } from "react-toastify";
import {
  initialForgotPasswordValues,
  ForgotPasswordSchema,
} from "modules/schemas";
import { Formik, Form, Field } from "formik";

const ForgotForm = () => {
  const navigate = useNavigate();
  
  const handleForgotPassword = async (values) => {
    try {
      const { message } = await forgotPassword(values);

      toast( `${message}` , {
        type: "success",
       autoClose: 3000
      });
      //navigate over here
      navigate("/forgottwo", {
       state: {email: values.email},
      });
    } catch (e) {
      toast( `${e.message}`, {
        type: "error",
        autoClose: 3000,
      });
    }
  };
  return (
    <div className="w-full">
      <div className="max-h-[75vh] grid content-center ">
        <div className="bg-eb-secondary max-h-[75vh] rounded-md pt-5 pb-5">
          <div className="grid justify-center ">
            <img src={Lock} alt="envelop" width={50} height={50} />
          </div>
          <div className="text-lg font-medium text-eb-primary grid justify-center mt-[14px]">
            Forgot Password?
          </div>
          <div className="font-light text-md grid justify-center mt-[10px] text-eb-primary">
            <div> No worries, we’ll send you reset instructions</div>
          </div>

          <Formik
            initialValues={initialForgotPasswordValues}
            validationSchema={ForgotPasswordSchema}
            onSubmit={handleForgotPassword}
          >
            <Form>
              <div className="mb-[14px] mt-[14px] ml-[42px] mr-[42px] text-eb-primary">
                <div className="text-sm">Email Address</div>
                <div>
                  {" "}
                  <Field
                    name="email"
                    type="email"
                    className="h-[38px] w-[100%] rounded-md border border-eb-primary mt-3 pl-3"
                    placeholder="Your Email"
                  />{" "}
                </div>
                <FormErrorMessage name={"email"} />
              </div>

              <div className="grid justify-center mt-[24px] ml-[62px] mr-[62px] ">
                <SubmitButton   className="h-[48px] w-[162px] bg-eb-primary hover:bg-black rounded-lg text-white text-sm grid justify-center content-center ">
                  Reset Password
                </SubmitButton>
              </div>
            </Form>
          </Formik>

          <div>
            <div className="font-light text-sm grid justify-center mt-5">
              <div className="text-eb-primary">
                <Link to="/login" className="flex gap-1">
                  <img src={ArrowLeft} alt="arrow" />
                  Back to Sign In
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotForm;
