import React from "react";
import SlidImg from "../assets/bannerImg.png";

const BlogImage = () => {
  return (
    <div className="w-full flex ">
      <div className="w-[50%] bg-[#D8E1E6] rounded-l-lg">
        <div className="p-[100px] text-eb-primary text-[25px] "> BLOG</div>
      </div>

      <div className="w-[50%] ">
        <img
          src={SlidImg}
          className="w-full h-full object-fit rounded-r-lg"
          alt="bannerImg"
        />
      </div>
    </div>
  );
};

export default BlogImage;
