import httpService from "./httpService";

export async function getAllBrands() {
	try {
		const response = await httpService.get("/brand/all");
		return response;
	} catch (error) {
		throw new Error(error.message);
	}
}

export async function getABrand(id) {
	try {
		let url = `/brand/${id}`;
		const res = await httpService.get(url);
		return res;
	} catch (error) {
		throw new Error(error.message);
	}
}

export async function viewProductByBrand(id) {
	try {
		let url = `/product/brand/${id}`;
		const res = await httpService.get(url);
		return res;
	} catch (error) {
		throw new Error(error.message);
	}
}
