import * as Yup from "yup";

export const initialLoginValues = {
    email: '',
    password: '',
};

export const LoginSchema  = Yup.object().shape({
    email: Yup.string()
    .email("Invalid email format")
    .trim()
    .required("Email is required"),
    password: Yup.string().required('password is required'),
});