import React, { useState, useEffect, useCallback } from "react";
import { userInvoices } from "services/myOrderCRUD";
import { useNavigate, Link } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
	IconButton,
	Menu,
  Grid,
  Box,
	Typography,
	Select,
	MenuItem,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import { CurrencyFormatter } from "components/currency-formatter/CurrencyFormatter";

const MyOrderTable = () => {
	const [myOrders, setMyOrders] = useState([]);
	const [pageLimit, setPageLimit] = useState(10);
	const [pageNumber, setPageNumber] = useState(1);
	const [totalPage, setTotalPage] = useState(0);
	const [totalOrder, setTotalOrder] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const [filter, setFilter] = useState(null);

	const navigate = useNavigate();

	const handleClickMenu = (index) => (event) => {
		event.stopPropagation();
		setFilter((prevFilter) => (prevFilter === index ? null : index));
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setFilter(null);
		setAnchorEl(null);
	};

	const getAllOrderData = useCallback(async () => {
		try {
			const response = await userInvoices(pageNumber, pageLimit, "");
			setMyOrders(response.data);
			setTotalOrder(response.invoiceCount);
			setTotalPage(Math.ceil(response.invoiceCount / pageLimit));
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	}, [pageNumber, pageLimit]);

	useEffect(() => {
		getAllOrderData();
	}, [pageNumber, pageLimit, getAllOrderData]);

	const handlePagePrev = () => {
		if (pageNumber > 1) setPageNumber(pageNumber - 1);
	};

	const handlePageNext = () => {
		if (pageNumber < totalPage) setPageNumber(pageNumber + 1);
	};

	const handlePageLimit = (e) => {
		setPageLimit(Number(e.target.value));
		setPageNumber(1);
	};

	const formatDate = (dateString) => {
		const date = new Date(dateString);
		return date.toLocaleDateString("en-US", {
			day: "numeric",
			month: "long",
			year: "numeric",
		});
	};

	return (
		<>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Order Number</TableCell>
							<TableCell>Product Name</TableCell>
							<TableCell>Date</TableCell>
							<TableCell>Shipment Status</TableCell>
							<TableCell>Payment Status</TableCell>
							<TableCell>Total</TableCell>
							<TableCell>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{myOrders.length === 0 ? (
							<TableRow>
								<TableCell colSpan={7} className="text-center">
									No orders yet
								</TableCell>
							</TableRow>
						) : (
							myOrders.map((order, index) => (
								<TableRow key={order._id}>
									<TableCell>{order.orderNumber}</TableCell>
									<TableCell>{order.orderItems[0]?.name}</TableCell>
									<TableCell>{formatDate(order.createdAt)}</TableCell>
									<TableCell>{order.shipmentStatus}</TableCell>
									<TableCell>{order.paid === "No" ? "Pending" : "Paid"}</TableCell>
									<TableCell>₦{CurrencyFormatter(order.grandTotalPrice)}</TableCell>
									<TableCell>
										<IconButton onClick={handleClickMenu(index)}>
											<MoreVertIcon />
										</IconButton>
										<Menu
											anchorEl={filter === index ? anchorEl : null}
											open={filter === index}
											onClose={handleClose}
											anchorOrigin={{ vertical: "top", horizontal: "right" }}
											transformOrigin={{ vertical: "top", horizontal: "right" }}
										>
											<MenuItem component={Link} to={`invoice/${order._id}`}>
												View
											</MenuItem>
											<MenuItem component={Link} to={`details/${order._id}`}>
												Track
											</MenuItem>
										</Menu>
									</TableCell>
								</TableRow>
							))
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<Box
				sx={{
					px: 2,
					py: 4,
					bgcolor: "white",
					borderTop: 1,
					borderColor: "#e0e0e0",
					mb: "20%",
				}}
			>
				<Grid
					container
					spacing={2}
					justifyContent="space-between"
					alignItems="center"
				>
					<Grid item xs={12} sm={6} md={4}>
						<Box variant="body2">
							Showing
							<Select
								value={pageLimit}
								onChange={handlePageLimit}
								variant="standard"
								sx={{ ml: 2, minWidth: 60 }}
							>
								{[10, 15, 20, 25].map((limit) => (
									<MenuItem key={limit} value={limit}>
										{limit}
									</MenuItem>
								))}
							</Select>
							per page
						</Box>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={4}
						display="flex"
						justifyContent={{ xs: "center", sm: "flex-end" }}
						alignItems="center"
						gap={1}
					>
						<Typography variant="body2">{pageNumber}</Typography>
						<Typography variant="body2">of</Typography>
						<Typography variant="body2">{totalPage}</Typography>
						<Typography
							variant="body2"
							onClick={handlePagePrev}
							sx={{ cursor: "pointer", ml: 1 }}
						>
							Prev
						</Typography>
						<Typography
							variant="body2"
							onClick={handlePageNext}
							sx={{ cursor: "pointer", ml: 1 }}
						>
							Next
						</Typography>
					</Grid>
				</Grid>
			</Box>
		</>
	);
};

export default MyOrderTable;
