import React, { useState, useEffect, useCallback } from "react";
import Header from "./Header";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Rating from "@mui/material/Rating";
import FavoriteIcon from "@mui/icons-material/Favorite";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Tab, Tabs } from "@mui/material";
import SlidImg from "../assets/bannerImg.png";
import PricingImg from "../assets/pricingbanner.png";
import { Link } from "react-router-dom";
import Testimonials from "components/testimonials/Testimonials";
import Blog from "components/blog/Blog";
import Footer from "components/footer/Footer";
import Loader from "components/loader/Loader";
import { useSelector, useDispatch } from "react-redux";
import { addToCarts } from "redux/slices/cartSlice";
import { addToWishList } from "redux/slices/wishListSlice";
import { ConstructionOutlined } from "@mui/icons-material";
import { getAllProducts } from "services/productCRUD";
import { getAllCategories, viewProductByCategory } from "services/categoryCRUD";
import { getAllBrands } from "services/brandCRUD";
import {
	brandPlaceholder,
	categoryPlaceholder,
	productPlacholder,
} from "./data";

const HomePage = () => {
	const dispatch = useDispatch();
	const navigate = useDispatch();
	const [tabIndex, setTabIndex] = useState(0);
	const [error, setError] = useState(null);
	const [categories, setCategories] = useState([]);
	const [brands, setBrands] = useState([]);
	const [popularProducts, setPopularProducts] = useState([]);
	const [hotProducts, setHotProducts] = useState([]);
	const [loading, setLoading] = useState(false);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(8);
	const [categoryId, setCategoryId] = useState(null);

	const fetchAllProducts = useCallback(async () => {
		try {
			const response = await getAllProducts();
			const { data } = response;
			const productData = data.length > 0 ? data : productPlacholder;
			setHotProducts(productData?.filter((product) => product.isHotSale));
			setPopularProducts(productData?.filter((product) => product.isPopular));
		} catch (err) {
			console.error("Error fetching products:", err);
			setError("Could not fetch products. Please try again later.");
		}
	}, []);

	const fetchAllCategories = useCallback(async () => {
		try {
			const response = await getAllCategories();
			const { data } = response;
			const categoryData = data.length > 0 ? data : categoryPlaceholder;
			setCategories(categoryData);
		} catch (err) {
			console.error("Error fetching categories:", err);
			setError("Could not fetch categories. Please try again later.");
		}
	}, []);

	const fetchAllBrands = useCallback(async () => {
		try {
			const response = await getAllBrands();
			const { data } = response;
			const brandData = data.length > 0 ? data : brandPlaceholder;
			setBrands(brandData);
		} catch (err) {
			console.error("Error fetching brands:", err);
			setError("Could not fetch brands. Please try again later.");
		}
	}, []);

	useEffect(() => {
		// by promise, fetch all products, categories and brands
		Promise.all([fetchAllProducts(), fetchAllCategories(), fetchAllBrands()])
			.then(() => setLoading(false))
			.catch(() => setLoading(false));
	}, [fetchAllProducts, fetchAllCategories, fetchAllBrands]);

	const fetchProductsByCategoryId = useCallback(async () => {
		try {
			if (categoryId) {
				const response = await viewProductByCategory(
					categoryId,
					currentPage,
					pageSize,
				);
				const { data } = response;
				setSelectedCategory(data);
			}
		} catch (err) {
			console.error("Error fetching products:", err);
			setError("Could not fetch products. Please try again later.");
		}
	}, [categoryId, currentPage, pageSize]);

	useEffect(() => {
		const _categoryId = categories[tabIndex]?._id;
		setCategoryId(_categoryId);
		fetchProductsByCategoryId();
	}, [tabIndex, categories, fetchProductsByCategoryId]);

	const handleTabChange = async (event, newValue) => {
		setTabIndex(newValue);
		const _categoryId = categories[newValue]._id;
		setCategoryId(_categoryId);

		fetchProductsByCategoryId();
	};

	const handlePageChange = async (_, newPage) => {
		setCurrentPage(newPage);
		const _categoryId = categories[tabIndex]?._id;
		setCategoryId(_categoryId);
		fetchProductsByCategoryId();
	};

	const addToWishHandler = (product) => {
		dispatch(addToWishList(product));
	};

	const addToCart = (itemId) => {
		if (!itemId) {
			console.error("Invalid item data:", itemId);
			return;
		}

		let item = {
			id: itemId._id,
			quantity: 1,
			price: itemId?.variants[0].item[0].price,
			size: itemId?.variants[0].item[0].size,
			name: itemId.name,
			image: itemId.images?.map((img) => img.url),
		};
		dispatch(addToCarts(item));
	};

	if (loading) {
		return <Loader />;
	}
	if (error) {
		// beautify error message by centering it and making the text bold
		return <div style={{ textAlign: "center", fontWeight: "bold" }}>{error}</div>;
	}

	return (
		<>
			<Header />
			<div className="px-[40px] md:px-[80px] py-[40px]">
				<div className="w-full flex">
					<div className="w-[100%] lg:w-[50%] bg-[#D8E1E6]">
						<div className="p-[50px]">
							<h3 className="text-[#3F3F95] my-4 font-bold text-xl">ACOFRESH</h3>
							<p className="text-lg text-[#3F3F95] mb-[40px]">
								ACOFRESH hair food is specially developed product which softens the
								hair, thereby making it easier to be styled. It contains Moisture
								Resistant Formula with paraffin oil which helps relieve dry scalp.
							</p>
							<Link to="/products">
								<button className="bg-[#3F3F95] hover:bg-black p-2 px-4 rounded-lg  text-white">
									See Products
								</button>
							</Link>
						</div>
					</div>
					<div className="hidden lg:block w-[50%]">
						<img src={SlidImg} className="w-full h-full object-fit" alt="bannerImg" />
					</div>
				</div>

				<div className="py-9">
					<h2 className="text-xl font-semibold py-5">Popular Products</h2>
					{popularProducts?.length > 0 ? (
						<div className="flex justify-center flex-wrap gap-5">
							{popularProducts.slice(0, 3).map((product, index) => {
								const price = product?.variants.find(
									(variant) => variant.quantityType === "single",
								)?.item?.[0]?.price;

								return (
									<div
										key={product._id}
										className="w-[300px] bg-white shadow-xl rounded-md"
									>
										<div className="p-4">
											<Link to={`/product/${product._id}`} className="mt-2">
												<div className="relative">
													{product?.images?.map((img, idx) => (
														<img
															key={idx}
															src={img.url}
															alt="productimg"
															className="w-full h-[245px] object-fit"
														/>
													))}
													<div className="bg-black/[.2] p-2 rounded-md absolute bottom-1 right-1 text-white">
														{product?.inStock === true ? "In Stock" : "Out of Stock"}
													</div>
												</div>
											</Link>
											<div className="flex justify-between py-4">
												<p>{product.name}</p>
												<p className="text-black font-semibold">
													₦ {price?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
												</p>
											</div>
											<p>
												Brand: <span className="font-semibold">{product?.brand?.name}</span>
											</p>
											<div>
												<Rating name={`rating-${index}`} value={product.ratings} readOnly />
											</div>
											<div className="flex items-center justify-between">
												<button
													onClick={() => addToCart(product)}
													disabled={!product.inStock}
													className="mt-2 p-2 px-4 rounded-md border border-[#3F3F95] text-[#3F3F95] hover:bg-[#3F3F95] hover:text-white"
												>
													Add to Cart
												</button>
												<div>
													<IconButton
														onClick={() => addToWishHandler(product)}
														aria-label="add to favorites"
													>
														<FavoriteIcon />
													</IconButton>
												</div>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					) : (
						<p>No popular products found.</p>
					)}
				</div>

				<div className="py-5">
					<div className="flex justify-between items-center">
						<h2 className="text-xl font-semibold py-5">Brands</h2>
						<Link className="text-[#6C6B6B] text-lg" to="/brands">
							<p>See All</p>
						</Link>
					</div>
					{brands && (
						<div className="flex justify-center flex-wrap gap-5">
							{brands?.slice(0, 4).map((item, index) => (
								<div key={item._id} className="w-[300px] bg-white shadow-xl rounded-md">
									<div className="p-4">
										<img
											src={item.image.url}
											alt="brandimg"
											className="w-full h-[300px] object-fit"
										/>
										<p>{item.name}</p>
									</div>
								</div>
							))}
						</div>
					)}

					{!brands || brands.length === 0 ? <p>No brands found.</p> : null}
				</div>

				<div className="py-5">
					<h2 className="text-xl font-semibold py-5">Hot Sales</h2>
					{hotProducts?.length > 0 ? (
						<div className="flex justify-center flex-wrap gap-5">
							{hotProducts?.slice(0, 4).map((item, index) => (
								<div key={item._id} className="w-[300px] bg-white shadow-xl rounded-md">
									<div className="p-4">
										<Link to={`/product/${item._id}`} className="mt-2">
											<div className="relative">
												{item.images?.map((img, idx) => (
													<img
														key={idx}
														src={img.url}
														alt={item.name}
														className="w-full h-[300px] object-fit"
													/>
												))}
												<div className="bg-black/[.2] p-2 rounded-md absolute bottom-1 right-1 text-white">
													{item.inStock === true ? "In Stock" : "Out of Stock"}
												</div>
											</div>
										</Link>
										<div className="flex items-center justify-between py-4">
											<p>{item.name}</p>
											<div>
												<IconButton
													onClick={() => addToWishHandler(item)}
													aria-label="add to favorites"
												>
													<FavoriteIcon />
												</IconButton>
											</div>
										</div>
										<p className="text-black font-semibold">
											₦{" "}
											{item.variants
												.find((variant) => variant.quantityType === "single")
												?.item?.[0]?.price.toFixed(2)
												.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
										</p>

										<p>
											Brand: <span className="font-semibold">{item.brand.name}</span>
										</p>
										<div>
											<Rating name="read-only" value={item.ratings} readOnly />
										</div>
										<div className="flex justify-between gap-4 items-center">
											<div className="flex justify-between items-center gap-7">
												<div>
													<button
														onClick={() => addToCart(item)}
														className="mt-2 p-2 px-4 rounded-md border border-[#3F3F95] text-[#3F3F95] hover:bg-[#3F3F95] hover:text-white"
													>
														Add to Cart
													</button>
												</div>
												<div>
													<Link to={`/product/${item._id}`}>
														<IconButton>
															<VisibilityIcon />
														</IconButton>
													</Link>
												</div>
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					) : (
						<p>No hot sale products found.</p>
					)}
				</div>

				<div className="py-5">
					<div className="w-full relative">
						<img src={PricingImg} alt="" className="w-full h-[250px] object-fit" />
						<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
							<div className="text-center">
								<h4 className="text-xl font-semibold py-2">Introducing</h4>
								<p className="text-lg py-2">Wholesale Purchase</p>
								<button className="bg-[#3F3F95] rounded-md hover:bg-black p-2 text-white font-semibold">
									See Pricing
								</button>
							</div>
						</div>
					</div>
				</div>

				<div className="py-5">
					<h3 className="text-xl py-5 font-semibold">Our Services</h3>
					<div className="flex flex-wrap justify-center gap-20">
						<div className="bg-[#EFEFEF] w-[250px] h-[235px] p-6">
							<div className="inline-block p-4 bg-[#D9D9EA] rounded-full">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									width="24"
									height="24"
								>
									<path fill="none" d="M0 0h24v24H0z" />
									<path
										d="M17 8h3l3 4.056V18h-2.035a3.5 3.5 0 0 1-6.93 0h-5.07a3.5 3.5 0 0 1-6.93 0H1V6a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2zm0 2v3h4v-.285L18.992 10H17z"
										fill="rgba(95,95,167,1)"
									/>
								</svg>
							</div>
							<h5 className="text-lg font-semibold py-5">Quick Delivery</h5>
							<p className="text-lg">Nationwide Delivery on all Products</p>
						</div>

						<div className="bg-[#EFEFEF] w-[250px] h-[235px] p-6">
							<div className="inline-block p-4 bg-[#D9D9EA] rounded-full">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									width="24"
									height="24"
								>
									<path fill="none" d="M0 0h24v24H0z" />
									<path
										d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-3.5-8v2H11v2h2v-2h1a2.5 2.5 0 1 0 0-5h-4a.5.5 0 1 1 0-1h5.5V8H13V6h-2v2h-1a2.5 2.5 0 0 0 0 5h4a.5.5 0 1 1 0 1H8.5z"
										fill="rgba(95,95,167,1)"
									/>
								</svg>
							</div>
							<h5 className="text-lg font-semibold py-5">Discount</h5>
							<p className="text-lg">Sales and discount on wholesales</p>
						</div>

						<div className="bg-[#EFEFEF] w-[250px] h-[235px] p-6">
							<div className="inline-block p-4 bg-[#D9D9EA] rounded-full">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									width="24"
									height="24"
								>
									<path fill="none" d="M0 0h24v24H0z" />
									<path
										d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm4.5 8.25V9H6v6h1.5v-2.25h2V15H11V9H9.5v2.25h-2zM16.25 15H17a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-3a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h.75v1.5h1.5V15zm-1.75-4.5h2v3h-2v-3z"
										fill="rgba(95,95,167,1)"
									/>
								</svg>
							</div>
							<h5 className="text-lg font-semibold py-5">Quality Assurance</h5>
							<p className="text-lg">All Products duly certified</p>
						</div>

						<div className="bg-[#EFEFEF] w-[250px] h-[235px] p-6">
							<div className="inline-block p-4 bg-[#D9D9EA] rounded-full">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									width="24"
									height="24"
								>
									<path fill="none" d="M0 0H24V24H0z" />
									<path
										d="M15 3c.552 0 1 .448 1 1v4c0 .552-.448 1-1 1h-2v2h4c.552 0 1 .448 1 1v3h2c.552 0 1 .448 1 1v4c0 .552-.448 1-1 1h-6c-.552 0-1-.448-1-1v-4c0-.552.448-1 1-1h2v-2H8v2h2c.552 0 1 .448 1 1v4c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1v-4c0-.552.448-1 1-1h2v-3c0-.552.448-1 1-1h4V9H9c-.552 0-1-.448-1-1V4c0-.552.448-1 1-1h6zM9 17H5v2h4v-2zm10 0h-4v2h4v-2zM14 5h-4v2h4V5z"
										fill="rgba(95,95,167,1)"
									/>
								</svg>
							</div>
							<h5 className="text-lg font-semibold py-5">Distributors</h5>
							<p className="text-lg">Distributors Nationwide</p>
						</div>
					</div>
				</div>

				<div className="py-9">
					<Box sx={{ width: "100%" }}>
						<Tabs
							value={tabIndex}
							onChange={handleTabChange}
							textColor="primary"
							indicatorColor="secondary"
							aria-label="secondary tabs example"
						>
							{categories?.map((item, index) => (
								<Tab
									key={item._id}
									sx={{ marginRight: "20px" }}
									label={
										<span
											onClick={() => handleTabChange(null, index)}
											className="text-sm lg:text-lg capitalize"
										>
											{item.name}
										</span>
									}
								/>
							))}
						</Tabs>
					</Box>
					{loading ? (
						<p>Loading...</p>
					) : error ? (
						<p>Error fetching data. Please try again later</p>
					) : (
						tabIndex !== null && (
							<Box
								sx={{
									padding: 2,
									display: "flex",
									gap: "30px",
									flexWrap: "wrap",
								}}
							>
								{selectedCategory && selectedCategory?.length > 0 ? (
									selectedCategory?.map((product, index) => (
										<div className="flex flex-row gap-5 my-2" key={product._id}>
											<div className="w-[300px] bg-white shadow-xl rounded-md">
												<div className="p-4">
													<Link
														to={`/product/${product._id}`}
														className="text-decoration-none"
													>
														<div className="relative">
															{product.images?.map((img, idx) => (
																<img
																	key={idx}
																	src={img.url}
																	alt="productimg"
																	className="w-full h-[300px] object-fit"
																/>
															))}
															<div className="bg-black/[.2] p-2 rounded-md absolute bottom-1 right-1 text-white">
																{product.inStock === true ? "In Stock" : "Out of Stock"}
															</div>
														</div>
													</Link>
													<div className="flex justify-between py-4">
														<p>{product.name}</p>
														<p className="text-black font-semibold">
															₦{" "}
															{product.variants
																.find((variant) => variant.quantityType === "single")
																?.item?.[0]?.price.toFixed(2)
																.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
														</p>
													</div>
													<p>
														Brand: <span className="font-semibold">{product.brand.name}</span>
													</p>
													<div>
														<Rating name="read-only" value={product.ratings} readOnly />
													</div>
													<div className="flex justify-between gap-4 items-center">
														<div>
															<button
																onClick={() => addToCart(product)}
																className="mt-2 p-2 px-4 rounded-md border border-[#3F3F95] text-[#3F3F95] hover:bg-[#3F3F95] hover:text-white"
															>
																Add to Cart
															</button>
														</div>
														<div>
															<IconButton
																onClick={() => addToWishHandler(product)}
																aria-label="add to favorites"
															>
																<FavoriteIcon />
															</IconButton>
														</div>
													</div>
												</div>
											</div>
										</div>
									))
								) : (
									<p>No Products Available for this category.</p>
								)}
							</Box>
						)
					)}
					<div className="flex flex-wrap justify-center mt-4">
						{selectedCategory &&
							Array.from(
								{ length: Math.ceil(selectedCategory.length / pageSize) },
								(_, index) => (
									<button
										key={index}
										onClick={() => handlePageChange(null, index + 1)}
										className={`mx-2 px-4 py-2 border ${
											currentPage === index + 1 ? "bg-gray-300" : "bg-white"
										}`}
									>
										{index + 1}
									</button>
								),
							)}
					</div>
				</div>

				<div className="py-7">
					<div className="w-full">
						<Testimonials />
					</div>
				</div>

				<div className="py-5">
					<div className="w-full">
						<Blog />
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default HomePage;
