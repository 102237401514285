import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: "user",
    initialState:{
        user: localStorage.getItem("user")
            ? JSON.parse(localStorage.getItem("user"))
            : null,
        accessToken: localStorage.getItem("accessToken") ?? null,
        refreshToken: localStorage.getItem("refreshToken") ?? null,
    },
    reducers:{
        login: (state, action) => {
          state.user = action.payload;
        },
        logout:(state) => {
          state.user = null;
          localStorage.removeItem("user");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
        },
        setUser:(state, action)=>{
          state.user = action.payload;
          localStorage.setItem('user', JSON.stringify(action.payload));
        },
        updateUser:(state, action) => {
          state.user = action.payload;
          localStorage.setItem('user', JSON.stringify(state.payload));    
        },
        setUserTokens: (state, action) => {
          state.accessToken = action.payload.accessToken;
          state.refreshToken = action.payload.refreshToken;
          localStorage.setItem("accessToken", action.payload.accessToken);
          localStorage.setItem("refreshToken", action.payload.refreshToken);
        },
        setAccessToken: (state, action) => {
          state.accessToken = action.payload;
          localStorage.setItem("accessToken", action.payload);
        },
        setRefreshToken: (state, action) => {
          state.refreshToken = action.payload;
          localStorage.setItem("refreshToken", action.payload);
        },
    }
})

export const {login, logout, setUserTokens, setAccessToken, setUser, updateUser} = userSlice.actions;

export const selectUser = (state) => state?.user?.user;

export default userSlice.reducer;

