import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { FormErrorMessage } from "components/forms/FormErrorMessage";
import { initialValues, RegisterSchema } from "../../../modules/schemas";
import { register } from "../../../services/authService";
import { toast } from "react-toastify";
import Logo from "../../assets/fullLogo.svg";
import SubmitButton from "components/forms/SubmitButton";
import { useNavigate } from "react-router-dom";
import PhoneControl from "./PhoneControl";
import CountryControl from "./CountryControl";
import ButtonGroup from "./ExpandableButton";
import MediaQuery from "components/media-query/MediaQuery";

const Register = () => {
	const navigate = useNavigate();

	const [passwordType, setPasswordType] = useState(true);
	const [confirmPasswordType, setConfirmPasswordType] = useState(true);
	const [isAgreed, setIsAgreed] = useState(false);
	const togglePassword = (e) => {
		e.preventDefault();
		setPasswordType(!passwordType);
	};
	const toggleConfirmPassword = (e) => {
		e.preventDefault();
		setConfirmPasswordType(!confirmPasswordType);
	};

	const handleRegisterSubmit = async (values) => {
		try {
			const { message } = await register(values);
			toast(`${message}`, {
				type: "success",
				autoClose: 3000,
			});
			navigate("/registerconfirm", { state: { email: values.email } });
		} catch (e) {
			toast(`${e?.message}`, {
				type: "error",
				autoClose: 3000,
			});
		}
	};

	return (
		<>
			{/* small screen start */}
			<MediaQuery min="sm" max="md">
				<div className="w-full">
					<div className="w-[150px] h-[88px]">
						<img src={Logo} alt="logo" className="w-full h-full object-fit" />
					</div>
					<div className="text-[15px] font-light text-bl grid justify-end text-[] mb-[5px]">
						<Link to="/login" className="text-xs">
							Already a member?<span className="text-eb-primary"> Sign In</span>
						</Link>
					</div>

					<div className="text-eb-primary text-lg font-bold mb-[10px]">
						Create an account
					</div>

					<div>
						<ButtonGroup />
					</div>

					<Formik
						initialValues={initialValues}
						validationSchema={RegisterSchema}
						onSubmit={handleRegisterSubmit}
					>
						<Form>
							<div className="max-h-[75vh]  bg-eb-secondary mt-[14px] pl-[62px] pr-[62px] pt-[20px] font-normal text-sm text-eb-primary rounded-lg mb-7 pb-[48px]">
								<div className="grid grid-cols-2 gap-4 mb-[24px] ">
									<div>
										<div className="text-xs">First Name</div>

										<div>
											<Field
												name="firstName"
												type="text"
												className="h-[38px] w-[100%] rounded-md border border-eb-primary mt-3 pl-3"
												placeholder="Ebuka"
											/>{" "}
											<div>
												<FormErrorMessage name={"firstName"} />
											</div>
										</div>
									</div>
									<div>
										<div className="text-xs">Last Name</div>
										<div>
											<Field
												name="lastName"
												type="text"
												className="h-[38px] w-[100%] rounded-md border border-eb-primary mt-3 pl-3"
												placeholder="Eze"
											/>{" "}
											<div>
												<FormErrorMessage name={"lastName"} />
											</div>
										</div>
									</div>
								</div>

								<div className="mb-[24px]">
									<div className="text-xs">Email Address</div>
									<div>
										{" "}
										<Field
											name="email"
											type="email"
											className="h-[38px] w-[100%] rounded-md border border-eb-primary mt-3 pl-3"
											placeholder="Your Email"
										/>{" "}
										<div>
											<FormErrorMessage name={"email"} />
										</div>
									</div>
								</div>

								<div className="mb-[24px] grid md:grid-cols-2 gap-10 ">
									<div className=" ">
										<div className="text-xs">Password</div>
										<div>
											<Field
												className="absolute w-[65%] h-[38px] md:w-[14vw] rounded-md border border-eb-primary mt-3 pl-3 bg-white grid justify-end content-center pr-[12px]"
												name="password"
												type={passwordType ? "password" : "text"}
											/>
											<div className="relative left-[85%] top-5 bg-white w-[20px] ">
												<button onClick={togglePassword}>
													{passwordType ? (
														<svg
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 24 24"
															width="18"
															height="18"
														>
															<path fill="none" d="M0 0h24v24H0z" />
															<path
																d="M12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9C2.121 6.88 6.608 3 12 3zm0 16a9.005 9.005 0 0 0 8.777-7 9.005 9.005 0 0 0-17.554 0A9.005 9.005 0 0 0 12 19zm0-2.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm0-2a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
																fill="rgba(63,63,149,1)"
															/>
														</svg>
													) : (
														<svg
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 24 24"
															width="18"
															height="18"
														>
															<path fill="none" d="M0 0h24v24H0z" />
															<path
																d="M17.882 19.297A10.949 10.949 0 0 1 12 21c-5.392 0-9.878-3.88-10.819-9a10.982 10.982 0 0 1 3.34-6.066L1.392 2.808l1.415-1.415 19.799 19.8-1.415 1.414-3.31-3.31zM5.935 7.35A8.965 8.965 0 0 0 3.223 12a9.005 9.005 0 0 0 13.201 5.838l-2.028-2.028A4.5 4.5 0 0 1 8.19 9.604L5.935 7.35zm6.979 6.978l-3.242-3.242a2.5 2.5 0 0 0 3.241 3.241zm7.893 2.264l-1.431-1.43A8.935 8.935 0 0 0 20.777 12 9.005 9.005 0 0 0 9.552 5.338L7.974 3.76C9.221 3.27 10.58 3 12 3c5.392 0 9.878 3.88 10.819 9a10.947 10.947 0 0 1-2.012 4.592zm-9.084-9.084a4.5 4.5 0 0 1 4.769 4.769l-4.77-4.769z"
																fill="rgba(63,63,149,1)"
															/>
														</svg>
													)}
												</button>
											</div>
										</div>
										<div className="relative top-6">
											<FormErrorMessage name={"password"} />
										</div>
									</div>
									<div>
										<div className="">
											<div className="text-xs mt-[30px] md:mt-0">Confirm Password</div>

											<div>
												<div>
													<Field
														className="absolute h-[38px] w-[65%] md:w-[14vw] rounded-md border border-eb-primary mt-3 pl-3 bg-white grid justify-end content-center pr-[12px]"
														name="confirm_password"
														type={confirmPasswordType ? "password" : "text"}
													/>
													<div className="relative left-[85%] top-5 bg-white w-[20px] ">
														<button onClick={toggleConfirmPassword}>
															{confirmPasswordType ? (
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	viewBox="0 0 24 24"
																	width="18"
																	height="18"
																>
																	<path fill="none" d="M0 0h24v24H0z" />
																	<path
																		d="M12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9C2.121 6.88 6.608 3 12 3zm0 16a9.005 9.005 0 0 0 8.777-7 9.005 9.005 0 0 0-17.554 0A9.005 9.005 0 0 0 12 19zm0-2.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm0-2a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
																		fill="rgba(63,63,149,1)"
																	/>
																</svg>
															) : (
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	viewBox="0 0 24 24"
																	width="18"
																	height="18"
																>
																	<path fill="none" d="M0 0h24v24H0z" />
																	<path
																		d="M17.882 19.297A10.949 10.949 0 0 1 12 21c-5.392 0-9.878-3.88-10.819-9a10.982 10.982 0 0 1 3.34-6.066L1.392 2.808l1.415-1.415 19.799 19.8-1.415 1.414-3.31-3.31zM5.935 7.35A8.965 8.965 0 0 0 3.223 12a9.005 9.005 0 0 0 13.201 5.838l-2.028-2.028A4.5 4.5 0 0 1 8.19 9.604L5.935 7.35zm6.979 6.978l-3.242-3.242a2.5 2.5 0 0 0 3.241 3.241zm7.893 2.264l-1.431-1.43A8.935 8.935 0 0 0 20.777 12 9.005 9.005 0 0 0 9.552 5.338L7.974 3.76C9.221 3.27 10.58 3 12 3c5.392 0 9.878 3.88 10.819 9a10.947 10.947 0 0 1-2.012 4.592zm-9.084-9.084a4.5 4.5 0 0 1 4.769 4.769l-4.77-4.769z"
																		fill="rgba(63,63,149,1)"
																	/>
																</svg>
															)}
														</button>
													</div>
												</div>
											</div>
											<div className=" relative top-6">
												<FormErrorMessage name={"confirm_password"} />
											</div>
										</div>
									</div>
								</div>

								<div className="grid grid-cols-2 gap-4 mt-[44px]">
									<div className="mb-[24px]">
										<div className="text-xs">Phone Number</div>
										<PhoneControl name={"phone"} />
									</div>

									<div className="">
										<div className="text-xs">Country or Region</div>
										<CountryControl name={"country"} />
									</div>
								</div>

								<div className="flex gap-4 mb-4">
									<input
										type="checkbox"
										className="rounded-lg"
										onChange={() => {}}
										checked
									/>
									<p className="text-xs">
										By registering, you agree with our Terms, Privacy and Policy.
									</p>
								</div>

								<SubmitButton className="h-[48px] w-full bg-eb-primary hover:bg-black rounded-lg text-white font-bold grid justify-center content-center ">
									Create Account
								</SubmitButton>
							</div>
						</Form>
					</Formik>
				</div>
			</MediaQuery>
			{/* small screen end */}

			{/* large screen start */}
			<MediaQuery min="md">
				<div className="w-full">
					<div className="text-[15px] font-light text-bl grid justify-end text-[] mb-[5px]">
						<Link to="/login" className="text-xs">
							Already a member?<span className="text-eb-primary"> Sign In</span>
						</Link>
					</div>

					<div className="text-eb-primary text-lg font-bold mb-[10px]">
						Create an account
					</div>

					<div>
						<ButtonGroup />
					</div>

					<Formik
						initialValues={initialValues}
						validationSchema={RegisterSchema}
						onSubmit={handleRegisterSubmit}
					>
						<Form>
							<div className="max-h-[75vh]  bg-eb-secondary mt-[14px] pl-[62px] pr-[62px] pt-[20px] font-normal text-sm text-eb-primary rounded-lg mb-7 pb-[48px]">
								<div className="grid grid-cols-2 gap-4 mb-[24px] ">
									<div>
										<div className="text-xs">First Name</div>

										<div>
											<Field
												name="firstName"
												type="text"
												className="h-[38px] w-[100%] rounded-md border border-eb-primary mt-3 pl-3"
												placeholder="Ebuka"
											/>{" "}
											<div>
												<FormErrorMessage name={"firstName"} />
											</div>
										</div>
									</div>
									<div>
										<div className="text-xs">Last Name</div>
										<div>
											<Field
												name="lastName"
												type="text"
												className="h-[38px] w-[100%] rounded-md border border-eb-primary mt-3 pl-3"
												placeholder="Eze"
											/>{" "}
											<div>
												<FormErrorMessage name={"lastName"} />
											</div>
										</div>
									</div>
								</div>

								<div className="mb-[24px]">
									<div className="text-xs">Email Address</div>
									<div>
										{" "}
										<Field
											name="email"
											type="email"
											className="h-[38px] w-[100%] rounded-md border border-eb-primary mt-3 pl-3"
											placeholder="Your Email"
										/>{" "}
										<div>
											<FormErrorMessage name={"email"} />
										</div>
									</div>
								</div>

								<div className="mb-[24px] grid md:grid-cols-2 gap-10 ">
									<div className=" ">
										<div className="text-xs">Password</div>
										<div>
											<Field
												className="absolute w-[65%] h-[38px] md:w-[14vw] rounded-md border border-eb-primary mt-3 pl-3 bg-white grid justify-end content-center pr-[12px]"
												name="password"
												type={passwordType ? "password" : "text"}
											/>
											<div className="relative left-[85%] top-5 bg-white w-[20px] ">
												<button onClick={togglePassword}>
													{passwordType ? (
														<svg
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 24 24"
															width="18"
															height="18"
														>
															<path fill="none" d="M0 0h24v24H0z" />
															<path
																d="M12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9C2.121 6.88 6.608 3 12 3zm0 16a9.005 9.005 0 0 0 8.777-7 9.005 9.005 0 0 0-17.554 0A9.005 9.005 0 0 0 12 19zm0-2.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm0-2a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
																fill="rgba(63,63,149,1)"
															/>
														</svg>
													) : (
														<svg
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 24 24"
															width="18"
															height="18"
														>
															<path fill="none" d="M0 0h24v24H0z" />
															<path
																d="M17.882 19.297A10.949 10.949 0 0 1 12 21c-5.392 0-9.878-3.88-10.819-9a10.982 10.982 0 0 1 3.34-6.066L1.392 2.808l1.415-1.415 19.799 19.8-1.415 1.414-3.31-3.31zM5.935 7.35A8.965 8.965 0 0 0 3.223 12a9.005 9.005 0 0 0 13.201 5.838l-2.028-2.028A4.5 4.5 0 0 1 8.19 9.604L5.935 7.35zm6.979 6.978l-3.242-3.242a2.5 2.5 0 0 0 3.241 3.241zm7.893 2.264l-1.431-1.43A8.935 8.935 0 0 0 20.777 12 9.005 9.005 0 0 0 9.552 5.338L7.974 3.76C9.221 3.27 10.58 3 12 3c5.392 0 9.878 3.88 10.819 9a10.947 10.947 0 0 1-2.012 4.592zm-9.084-9.084a4.5 4.5 0 0 1 4.769 4.769l-4.77-4.769z"
																fill="rgba(63,63,149,1)"
															/>
														</svg>
													)}
												</button>
											</div>
										</div>
										<div className="relative top-6">
											<FormErrorMessage name={"password"} />
										</div>
									</div>
									<div>
										<div className="">
											<div className="text-xs mt-[30px] md:mt-0">Confirm Password</div>

											<div>
												<div>
													<Field
														className="absolute h-[38px] w-[65%] md:w-[14vw] rounded-md border border-eb-primary mt-3 pl-3 bg-white grid justify-end content-center pr-[12px]"
														name="confirm_password"
														type={confirmPasswordType ? "password" : "text"}
													/>
													<div className="relative left-[85%] top-5 bg-white w-[20px] ">
														<button onClick={toggleConfirmPassword}>
															{confirmPasswordType ? (
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	viewBox="0 0 24 24"
																	width="18"
																	height="18"
																>
																	<path fill="none" d="M0 0h24v24H0z" />
																	<path
																		d="M12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9C2.121 6.88 6.608 3 12 3zm0 16a9.005 9.005 0 0 0 8.777-7 9.005 9.005 0 0 0-17.554 0A9.005 9.005 0 0 0 12 19zm0-2.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm0-2a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
																		fill="rgba(63,63,149,1)"
																	/>
																</svg>
															) : (
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	viewBox="0 0 24 24"
																	width="18"
																	height="18"
																>
																	<path fill="none" d="M0 0h24v24H0z" />
																	<path
																		d="M17.882 19.297A10.949 10.949 0 0 1 12 21c-5.392 0-9.878-3.88-10.819-9a10.982 10.982 0 0 1 3.34-6.066L1.392 2.808l1.415-1.415 19.799 19.8-1.415 1.414-3.31-3.31zM5.935 7.35A8.965 8.965 0 0 0 3.223 12a9.005 9.005 0 0 0 13.201 5.838l-2.028-2.028A4.5 4.5 0 0 1 8.19 9.604L5.935 7.35zm6.979 6.978l-3.242-3.242a2.5 2.5 0 0 0 3.241 3.241zm7.893 2.264l-1.431-1.43A8.935 8.935 0 0 0 20.777 12 9.005 9.005 0 0 0 9.552 5.338L7.974 3.76C9.221 3.27 10.58 3 12 3c5.392 0 9.878 3.88 10.819 9a10.947 10.947 0 0 1-2.012 4.592zm-9.084-9.084a4.5 4.5 0 0 1 4.769 4.769l-4.77-4.769z"
																		fill="rgba(63,63,149,1)"
																	/>
																</svg>
															)}
														</button>
													</div>
												</div>
											</div>
											<div className=" relative top-6">
												<FormErrorMessage name={"confirm_password"} />
											</div>
										</div>
									</div>
								</div>

								<div className="grid grid-cols-2 gap-4 mt-[44px]">
									<div className="mb-[24px]">
										<div className="text-xs">Phone Number</div>
										<PhoneControl name={"phone"} />
									</div>

									<div className="">
										<div className="text-xs">Country or Region</div>
										<CountryControl name={"country"} />
									</div>
								</div>

								<div className="flex gap-4 mb-4">
									<input
										type="checkbox"
										className="rounded-lg"
										onChange={() => {
											setIsAgreed(!isAgreed);
										}}
										checked={isAgreed}
									/>
									<p className="text-xs">
										By registering, you agree with our Terms, Privacy and Policy.
									</p>
								</div>

								<SubmitButton className="h-[48px] w-full bg-eb-primary hover:bg-black rounded-lg text-white font-bold grid justify-center content-center ">
									Create Account
								</SubmitButton>
							</div>
						</Form>
					</Formik>
				</div>
			</MediaQuery>
			{/* large screen end */}
		</>
	);
};

export default Register;
