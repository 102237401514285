import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { axiosErrorHandler } from "../../utils/axiosErrorHandler";
import { baseURL } from "constants/url";

export const fetchAllBrands = createAsyncThunk(
    "brands/fetchAllBrands",
    async () => {
        try {
            const response = await axios.get(`${baseURL}/brand/all`);
            const { data } = response.data;
            return data;
            } catch (error) {
            return axiosErrorHandler(error);
        }
    }
);

const brandSlice = createSlice({
    name: "brands",
    initialState: {
        isLoading: false,
        brands: [],
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllBrands.pending, (state, action) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(fetchAllBrands.fulfilled, (state, action) => {
            state.isLoading = false;
            state.brands = action.payload;
            state.error = null;
        });
        builder.addCase(fetchAllBrands.rejected, (state, action) => {
            state.isLoading = false;
            state.brands = [];
            state.error = action.error.message;
        });
    }

});

export default brandSlice.reducer;