import httpService from "./httpService";

export async function getAllProducts() {
	try {
		const response = await httpService.get("/product/fetch/all");
		return response;
	} catch (error) {
		throw new Error(error.message);
	}
}

export async function getAllPopularProducts() {
	try {
		const response = await httpService.get("/product/fetch/all/popular");
		return response;
	} catch (error) {
		throw new Error(error.message);
	}
}

export async function getAllHotProducts() {
	try {
		const response = await httpService.get("/product/fetch/all/hot-sale");
		return response;
	} catch (error) {
		throw new Error(error.message);
	}
}

export async function getProductsByKeywords(word) {
	try {
		let url = "/product/fetch/all";
		if (word) url += `?keyword=${word}`;

		const response = await httpService.get(url);
		return response;
	} catch (error) {
		throw new Error(error.message);
	}
}

export async function postProductReview(payload) {
	try {
		let url = "/product/create/review";
		const res = await httpService.post(url, payload);
		return res;
	} catch (error) {
		throw new Error(error.message);
	}
}

export async function deleteProductReview(id) {
	try {
		let url = `/product/review/${id}}`;
		const res = await httpService.delete(url);
		return res;
	} catch (error) {
		throw new Error(error.message);
	}
}

export async function getProductReviews(id) {
	try {
		let url = `/product/reviews/${id}`;
		const res = await httpService.get(url);
		return res;
	} catch (error) {
		throw new Error(error.message);
	}
}

export async function viewProductDetails(id) {
	try {
		let url = `/product/${id}`;
		const res = await httpService.get(url);
		return res;
	} catch (error) {
		throw new Error(error.message);
	}
}
