import React, { useState, useCallback, useEffect } from "react";
import Skin1 from "../assets/skin1.svg";
import Skin2 from "../assets/skin2.svg";
import Skin3 from "../assets/skin3.svg";
import Skin4 from "../assets/skin4.svg";
import { Link } from "react-router-dom";
import { getAllBlogs } from "services/blogService";
import Loader from "components/loader/Loader";

const post = [
	{
		id: "1",
		picture: Skin1,
		title: "Skin Detoxification",
		content:
			"Taking care of one of the major detoxification organs on our bodies, our skin, is just as imperative as the care we show the delicate skin on our face like the heart, stomach, and brain, your skin is an organ",
	},
	{
		id: "2",
		picture: Skin2,
		title: "Skin Detoxification",
		content:
			"Taking care of one of the major detoxification organs on our bodies, our skin, is just as imperative as the care we show the delicate skin on our face like the heart, stomach, and brain, your skin is an organ",
	},
	{
		id: "3",
		picture: Skin3,
		title: "Skin Detoxification",
		content:
			"Taking care of one of the major detoxification organs on our bodies, our skin, is just as imperative as the care we show the delicate skin on our face like the heart, stomach, and brain, your skin is an organ",
	},
	{
		id: "4",
		picture: Skin4,
		title: "Skin Detoxification",
		content:
			"Taking care of one of the major detoxification organs on our bodies, our skin, is just as imperative as the care we show the delicate skin on our face like the heart, stomach, and brain, your skin is an organ",
	},
];

const BlogPost = () => {
	const [blogs, setBlogs] = useState([]);
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(true);

	const fetchData = useCallback(async () => {
		try {
			const response = await getAllBlogs();
			const { data } = response;
			setBlogs(data);
			setLoading(false);
		} catch (err) {
			console.error("Error fetching blogs", err);
			setError("Something went wrong. Please try again later.");
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	function truncateWords(content, limit) {
		if (!content) return ""; // Return empty string if content is falsy
		const words = content.split(" ");
		if (words.length > limit) {
			return words.slice(0, limit).join(" ") + "...";
		} else {
			return content;
		}
	}

	const actualPost = () => {
		return (
			<div>
				{blogs.map(({ image, title, content }, index) => {
					return (
						<div key={blogs._id}>
							{index % 2 === 0 ? (
								<div className="grid md:grid-cols-2 p-4">
									<div className="grid content-center ">
										<p className="text-eb-primary text-[25px]"> {title}</p>

										<div>
											<p>
												{" "}
												<p className="text-[20px] md:w-[450px] mt-4">
													{truncateWords(content, 20)}{" "}
													<Link to={`/bog/${blogs._id}`} className="text-eb-primary">
														{" "}
														Read More
													</Link>{" "}
												</p>{" "}
											</p>

											<p> </p>
										</div>
									</div>

									<div className="grid justify-end ">
										<img
											className="h-[320px] md:w-[500px] object-fit"
											src={image.url}
											alt={title}
										/>
									</div>
								</div>
							) : (
								<div className="grid md:grid-cols-2 p-4" key={blogs._id}>
									<div>
										<img
											className="h-[320px] md:w-[500px] object-fit"
											src={image.url}
											alt={title}
										/>
									</div>

									<div className="grid content-center justify-end ">
										<p className="text-eb-primary text-[25px]"> {title}</p>

										<div>
											<p>
												{" "}
												<p className="text-[20px] md:w-[450px] mt-4">
													{truncateWords(content, 20)}{" "}
													<Link to={`/bog/${blogs._id}`} className="text-eb-primary">
														Read More
													</Link>{" "}
												</p>{" "}
											</p>

											<p> </p>
										</div>
									</div>
								</div>
							)}
						</div>
					);
				})}
			</div>
		);
	};

	const placeholderPost = () => {
		return (
			<div>
				{post.map(({ picture, title, content }, index) => {
					return (
						<div key={post.id}>
							{index % 2 === 0 ? (
								<div className="grid md:grid-cols-2 p-4">
									<div className="grid content-center ">
										<p className="text-eb-primary text-[25px]"> {title}</p>

										<div>
											<p>
												{" "}
												<p className="text-[20px] md:w-[450px] mt-4">
													{content}{" "}
													<Link to={`/bog/1`} className="text-eb-primary">
														{" "}
														...Read More
													</Link>{" "}
												</p>{" "}
											</p>

											<p> </p>
										</div>
									</div>

									<div className="grid justify-end ">
										<img
											className="h-[320px] md:w-[500px] object-fit"
											src={picture}
											alt={title}
										/>
									</div>
								</div>
							) : (
								<div className="grid md:grid-cols-2 p-4" key={post.id}>
									<div>
										<img
											className="h-[320px] md:w-[500px] object-fit"
											src={picture}
											alt={title}
										/>
									</div>

									<div className="grid content-center justify-end ">
										<p className="text-eb-primary text-[25px]"> {title}</p>

										<div>
											<p>
												{" "}
												<p className="text-[20px] md:w-[450px] mt-4">
													{content}{" "}
													<Link to={`/bog/1`} className="text-eb-primary">
														{" "}
														...Read More
													</Link>{" "}
												</p>{" "}
											</p>

											<p> </p>
										</div>
									</div>
								</div>
							)}
						</div>
					);
				})}
			</div>
		);
	};

	if (loading) {
		return <Loader />;
	}
	if (error) {
		return <div style={{ textAlign: "center", fontWeight: "bold" }}>{error}</div>;
	}
	return <>{blogs.length > 0 ? actualPost() : placeholderPost()}</>;
};

export default BlogPost;
