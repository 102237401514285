import React, { useState } from "react";
import Mail from "../../assets/mail.svg";
import { Link } from "react-router-dom";
import ArrowLeft from "../../assets/arrow-left.svg";
import AuthWrapper from "../AuthWrapper";
import { useLocation } from "react-router-dom";
import Popup from "reactjs-popup";
import OtpScreen from "./Otp";
import { resendOTP } from "services/authService"; 
import { toast } from "react-toastify";
// import Spinner from "components/forms/Spinner";

const ForgotTwo = () => {
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const [isResending, setIsResending] = useState(false);

  const handleResendOTP = async () => {
    if (isResending) return;
    try {
      setIsResending(true);
      const { message } = await resendOTP(location.state.email);
      setIsResending(false);
      toast(`${message}`, {
        type: "success",
        autoClose: 3000,
      });
      if (!open) {
        setOpen(true);
      }
    } catch (e) {
      toast(`${e.message}`, {
        type: "error",
        autoClose: 3000,
      });
    }
  };

  return (
    <AuthWrapper>
      <div className="h-[85vh] grid content-center ">
        <div className="bg-eb-secondary h-[75vh] rounded-lg pt-5 pb-5">
          <div className="grid justify-center ">
            <img src={Mail} alt="envelop" />
          </div>
          <div className="text-[25px] font-medium text-eb-primary grid justify-center mt-[24px]">
            Check your mail
          </div>
          <div className="font-light text-[18px] grid justify-center mt-[24px]">
            <div>We sent a password reset link to </div>
          </div>
          <div className="font-light text-[18px] grid justify-center">
            <div className="text-eb-primary">{location.state.email}</div>
          </div>
          {/* <div className="grid justify-center mt-[24px]">
              <div className="bg-eb-primary text-white h-[48px] w-[164px] grid justify-center content-center rounded-lg">
                <p>Open email app</p>
              </div>
            </div> */}
          {/* <div>
            <div className="font-light text-[18px] grid justify-center content-end mt-[24px]">
              <div>Didn’t receive an email?</div>
            </div>
            <div className="font-light text-[18px] grid justify-center">
              <div
                onClick={() => handleResendOTP()}
                className="text-eb-primary flex items-center space-x-4"
              >
                Click to resend 
                {
                  isResending && <Spinner />
                }
                
              </div>
            </div>



          </div> */}

          <div>
            {/* <div className="font-light text-[18px] grid justify-center content-end mt-[24px]">
              <div>Or </div>
            </div> */}

            <Popup
              open={open}
              onClose={() => setOpen(false)}
              onOpen={() => setOpen(true)}
              trigger={
                <div className="grid justify-center mt-6">
                  <button className="font-medium text-[15px] grid justify-center content-center text-white bg-eb-primary rounded-md w-[164px] h-12">
                    Input OTP
                  </button>
                </div>
              }
              overlayStyle={{
                background: "rgba(0,0,0,0.45)",
              }}
              contentStyle={{
                overflowY: "auto",
                maxHeight: "calc(100vh - 100px)",
              }}
              modal
              closeOnDocumentClick={false}
            >
              <div className="flex justify-center">
                <div className="w-[90vw] md:w-[100%] bg-white self-center p-[23px] rounded-lg ">
                  <OtpScreen
                    email={location.state.email}
                    onResend={handleResendOTP}
                    isResending={isResending}
                    setOpen={setOpen}
                  />
                </div>
              </div>
            </Popup>
          </div>

          <div className="font-light text-[15px] grid justify-center mt-10">
            <button className="text-eb-primary">
              <Link to="/login" className="flex gap-1">
                {" "}
                <img src={ArrowLeft} alt="arrow" /> Back to Sign In
              </Link>
            </button>
          </div>
        </div>
      </div>
    </AuthWrapper>
  );
};

export default ForgotTwo;
