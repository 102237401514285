import React from "react";
import { Link } from "react-router-dom";
import Lock from "../../assets/filled.svg";
import Arrow from "../../assets/arrow.svg";
import AuthWrapper from "../AuthWrapper";

const ResetSuccess = () => {
  return (
    <AuthWrapper>
       <div>
      <div className="  ">

        <div className="h-[85vh] grid content-center ">
          <div className="bg-eb-secondary h-[70vh] rounded-lg pt-5 pb-5">
            <div className="grid justify-center ">
              <img src={Lock} alt="envelop" />
            </div>
            <div className="text-[25px] font-medium text-eb-primary grid justify-center mt-[24px]">
              Password reset
            </div>
            <div className="font-light text-[18px] grid justify-center mt-[24px] text-eb-primary">
              <div>Your password has been successfully reset.</div>
            </div>
            <div className="font-light text-[18px] grid justify-center">
              <div className="text-eb-primary">Click below to log in.</div>
            </div>

            <div className="grid justify-center mt-[24px] ml-[62px] mr-[62px] ">
              <Link to="/login">
              <button className="bg-eb-primary hover:bg-black text-white text-[16px] h-[48px] w-[164px] grid justify-center content-center rounded-lg">
                Continue
              </button>
              </Link>
            </div>

            <div className="font-light text-[18px] grid justify-center mt-10">
              <div className="text-eb-primary">
                <Link to="/" className="flex gap-1"> Proceed to Home
                 <img src={Arrow} alt="arrow"/>
                 </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </AuthWrapper>
   
  );
};

export default ResetSuccess;
