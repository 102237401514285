import * as Yup from 'yup';

export const initialChangePasswordValues = {
  oldPassword: '',
  newPassword: '',
};

export const changePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .min(6, 'Password must be at least six (6) characters.')
    .required('Old Password is required.'),
  newPassword: Yup.string()
    .min(6, 'Password must be at least six (6) characters.')
    .required('New Password is required.'),
  
});