export const CurrencyFormatter = (val) => {
	if (!val || isNaN(val)) {
		return "0.00";
	}

	const formatter = new Intl.NumberFormat("en-NG");

	if (Math.abs(val) >= 1e12) {
		return formatter.format(val / 1e12) + "T";
	}
	if (Math.abs(val) >= 1e9) {
		return formatter.format(val / 1e9) + "B";
	}
	// if (Math.abs(val) >= 1e6) {
	// 	return formatter.format(val / 1e6) + "M";
	// }
	return formatter.format(val);
};