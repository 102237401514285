import * as Yup from "yup";

export const initialValues = {
	uniqueIdentifier: "",
	itemName: "",
	quantity: "",
	acceptPolicy: "",
	reasonForReturn: "",
	description: "",
};

export const ROGSchema = Yup.object().shape({
	uniqueIdentifier: Yup.string(),
	itemName: Yup.string(),
	quantity: Yup.string(),
	acceptPolicy: Yup.string(),
	reasonForReturn: Yup.string(),
	description: Yup.string(),
});
