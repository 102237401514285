import React, { useState, useEffect } from "react";
import Footer from "components/footer/Footer";
import Header from "components/home/Header";
import Map from "components/maps/Map";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import httpService from "services/httpService";
import MediaQuery from "components/media-query/MediaQuery";

const Distributors = () => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [location, setLocation] = useState([]);
	const [activeTab, setActiveTab] = useState("Abuja");

	const toggleTab = (tabName) => {
		setActiveTab(tabName);
	};

	useEffect(() => {
		if (location.length === 1) {
			setActiveTab(location[0].state);
		}
	}, [location]);

	const fetchLocations = async () => {
		try {
			setLoading(true);
			const response = await httpService.get(`/location/fetch/all`);
			setLocation(response.locations);
			setLoading(false);
		} catch (error) {
			console.error("Error fetching location:", error);
			setError("Failed to fetch location");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchLocations();
	}, []);

	return (
		<div className="min-h-screen flex flex-col">
			<div className="flex-grow">
				<Header />
				{/* small screen start */}
				<MediaQuery min="sm" max="md">
					<div className="px-4 py-8">
						<h3 className="text-lg py-5 text-[#3F3F95] font-semibold text-center">
							Nationwide Distributors
						</h3>
						{location.length === 0 ? (
							<div className="p-4 bg-white shadow-md rounded-md text-center">
								<p className="flex justify-center items-center">
									No distributors for now
								</p>
							</div>
						) : (
							<div className="w-full">
								{location
									.sort((a, b) => a.state.localeCompare(b.state))
									.map((lctn, index) => (
										<Accordion key={index}>
											<AccordionSummary expandIcon={<ExpandMoreIcon />}>
												{lctn.state}
											</AccordionSummary>
											<AccordionDetails>
												<div className="p-4 bg-white shadow-md rounded-md">
													<div className="grid grid-cols-1 gap-2 my-4">
														<div className="my-2">
															<p className="text-sm font-bold">State</p>
															<p className="text-base text-[#656565]">{lctn.state}</p>
														</div>
														<div className="my-2">
															<p className="text-sm font-bold">City & Shipping Price</p>
															{lctn?.cities?.map((city, index) => (
																<p key={index} className="text-base text-[#656565]">
																	{city?.name} - ₦{city?.shipping_price}
																</p>
															))}
														</div>
													</div>
													<div>
														{lctn?.cities?.map((city) =>
															city.distributors?.map((distributor, index) => (
																<div key={index} className="my-3">
																	<div className="grid grid-cols-1 gap-2 my-4">
																		<div className="my-2">
																			<p className="text-sm font-bold">Distributor Name</p>
																			<p className="text-base text-[#656565]">
																				{distributor?.distributorInformation?.firstName} &nbsp;
																				{distributor?.distributorInformation?.lastName}
																			</p>
																		</div>
																		<div className="my-2">
																			<p className="text-sm font-bold">Distributor Contact</p>
																			<p className="text-base text-[#656565]">
																				{distributor?.phone[0]?.phoneNumber}, &nbsp;{" "}
																				{distributor?.phone[1]?.phoneNumber}
																			</p>
																			<p className="text-base text-[#656565]">
																				{distributor?.email[0]?.emailAddress}, &nbsp;{" "}
																				{distributor?.email[1]?.emailAddress}
																			</p>
																		</div>
																	</div>
																	<div className="grid grid-cols-1 gap-2 my-4">
																		<div className="my-2">
																			<p className="text-sm font-bold">Business Name</p>
																			<p className="text-base text-[#656565]">
																				{distributor?.distributorInformation?.business?.name}
																			</p>
																		</div>
																		<div className="my-2">
																			<p className="text-sm font-bold">Business Address</p>
																			<p className="text-base text-[#656565]">
																				{distributor?.distributorInformation?.business?.address}
																			</p>
																		</div>
																	</div>
																</div>
															)),
														)}
													</div>
												</div>
											</AccordionDetails>
										</Accordion>
									))}
							</div>
						)}
					</div>
				</MediaQuery>
				{/* small screen end */}

				{/* medium screen start */}
				<MediaQuery min="md" max="lg">
					<div className="px-8 py-8">
						<h3 className="text-2xl py-5 text-[#3F3F95] font-semibold text-center">
							Nationwide Distributors
						</h3>
						{location.length === 0 ? (
							<div className="p-8 bg-white shadow-md rounded-md text-center">
								<p className="flex justify-center items-center">
									No distributors for now
								</p>
							</div>
						) : (
							<div className="w-full">
								{location
									.sort((a, b) => a.state.localeCompare(b.state))
									.map((lctn, index) => (
										<Accordion key={index}>
											<AccordionSummary expandIcon={<ExpandMoreIcon />}>
												{lctn.state}
											</AccordionSummary>
											<AccordionDetails>
												<div className="p-8 bg-white shadow-md rounded-md">
													<div className="grid grid-cols-2 gap-2 my-4">
														<div className="my-2">
															<p className="text-sm font-bold">State</p>
															<p className="text-base text-[#656565]">{lctn.state}</p>
														</div>
														<div className="my-2">
															<p className="text-sm font-bold">City & Shipping Price</p>
															{lctn?.cities?.map((city, index) => (
																<p key={index} className="text-base text-[#656565]">
																	{city.name} - ₦{city.shipping_price}
																</p>
															))}
														</div>
													</div>
													<div>
														{lctn?.cities?.map((city) =>
															city.distributors?.map((distributor, index) => (
																<div key={index} className="my-3">
																	<div className="grid grid-cols-2 gap-2 my-4">
																		<div className="my-2">
																			<p className="text-sm font-bold">Distributor Name</p>
																			<p className="text-base text-[#656565]">
																				{distributor?.distributorInformation?.firstName} &nbsp;
																				{distributor?.distributorInformation?.lastName}
																			</p>
																		</div>
																		<div className="my-2">
																			<p className="text-sm font-bold">Distributor Contact</p>
																			<p className="text-base text-[#656565]">
																				{distributor?.phone[0]?.phoneNumber}, &nbsp;{" "}
																				{distributor?.phone[1]?.phoneNumber}
																			</p>
																			<p className="text-base text-[#656565]">
																				{distributor?.email[0]?.emailAddress}, &nbsp;{" "}
																				{distributor?.email[1]?.emailAddress}
																			</p>
																		</div>
																	</div>
																	<div className="grid grid-cols-2 gap-2 my-4">
																		<div className="my-2">
																			<p className="text-sm font-bold">Business Name</p>
																			<p className="text-base text-[#656565]">
																				{distributor?.distributorInformation?.business?.name}
																			</p>
																		</div>
																		<div className="my-2">
																			<p className="text-sm font-bold">Business Address</p>
																			<p className="text-base text-[#656565]">
																				{distributor?.distributorInformation?.business?.address}
																			</p>
																		</div>
																	</div>
																</div>
															)),
														)}
													</div>
												</div>
											</AccordionDetails>
										</Accordion>
									))}
							</div>
						)}
					</div>
				</MediaQuery>
				{/* medium screen end */}

				{/* large screen start */}
				<MediaQuery min="lg">
					<div className="px-16 py-8">
						<h3 className="text-3xl py-5 text-[#3F3F95] font-semibold text-center">
							Nationwide Distributors
						</h3>
						{location.length === 0 ? (
							<div className="p-16 bg-white shadow-md rounded-md text-center">
								<p className="flex justify-center items-center">
									No distributors for now
								</p>
							</div>
						) : (
							<div className="w-full">
								{location
									.sort((a, b) => a.state.localeCompare(b.state))
									.map((lctn, index) => (
										<Accordion key={index}>
											<AccordionSummary expandIcon={<ExpandMoreIcon />}>
												{lctn.state}
											</AccordionSummary>
											<AccordionDetails>
												<div className="p-16 bg-white shadow-md rounded-md">
													<div className="grid grid-cols-3 gap-2 my-4">
														<div className="my-2">
															<p className="text-sm font-bold">State</p>
															<p className="text-base text-[#656565]">{lctn.state}</p>
														</div>
														<div className="my-2">
															<p className="text-sm font-bold">City & Shipping Price</p>
															{lctn?.cities?.map((city, index) => (
																<p key={index} className="text-base text-[#656565]">
																	{city.name} - ₦{city.shipping_price}
																</p>
															))}
														</div>
													</div>
													<div>
														{lctn?.cities?.map((city) =>
															city.distributors?.map((distributor, index) => (
																<div key={index} className="my-3">
																	<div className="grid grid-cols-3 gap-2 my-4">
																		<div className="my-2">
																			<p className="text-sm font-bold">Distributor Name</p>
																			<p className="text-base text-[#656565]">
																				{distributor?.distributorInformation?.firstName} &nbsp;
																				{distributor?.distributorInformation?.lastName}
																			</p>
																		</div>
																		<div className="my-2">
																			<p className="text-sm font-bold">Distributor Contact</p>
																			<p className="text-base text-[#656565]">
																				{distributor?.phone[0]?.phoneNumber}, &nbsp;{" "}
																				{distributor?.phone[1]?.phoneNumber}
																			</p>
																			<p className="text-base text-[#656565]">
																				{distributor?.email[0]?.emailAddress}, &nbsp;{" "}
																				{distributor?.email[1]?.emailAddress}
																			</p>
																		</div>
																	</div>
																	<div className="grid grid-cols-3 gap-2 my-4">
																		<div className="my-2">
																			<p className="text-sm font-bold">Business Name</p>
																			<p className="text-base text-[#656565]">
																				{distributor?.distributorInformation?.business?.name}
																			</p>
																		</div>
																		<div className="my-2">
																			<p className="text-sm font-bold">Business Address</p>
																			<p className="text-base text-[#656565]">
																				{distributor?.distributorInformation?.business?.address}
																			</p>
																		</div>
																	</div>
																</div>
															)),
														)}
													</div>
												</div>
											</AccordionDetails>
										</Accordion>
									))}
							</div>
						)}
					</div>
				</MediaQuery>
				{/* large screen end */}
			</div>
			<Footer />
		</div>
	);
};

export default Distributors;
