import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../assets/fullLogo.svg";
import Verified from "../../../assets/verified.svg";

const SuccessScreen = () => {
	return (
		<div className="bg-[#F9FAFB]">
			<div className="h-[70px] overflow-x-hidden pb-2  px-[10px] bg-white shadow grid grid-cols-4 ">
				<div className="grid content-end col-span-1">
					<img src={Logo} alt="logo" width={120} height={120} />
				</div>
			</div>
			<div className="grid h-screen justify-center mt-[10vh]">
				<div className="bg-white max-h-[60vh] rounded-[24px] p-8 justify-center">
					<div className="grid justify-center">
						<img src={Verified} alt="verified" />
					</div>
					<div className="mt-4 text-xl grid justify-center text-[#111827] text-bold">
						Verification Successful!
					</div>
					<div className="mt-2 text-[#718096] text-sm">
						The OTP sent to your mail was verified successfully. <br />
						Thank you.
					</div>
					<div className="flex justify-center mt-8">
						<Link to="/login">
							<button className="bg-eb-primary h-[48px] grid justify-center content-center text-semibold text-white rounded-xl px-5">
								Go to Login Page
							</button>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SuccessScreen;
