import * as Yup from "yup";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/slices/authSlice"




export const useInitialValues = () => {
  const userData = useSelector(selectUser);

  const initialValues = {
    firstName: userData?.firstName || '',
    lastName: userData?.lastName || '',
    phone: userData?.phone || '',
    email: userData?.email || '',
    // address:"",
  }

  return initialValues;
}



export const ProfileSchema = Yup.object().shape({
    firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
    phone: Yup.number()
    .typeError("Only digit(s) is allowed")
    .required("phone number is required"),
    email: Yup.string()
    .email("Invalid email format")
    .trim()
    .required("The email field is required"),
})