import React from 'react';
import AuthWrapper from '../AuthWrapper';
import LoginForm from './Login';

const Login = () => {
  return (
    <AuthWrapper>

      <LoginForm />
    </AuthWrapper> 
  )
}

export default Login;



{/* <div   className='h-screen  overflow-x-hidden  w-screen'> 
        <div className='md:ml-[80px] md:mr-[80px] mt-[10px] h-screen overflow-hidden md:grid md:grid-cols-2 md:gap-2'>
        <div className='hidden  md:h-[90%] md:grid '>
        <CarouselTab />
        </div>
        <div className='h-[80%] w-full  grid content-center'>
         <LoginForm />
        </div>
        </div>
    </div> */}