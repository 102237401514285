import React from "react";
import { Formik, Form, Field } from "formik";
import { createReturnOrd } from "services/myOrderCRUD";
import { FormErrorMessage } from "components/forms/FormErrorMessage";
import { initialValues, ROGSchema } from "./ROG-Schema";
import { toast } from "react-toastify";
import SubmitButton from "components/forms/SubmitButton";
import { useNavigate } from "react-router-dom";
import Header from "components/home/Header";

const ReturnOfGoods = () => {
  const navigate = useNavigate();

  const handleROGFormSubmit = async (values) => {
    try {
      const { message } = await createReturnOrd(values);
      toast(`${message}`, {
        type: "success",
        autoClose: 3000,
      });
      navigate("/return-query");
    } catch (e) {
      toast(`${e?.message}`, {
        type: "error",
        autoClose: 3000,
      });
    }
  };

  const acceptPolicyOptions = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  const reasonForReturnOptions = [
    { value: "Wrong Item", label: "Wrong Item" },
    { value: "Damaged", label: "Damaged" },
    { value: "Defective", label: "Defective" },
    { value: "Mismatched", label: "Mismatched" },
  ];

  return (
    <div>
      <Header />

      <div className="md:m-[40px] md:ml-[80px] m-4">
        <div className="bg-eb-secondary  rounded-tl-[30px] p-7">
          {/* TITLE */}

          <div>
            <p className="text-erp-dark-grayII font-bold text-[18px]">
              Create Goods Return
            </p>
          </div>

          <hr className="my-3" />

          {/* FORM */}

          <Formik
            initialValues={initialValues}
            validationSchema={ROGSchema}
            onSubmit={handleROGFormSubmit}
          >
            <Form>
              <div>
                {/* SHIPMENT TRACKING NUMBER */}

                <div className="mb-5">
                  <p className="text-erp-dark-grayII text-[14px]">
                    Order Identification
                  </p>
                  <Field
                    name="uniqueIdentifier"
                    type="text"
                    className="h-[38px] pl-2 w-[100%] border border-erp-light-gray rounded-lg"
                    placeholder="Insert Barcode number, order ID or tracking number"
                  />
                  <div>
                    <FormErrorMessage name={"uniqueIdentifier"} />
                  </div>
                </div>

                {/* PRODUCT NAME */}
                <div className="mb-5">
                  <p className="text-[14px] ">Item Name</p>
                  <Field
                    name="itemName"
                    type="text"
                    placeholder="Insert Item Name"
                    className="h-[38px] pl-2 w-[100%] border border-erp-light-gray rounded-lg"
                  />
                  <div>
                    <FormErrorMessage name={"itemName"} />
                  </div>
                </div>

                {/* QUANTITY */}
                <div className="mb-5">
                  <p className="text-[14px]">Quantity </p>
                  <Field
                    name="quantity"
                    type="text"
                    placeholder="Quantity"
                    className="h-[38px] pl-2 w-[100%] border border-erp-light-gray rounded-lg"
                  />
                  <div>
                    <FormErrorMessage name={"quantity"} />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  {/* Acceptance Policy */}
                  <div className="mb-5">
                    <p className="text-[14px] ">Acceptance Policy</p>
                    <Field
                      as="select"
                      id="acceptPolicy"
                      name="acceptPolicy"
                      type="text"
                      className="h-[38px] pl-2 w-[100%] border border-erp-light-gray rounded-lg"
                    >
                      <option value="">Select an option</option>
                      {acceptPolicyOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Field>
                    <div>
                      <FormErrorMessage name={"acceptPolicy"} />
                    </div>
                  </div>

                  {/* If eligible for refund, input return authorisation number */}
                  <div className="mb-5">
                    <p className="text-erp-dark-grayII text-[14px]">
                      Reason For Return
                    </p>
                    <Field
                      as="select"
                      id="reasonForReturn"
                      name="reasonForReturn"
                      type="text"
                      className="h-[38px] pl-2 w-[100%] border border-erp-light-gray rounded-lg"
                      placeholder="To include documentation or follow-up instructions needed"
                    >
                      <option value="">Select an option</option>
                      {reasonForReturnOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Field>
                    <div>
                      <FormErrorMessage name={"reasonForReturn"} />
                    </div>
                  </div>
                </div>

                {/* Input instructions on how to return item */}

                <div className="mb-5">
                  <p className="text-erp-dark-grayII text-[14px]">
                    Input instructions on how to return item
                  </p>
                  <Field
                    as="textarea"
                    name="description"
                    row="15"
                    className="h-[100px] pt-2 pl-2 w-[100%] border border-erp-light-gray rounded-lg"
                    placeholder="To include documentation or follow-up instructions needed"
                  />
                  <div>
                    <FormErrorMessage name={"description"} />
                  </div>
                </div>

                <SubmitButton className="flex justify-center text-white text-[14px] bg-eb-primary rounded-lg h-[40px] w-[100%] font-semibold">
                  <p> Initiate Return</p>
                </SubmitButton>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ReturnOfGoods;
