import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/home/Header";
import Footer from "../../components/footer/Footer";

const OAuthErrorPage = () => {
	return (
		<>
			<Header />
			<div className="m-auto w-full text-center my-5 py-5">
				<div className="flex justify-center">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 24 24"
						width="200"
						height="200"
					>
						<path
							fill="#FF3F95"
							d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm-1 17h2v2h-2zm0-10h2v6h-2z"
						/>
					</svg>
					<div className="flex justify-center items-center mt-4">
						<h1 className="text-3xl font-semibold">Error</h1>
						<p className="text-lg">Something went wrong</p>
					</div>
				</div>

				<div className="flex justify-center items-center mt-4">
					<h3 className="text-3xl font-semibold">Error</h3>
					<p className="text-lg">Something went wrong</p>
				</div>
				<Link to="/home" className="text-[#3F3F95] font-semibold">
					<div className="flex justify-center items-center mt-4">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							width="24"
							height="24"
							className="mr-4"
						>
							<path fill="none" d="M0 0h24v24H0z" />
							<path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z" />
						</svg>
						Go back to Home Page
					</div>
				</Link>
			</div>
			<Footer />
		</>
	);
};

export default OAuthErrorPage;
