import React from "react";
import Header from "components/home/Header";
import { Formik, Form, Field } from "formik";
import {
	initialChangePasswordValues,
	changePasswordSchema,
} from "modules/schemas/change-password-schema";
import { changePassword } from "../../../services/authService";
import { toast } from "react-toastify";
import { FormErrorMessage } from "components/forms/FormErrorMessage";
import SubmitButton from "components/forms/SubmitButton";

const ChangePassword = () => {
	const handleChangePasswordSubmit = async (oldPassword, newPassword) => {
		try {
			const { message } = await changePassword(oldPassword, newPassword);
			toast(`${message}`, {
				type: "success",
				autoClose: 3000,
			});
		} catch (e) {
			toast(e?.message, {
				type: "error",
				autoClose: 3000,
			});
		}
	};
	return (
		<div>
			<Header />

			{/* TITLE */}
			<p className="text-[25px] md:text-[33px] font-bold m-[10px] md:m-[40px] text-eb-primary">
				Password
			</p>

			<div className="m-[10px] md:m-[40px] w-[95%] md:w-[60%] bg-eb-secondary p-6 rounded-lg">
				<Formik
					initialValues={initialChangePasswordValues}
					validationSchema={changePasswordSchema}
					onSubmit={handleChangePasswordSubmit}
				>
					<Form>
						<div className="bg-white p-4 rounded-2xl">
							<p className="text-[#111827] text-[18px] font-bold">Password </p>
							<p className="text-[#718096] text-[14px] mb-3">
								Change or view your password.
							</p>

							<div>
								<p className="text-[#718096] mb-2">Old Password</p>
								<Field
									name="oldPassword"
									className="p-4 w-[100%] h-[56px] bg-[#FAFAFA] rounded-xl"
									type="password"
								/>
								<div>
									<FormErrorMessage name={"old_password"} />
								</div>
							</div>

							<div className="my-6">
								<p className="text-[#718096] mb-2">New Password</p>
								<Field
									name="newPassword"
									className="p-4 w-[100%]  h-[56px] bg-[#FAFAFA] rounded-xl"
									type="password"
								/>
								<div>
									<FormErrorMessage name={"new_password"} />
								</div>
								<p className="text-[#A0AEC0] mb-2 text-[12px]">Minimum 6 Characters</p>
							</div>

							<div className="my-8 flex justify-end">
								<SubmitButton className="bg-eb-primary rounded-xl text-white text-[14px] p-4 w-[120px] ">
									<p>Save Changes</p>
								</SubmitButton>
							</div>
						</div>
					</Form>
				</Formik>
			</div>
		</div>
	);
};

export default ChangePassword;
