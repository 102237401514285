import React from 'react';
import Header from 'components/home/Header';
import { Link } from 'react-router-dom';
import ROGTable from "./ROGTable";

const ReturnQuery = () => {
  return (
    <div>
        <Header />
        <div className="md:m-[40px] md:ml-[80px] m-4">
        <div className="bg-eb-secondary rounded-tl-md  md:rounded-tl-[30px] p-2 md:p-7">
      {/* TITLE */}

      <div className="flex justify-between mb-4">
        <p className="text-[22px] font-semibold">Return of Goods</p>

        <div className="flex gap-5">

          <Link to="rog-form">
            <div className="bg-eb-primary text-white rounded-xl h-10 w-[133px] grid justify-center content-center font-medium">
              <p>New Returns</p>
            </div>
          </Link>
        </div>
      </div>

      <div className='overflow-x-auto'>
        <ROGTable />
      </div>
    </div>
        </div>
        
     </div>
  )
}

export default ReturnQuery;