import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import Rating from "@mui/material/Rating";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Footer from "components/footer/Footer";
import Header from "components/home/Header";
import SingleProduct from "../../components/assets/single-product.png";
import HotImg from "../../components/assets/hotproduct.png";
import Loader from "components/loader/Loader";
import { addToCarts } from "redux/slices/cartSlice";
import { defaultProductPrice } from "../products/Products";
import { CurrencyFormatter } from "../../components/currency-formatter/CurrencyFormatter";

import httpService from "../../services/httpService";
import MediaQuery from "components/media-query/MediaQuery";

const Product = () => {
	const dispatch = useDispatch();
	const { productId } = useParams();
	const navigate = useNavigate();
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);

	const [product, setProduct] = useState();

	let [quantity, setQuantity] = useState(1);

	const [value, setValue] = useState(0);
	const [review, setReview] = useState("");
	const [reviews, setReviews] = useState([]);

	//for reviews
	const handleSubmit = async () => {
		try {
			const response = await httpService.post(`/product/create/review`, {
				rating: value,
				comment: review,
				productID: productId,
			});

			// Update reviews state with newly added review
			setReviews([...reviews, response.data]);

			// Clear form inputs
			setValue(0);
			setReview("");

			toast.success(response.data.message, {
				autoClose: 2000,
				position: "top-right",
			});
		} catch (error) {
			console.error("Error posting review:", error);

			toast.error("Failed to submit review. Please try again later.", {
				autoClose: 2000,
				position: "top-right",
			});
		}
	};

	useEffect(() => {
		const fetchProduct = async () => {
			try {
				const response = await httpService.get(`/product/${productId}`);
				setProduct(response.data);
				setLoading(false);
			} catch (error) {
				setError("No product found: Either out of stock or not available");
				console.error("Error fetching product:", error);
				setLoading(false);
			}
		};

		fetchProduct();
	}, [productId]);

	const [selectedType, setSelectedType] = useState(
		product?.variants[0]?.quantityType || "single",
	);
	const [selectedSize, setSelectedSize] = useState(
		product?.variants[0].item[0].size,
	);
	const [selectedPrice, setSelectedPrice] = useState(() => {
		const firstVariant = product?.variants[0];
		if (firstVariant && firstVariant.item && firstVariant.item[0]) {
			return firstVariant.item[0].price;
		}
		return 0;
	});

	const handleTypeChange = (event) => {
		const selectedQuantityType = event.target.value;
		setSelectedType(selectedQuantityType);

		const selectedVariant = product?.variants.find(
			(variant) => variant.quantityType === selectedQuantityType,
		);

		const defaultSize = selectedVariant.item[0].size;
		setSelectedSize(defaultSize);

		const selectedSizePrice = selectedVariant.item.find(
			(item) => item.size === defaultSize,
		).price;
		setSelectedPrice(selectedSizePrice);
	};

	const handleSizeChange = (event) => {
		const selectedSize = event.target.value;
		const selectedVariant = product?.variants.find((variant) =>
			variant.item.find((item) => item.size === selectedSize),
		);
		const selectedItem = selectedVariant.item.find(
			(item) => item.size === selectedSize,
		);
		setSelectedSize(selectedSize);
		setSelectedPrice(
			selectedVariant.item.find((item) => item.size === selectedSize).price,
		);
	};

	const selectedVariant = product?.variants.find(
		(variant) => variant.quantityType === selectedType,
	);

	const increaseQuantity = (e) => {
		e.preventDefault();
		quantity += 1;
		setQuantity(quantity);
	};

	const decreaseQuantity = (e) => {
		e.preventDefault();
		quantity > 1 ? setQuantity((quantity -= 1)) : setQuantity(quantity);
	};

	//add the item to the cart

	const addToCart = (e) => {
		e.preventDefault();
		let item = {
			id: productId,
			quantity: quantity,
			price: selectedPrice,
			size: selectedSize,
			name: product.name,
			image: product.images?.map((img) => img.url),
		};
		dispatch(addToCarts(item));
		navigate("/cart");
	};

	if (loading) {
		return <Loader />;
	}

	if (error) {
		// beautify error message by centering it and making the text bold
		return <div style={{ textAlign: "center", fontWeight: "bold" }}>{error}</div>;
	}

	return (
		<div className="min-h-screen flex flex-col">
			<div className="flex-grow">
				<Header />
				<div className="px-[80px] py-[40px]">
					{product ? (
						<>
							{/* small screen start */}
							<MediaQuery min="sm" max="md">
								<div className="flex flex-col gap-8">
									<div className="w-[300px] h-[50vh]">
										{product.images?.map((img, idx) => (
											<img
												key={idx}
												src={img.url}
												alt=""
												className="w-full h-full object-fit"
											/>
										))}
									</div>

									<div>
										<h4 className="uppercase text-2xl text-[#3F3F95]">{product.name}</h4>
										<div className="py-4">
											<Rating name="simple-controlled" value={product.ratings} readOnly />
										</div>
										<div>
											<p className="text-xl font-semibold">₦{selectedPrice}</p>
										</div>
										<div className="flex gap-7 items-center py-4">
											<div>
												<p className="text-lg text-[#464545">Quantity</p>
											</div>
											<div className="flex items-center">
												<button
													onClick={decreaseQuantity}
													className="p-2 border border-[#F7F7FC]"
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 24 24"
														width="24"
														height="24"
													>
														<path fill="none" d="M0 0h24v24H0z" />
														<path d="M5 11h14v2H5z" />
													</svg>
												</button>
												<button className="py-2 px-6 border border-[#F7F7FC]">
													{quantity}
												</button>
												<button
													onClick={increaseQuantity}
													className="p-2 border border-[#F7F7FC]"
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 24 24"
														width="24"
														height="24"
													>
														<path fill="none" d="M0 0h24v24H0z" />
														<path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" />
													</svg>
												</button>
											</div>
										</div>
										<p className="pt-4 text-lg text-[#464545]">
											Brand:{" "}
											<span className="ml-2 text-lg text-black">{product.brand.name}</span>
										</p>
										<div className="flex gap-7 items-center py-4">
											<div>
												<p className="text-lg">Size</p>
											</div>
											<div>
												<FormControl sx={{ m: 1, minWidth: 120 }} size="small">
													<Select
														value={selectedSize ? selectedSize : ""}
														disabled={!selectedType}
														onChange={handleSizeChange}
														displayEmpty
													>
														<MenuItem value="" disabled>
															{selectedType ? "Select a size" : "Select a quantity type first"}
														</MenuItem>
														{selectedVariant &&
															selectedVariant.item.map((itm) => (
																<MenuItem key={itm.size} value={itm.size}>
																	{itm.size}
																</MenuItem>
															))}
													</Select>
												</FormControl>
											</div>
										</div>
										<div>
											<FormControl>
												<RadioGroup row value={selectedType} onChange={handleTypeChange}>
													{product.variants.map((variant) => (
														<FormControlLabel
															key={variant.quantityType}
															value={variant.quantityType}
															control={<Radio />}
															label={variant.quantityType}
															labelPlacement="end"
														/>
													))}
												</RadioGroup>
											</FormControl>
										</div>
										<div className="flex gap-7">
											<div className="py-4">
												<button
													onClick={addToCart}
													className="px-4 py-2 bg-[#3F3F95] rounded-md hover:bg-black text-white text-sm font-semibold"
												>
													BUY NOW
												</button>
											</div>
											<div className="py-4">
												<button
													onClick={addToCart}
													className="px-4 py-2 bg-[#3F3F95] rounded-md hover:bg-black text-white text-sm font-semibold"
												>
													ADD TO CART
												</button>
											</div>
										</div>
										<div>
											<h4 className="text-xl text-[#3F3F95] py-5">Benefits</h4>
											{product.benefits?.map((bnf, indx) => (
												<div ket={indx} className="flex gap-9 items-center mb-2">
													<span>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 24 24"
															width="24"
															height="24"
														>
															<path fill="none" d="M0 0h24v24H0z" />
															<path d="M12.9 6.858l4.242 4.243L7.242 21H3v-4.243l9.9-9.9zm1.414-1.414l2.121-2.122a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414l-2.122 2.121-4.242-4.242z" />
														</svg>
													</span>
													<p>{bnf}</p>
												</div>
											))}
										</div>
									</div>
								</div>
							</MediaQuery>
							{/* small screen end */}

							{/* larger screen start */}
							<MediaQuery min="md">
								<div className="flex gap-8">
									<div className="w-[500px] h-[70vh]">
										{product.images?.map((img, idx) => (
											<img
												key={idx}
												src={img.url}
												alt=""
												className="w-full h-full object-fit"
											/>
										))}
									</div>

									<div>
										<h4 className="uppercase text-2xl text-[#3F3F95]">{product.name}</h4>
										<div className="py-4">
											<Rating name="simple-controlled" value={product.ratings} readOnly />
										</div>
										<div>
											<p className="text-xl font-semibold">₦{selectedPrice}</p>
										</div>
										<div className="flex gap-7 items-center py-4">
											<div>
												<p className="text-lg text-[#464545">Quantity</p>
											</div>
											<div className="flex items-center">
												<button
													onClick={decreaseQuantity}
													className="p-2 border border-[#F7F7FC]"
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 24 24"
														width="24"
														height="24"
													>
														<path fill="none" d="M0 0h24v24H0z" />
														<path d="M5 11h14v2H5z" />
													</svg>
												</button>
												<button className="py-2 px-6 border border-[#F7F7FC]">
													{quantity}
												</button>
												<button
													onClick={increaseQuantity}
													className="p-2 border border-[#F7F7FC]"
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 24 24"
														width="24"
														height="24"
													>
														<path fill="none" d="M0 0h24v24H0z" />
														<path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" />
													</svg>
												</button>
											</div>
										</div>
										<p className="pt-4 text-lg text-[#464545]">
											Brand:{" "}
											<span className="ml-2 text-lg text-black">{product.brand.name}</span>
										</p>
										<div className="flex gap-7 items-center py-4">
											<div>
												<p className="text-lg">Size</p>
											</div>
											<div>
												<FormControl sx={{ m: 1, minWidth: 120 }} size="small">
													<Select
														value={selectedSize ? selectedSize : ""}
														disabled={!selectedType}
														onChange={handleSizeChange}
														displayEmpty
													>
														<MenuItem value="" disabled>
															{selectedType ? "Select a size" : "Select a quantity type first"}
														</MenuItem>
														{selectedVariant &&
															selectedVariant.item.map((itm) => (
																<MenuItem key={itm.size} value={itm.size}>
																	{itm.size}
																</MenuItem>
															))}
													</Select>
												</FormControl>
											</div>
										</div>
										<div>
											<FormControl>
												<RadioGroup row value={selectedType} onChange={handleTypeChange}>
													{product.variants.map((variant) => (
														<FormControlLabel
															key={variant.quantityType}
															value={variant.quantityType}
															control={<Radio />}
															label={variant.quantityType}
															labelPlacement="end"
														/>
													))}
												</RadioGroup>
											</FormControl>
										</div>
										<div className="flex gap-7">
											<div className="py-4">
												<button
													onClick={addToCart}
													className="px-4 py-2 bg-[#3F3F95] rounded-md hover:bg-black text-white text-sm font-semibold"
												>
													BUY NOW
												</button>
											</div>
											<div className="py-4">
												<button
													onClick={addToCart}
													className="px-4 py-2 bg-[#3F3F95] rounded-md hover:bg-black text-white text-sm font-semibold"
												>
													ADD TO CART
												</button>
											</div>
										</div>
										<div>
											<h4 className="text-xl text-[#3F3F95] py-5">Benefits</h4>
											{product.benefits?.map((bnf, indx) => (
												<div ket={indx} className="flex gap-9 items-center mb-2">
													<span>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 24 24"
															width="24"
															height="24"
														>
															<path fill="none" d="M0 0h24v24H0z" />
															<path d="M12.9 6.858l4.242 4.243L7.242 21H3v-4.243l9.9-9.9zm1.414-1.414l2.121-2.122a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414l-2.122 2.121-4.242-4.242z" />
														</svg>
													</span>
													<p>{bnf}</p>
												</div>
											))}
										</div>
									</div>
								</div>
							</MediaQuery>
							{/* larger screen end */}
							<div>
								<h4 className="text-xl text-center py-4 font-semibold text-[#3F3F95]">
									Description
								</h4>

								<div className="flex justify-center">
									<p className="w-[700px] text-[#3F3F95] text-center">
										{product.description}
									</p>
								</div>
							</div>
							<div className="py-4">
								<h4 className="text-xl text-center py-4 font-semibold text-[#3F3F95]">
									How to Use
								</h4>
								<div className="flex justify-center">
									<p className="w-[700px] text-[#3F3F95] text-center">
										On day one, apply to damp hair, rake through, and style. On day two
										(and so on), apply to dry hair to refresh and restyle.
									</p>
								</div>
							</div>
							<div className="py-4">
								<h4 className="text-xl text-center py-4 font-semibold text-[#3F3F95]">
									Review
								</h4>
								<div className="flex justify-center gap-7">
									<div>
										<Rating name="simple-controlled" value={product.ratings} readOnly />
									</div>
									<div className="text-[#3F3F95]">{product.ratings}</div>
									<div className="text-[#3F3F95]">{product.reviews.length} Reviews</div>
								</div>

								<div className="flex justify-center py-4">
									<Popup
										trigger={
											<button className="text-[#3F3F95] hover:text-white hover:bg-[#3F3F95] rounded-md border border-[#3F3F95] p-2">
												Write a Review
											</button>
										}
										overlayStyle={{
											background: "rgba(0,0,0,0.45)",
										}}
										contentStyle={{
											width: "600px",
											maxHeight: "calc(100vh - 100px)",
										}}
										closeOnDocumentClick
										modal
									>
										<div className="w-full bg-[#F5F5F5] rounded-md">
											<div className="p-8">
												<div>
													<div className="mb-4">
														<p className="text-[#3F3F95]">You can rate us here</p>
														<div>
															<Rating
																name="simple-controlled"
																value={value}
																onChange={(event, newValue) => {
																	setValue(newValue);
																}}
															/>
														</div>
													</div>
													<div>
														<label className="text-[#3F3F95]">Write Review</label>
														<textarea
															className="w-full px-3 py-2 text-gray-700 border border-[#3F3F95] rounded-lg focus:outline-none resize-none"
															rows="4"
															value={review}
															onChange={(e) => setReview(e.target.value)}
														></textarea>
													</div>
													<div className="text-center py-4">
														<button
															onClick={handleSubmit}
															className="bg-[#3F3F95] py-2 px-6 rounded-md text-white hover:bg-black"
														>
															Submit
														</button>
													</div>
												</div>
											</div>
										</div>
									</Popup>
								</div>
								<div className="flex justify-center">
									<div className="flex flex-col py-4">
										{product.reviews.length > 0 ? (
											<>
												<div className="flex items-center gap-7 mb-4">
													<div className="flex items-center ">
														<p>1</p>
														<span>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																viewBox="0 0 24 24"
																width="24"
																height="24"
															>
																<path fill="none" d="M0 0h24v24H0z" />
																<path d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z" />
															</svg>
														</span>
													</div>
													<div className="w-[300px]">
														<div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
															<div
																className="bg-[#5F5FA7] h-2.5 rounded-full"
																style={{ width: "45%" }}
															></div>
														</div>
													</div>
													<div>
														<p className="text-[#3F3F95]">
															{product.reviewSummary[1]} Reviews
														</p>
													</div>
												</div>
												<div className="flex items-center gap-7 mb-4">
													<div className="flex items-center ">
														<p>2</p>
														<span>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																viewBox="0 0 24 24"
																width="24"
																height="24"
															>
																<path fill="none" d="M0 0h24v24H0z" />
																<path d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z" />
															</svg>
														</span>
													</div>
													<div className="w-[300px]">
														<div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
															<div
																className="bg-[#5F5FA7] h-2.5 rounded-full"
																style={{ width: "45%" }}
															></div>
														</div>
													</div>
													<div>
														<p className="text-[#3F3F95]">
															{product.reviewSummary[2]} Reviews
														</p>
													</div>
												</div>
												<div className="flex items-center gap-7 mb-4">
													<div className="flex items-center ">
														<p>3</p>
														<span>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																viewBox="0 0 24 24"
																width="24"
																height="24"
															>
																<path fill="none" d="M0 0h24v24H0z" />
																<path d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z" />
															</svg>
														</span>
													</div>
													<div className="w-[300px]">
														<div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
															<div
																className="bg-[#5F5FA7] h-2.5 rounded-full"
																style={{ width: "45%" }}
															></div>
														</div>
													</div>
													<div>
														<p className="text-[#3F3F95]">
															{product.reviewSummary[3]} Reviews
														</p>
													</div>
												</div>
												<div className="flex items-center gap-7 mb-4">
													<div className="flex items-center ">
														<p>4</p>
														<span>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																viewBox="0 0 24 24"
																width="24"
																height="24"
															>
																<path fill="none" d="M0 0h24v24H0z" />
																<path d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z" />
															</svg>
														</span>
													</div>
													<div className="w-[300px]">
														<div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
															<div
																className="bg-[#5F5FA7] h-2.5 rounded-full"
																style={{ width: "45%" }}
															></div>
														</div>
													</div>
													<div>
														<p className="text-[#3F3F95]">
															{product.reviewSummary[4]} Reviews
														</p>
													</div>
												</div>
												<div className="flex items-center gap-7 mb-4">
													<div className="flex items-center ">
														<p>5</p>
														<span>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																viewBox="0 0 24 24"
																width="24"
																height="24"
															>
																<path fill="none" d="M0 0h24v24H0z" />
																<path d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z" />
															</svg>
														</span>
													</div>
													<div className="w-[300px]">
														<div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
															<div
																className="bg-[#5F5FA7] h-2.5 rounded-full"
																style={{ width: "45%" }}
															></div>
														</div>
													</div>
													<div>
														<p className="text-[#3F3F95]">
															{product.reviewSummary[5]} Reviews
														</p>
													</div>
												</div>
											</>
										) : (
											<p>No reviews yet.</p>
										)}
									</div>
								</div>
								<div className="flex justify-center">
									{product.reviews.length > 0 ? (
										product.reviews.map((review, index) => (
											<div
												className="border border-[#3F3F95] rounded-md p-3 my-2"
												key={index}
											>
												<p className="text-[#3F3F95] w-[700px] text-lg ">
													{review.comment}
												</p>
												<div className="flex items-center gap-9 mt-4">
													<div>
														<p>{review.name}</p>
													</div>
													<div>
														<Rating name="simple-controlled" value={review.rating} readOnly />
													</div>
												</div>
											</div>
										))
									) : (
										<p>No reviews yet.</p>
									)}
								</div>

								<div className="mt-7">
									<h4 className="text-xl py-4 text-center text-[#3F3F95] font-semibold">
										Similar Products
									</h4>
									<div className="flex gap-5">
										<div className="w-[300px] bg-white shadow-xl rounded-md">
											<div className="p-4">
												<div className="relative">
													<img
														src={HotImg}
														alt="hotimage"
														className="w-[290px] h-[300px] object-fit"
													/>
													<div className="bg-black/[.2] p-2 rounded-md absolute bottom-1 right-1 text-white">
														In Stock
													</div>
												</div>
												<div className="flex justify-between py-4">
													<p>Lemonfresh Advanced Therapy</p>
													<p className="text-black font-semibold">N6,000</p>
												</div>
												<p>
													Brand: <span className="font-semibold">Topsy</span>
												</p>
												<div>
													<Rating name="read-only" value={3} readOnly />
												</div>
												<div className="flex justify-between gap-4 items-center">
													<div>
														<button className="mt-2 p-2 px-4 rounded-md border border-[#3F3F95] text-[#3F3F95] hover:bg-[#3F3F95] hover:text-white">
															Buy Now
														</button>
													</div>
													<div>
														<IconButton aria-label="add to favorites">
															<FavoriteIcon />
														</IconButton>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="text-center rounded-md py-7">
										<button className="bg-[#3F3F95] hover:bg-black py-2 px-6 text-white">
											Shop for more
										</button>
									</div>
								</div>
							</div>
						</>
					) : (
						<p className="text-lg p-4 font-semibold">Something went wrong</p>
					)}
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default Product;
