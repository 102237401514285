import React, { useState, useEffect } from "react";
import Header from "components/home/Header";
import Stepper from "react-stepper-horizontal";
import { useParams } from "react-router-dom";
import { singleUserOrder } from "services/myOrderCRUD";
import Footer from "components/footer/Footer";
import { CurrencyFormatter } from "components/currency-formatter/CurrencyFormatter";

const OrderDetails = () => {
	const [singleOrder, setSingleOrder] = useState({});

	const { id } = useParams();

	//GET SINGLE USER ORDER
	useEffect(() => {
		const getSingleOrderData = async () => {
			try {
				const response = await singleUserOrder(id);

				setSingleOrder(response.data);
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};
		getSingleOrderData();
	}, [id]);
	const myCurrentStep = () => {
		switch (singleOrder.orderStatus) {
			case "placed":
				return 0;
			case "packed":
				return 1;
			case "shipped":
				return 2;
			case "delivered":
				return 3;
			default:
				return 0;
		}
	};

	const getDate = singleOrder?.createdAt;
	const dateObj = new Date(getDate);
	const myOptions = {
		weekday: "short",
		month: "short",
		day: "numeric",
		year: "numeric",
	};

	const formattedDate = dateObj.toLocaleDateString("en-US", myOptions);
	const formattedTime = dateObj.toLocaleTimeString("en-US", {
		hour: "2-digit",
		minute: "2-digit",
	});

	return (
		<>
			<Header />
			<div
				className="bg-eb-secondary min-h-[100vh] "
				style={{ overflowY: "auto" }}
			>
				{/* <Header /> */}

				<div className="md:m-[40px] md:ml-[80px] m-4   ">
					{/* TITLE  */}

					<div className="flex justify-between">
						<div>
							<p className="text-[22px] font-bold text-eb-primary">Order Details</p>
						</div>
					</div>

					<div>
						{/* TRACK ORDER, DELIVER INFO */}
						<div className="grid md:grid-cols-3 gap-4 mb-5">
							<div className="bg-[#FEFEFE] rounded-lg p-3 col-span-2">
								<div className="flex justify-between">
									<p>Track Order:</p>
									<p className="text-[#656565]">
										Tracking ID :{singleOrder?.trackingId}
									</p>
								</div>

								<div>
									<div>
										<Stepper
											steps={[
												{ title: "Order Placed" },
												{ title: "Packed" },
												{ title: "Shipped" },
												{ title: "Delivered" },
											]}
											activeColor={"#3F3F95"}
											completeColor={"#3F3F95"}
											completeBarColor={"#3F3F95"}
											circleFontSize={14}
											titleFontSize={14}
											activeTitleColor={"#3F3F95"}
											completeTitleColor={"#3F3F95"}
											size={25}
											activeStep={myCurrentStep()}
										/>
									</div>
								</div>

								<div className="grid justify-center mt-5 ">
									<p className="text-[#1E1E1E] text-xs ">{formattedDate}</p>
									<p className="text-[#1E1E1E] text-xs text-center"> {formattedTime}</p>
								</div>
							</div>
						</div>

						{/* ORDER, SHIPPING INFORMATION, BILLING INFORMATION */}

						<div className="grid md:grid-cols-5 gap-4">
							<div className="col-span-3 bg-[#FEFEFE] rounded-lg p-3 ">
								{singleOrder?.orderItems?.map(
									({ name, price, size, quantity, image, _id }, index) => {
										return (
											<div key={_id} className="flex gap-4 mb-4">
												<div className="bg-[#EFEFEF] w-[114px] h-[82px] rounded-xl grid justify-center content-center">
													{image ? (
														<img
															src={image}
															alt={name}
															className="w-full h-full object-cover rounded-xl"
															onError={(e) => {
																e.target.src =
																	'data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36"  fill="rgba(197,197,197,1)" viewBox="0 0 24 24" ><path d="M5 11.1005L7 9.1005L12.5 14.6005L16 11.1005L19 14.1005V5H5V11.1005ZM5 13.9289V19H8.1005L11.0858 16.0147L7 11.9289L5 13.9289ZM10.9289 19H19V16.9289L16 13.9289L10.9289 19ZM4 3H20C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3ZM15.5 10C14.6716 10 14 9.32843 14 8.5C14 7.67157 14.6716 7 15.5 7C16.3284 7 17 7.67157 17 8.5C17 9.32843 16.3284 10 15.5 10Z" /></svg>';
															}}
														/>
													) : (
														<svg
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 24 24"
															width="36"
															height="36"
														>
															<path
																d="M5 11.1005L7 9.1005L12.5 14.6005L16 11.1005L19 14.1005V5H5V11.1005ZM5 13.9289V19H8.1005L11.0858 16.0147L7 11.9289L5 13.9289ZM10.9289 19H19V16.9289L16 13.9289L10.9289 19ZM4 3H20C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3ZM15.5 10C14.6716 10 14 9.32843 14 8.5C14 7.67157 14.6716 7 15.5 7C16.3284 7 17 7.67157 17 8.5C17 9.32843 16.3284 10 15.5 10Z"
																fill="rgba(197,197,197,1)"
															></path>
														</svg>
													)}
												</div>

												<div className="text-[#1E1E1E] grid  md:grid-cols-6 text-[14px] gap-4 content-center">
													<p className="col-span-3"> {name} </p>
													<p className="col-span-1">{size}</p>
													<p className="col-span-1">{quantity}x</p>
													<p className="col-span-1">₦{CurrencyFormatter(price)}</p>
												</div>
											</div>
										);
									},
								)}

								<div className="w-[60%] mt-8 text-[14px] ">
									<div className="text-[#1E1E1E] flex justify-between mb-4">
										<p> Sub Total : </p>
										<p> ₦{CurrencyFormatter(singleOrder?.itemsPrice)}</p>
									</div>

									<div className="text-[#1E1E1E] flex justify-between mb-4">
										<p> Shipping Charge : </p>
										<p> ₦{CurrencyFormatter(singleOrder?.shippingPrice)}</p>
									</div>

									<div className="text-[#1E1E1E] flex justify-between mb-4">
										<p> Estimated Tax : </p>
										<p> ₦{CurrencyFormatter(singleOrder?.taxPrice)} </p>
									</div>

									<div className="text-[#656565] font-semibold text-[18px] flex justify-between mb-4">
										<p> Total : </p>
										<p> ₦{CurrencyFormatter(singleOrder?.grandTotalPrice)}</p>
									</div>
								</div>
							</div>

							<div className="col-span-2 ">
								{/* SHIPPING INFORMATION */}
								<div className="bg-[#FEFEFE] rounded-lg p-3 mb-4">
									<p className="text-[#1E1E1E] font-semibold">Shipping Information :</p>

									<div className=" text-[#656565] mt-[8px] text-sm">
										<p className="font-semibold">Name:</p>
										<p>{singleOrder?.user?.name}</p>
									</div>
									<div className=" text-[#656565] mt-[8px] text-sm">
										<p className="font-semibold">Address:</p>
										<p>
											{singleOrder?.shippingInfo?.address},{" "}
											{singleOrder?.shippingInfo?.city}, {singleOrder?.shippingInfo?.state}
										</p>
									</div>

									<div className=" text-[#656565] mt-[8px] text-sm">
										<p className="font-semibold">Phone</p>
										<p>{singleOrder?.customerPhoneNumber}</p>
									</div>

									<div className=" text-[#656565] mt-[8px] text-sm">
										<p className="font-semibold">Email</p>
										<p>{singleOrder?.user?.email}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default OrderDetails;
