import React from "react";
import SliderTab from "./slider";

const Carousel = ({ children }) => {
  return (
    // <div className="h-screen overflow-hidden lg:h-screen grid md:grid-cols-2  ">
    //   <div className=" hidden h-screen md:grid justify-center content-center ">
    //     <div className="grid content-center justify-center w-[40vw] h-[90vh] ">
    //       <div className="w-[37vw] h-[90vh] ">
    //         <SliderTab className="" />
    //       </div>
    //     </div>
    //   </div>

    //   <div className="grid content-center  pr-[5vw]">{children}</div>
    // </div>
    <>
    <div className="w-full p-4">
      <div className="">
        
        {children}
      </div>
      </div>
    </>
  );
};

export default Carousel;
