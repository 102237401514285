import { useState } from "react";
import { useLocation } from "react-router-dom";
import Google from "../../assets/googleLogoWhite.svg";
import GoogleGrey from "../../assets/googleLogoGrey.svg";
import Facebook from "../../assets/facebookLogoWhite.svg";
import FacebookGrey from "../../assets/facebookLogo.svg";
import Apple from "../../assets/appleLogoWhite.svg";
import AppleGrey from "../../assets/appleLogo.svg";
import { useNavigate } from "react-router-dom";
import { baseURL } from "constants/url";
import { getGoogleConsent } from "utils/getGoogleConsent";

const ButtonGroup = () => {
	const [activeButton, setActiveButton] = useState(0);
	const navigate = useNavigate();
	const location = useLocation();
	const from = location.state?.from || "/login";
	const buttons = [
		{
			id: 0,
			text: "google",
			image: Google,
			image2: GoogleGrey,
			description: "Sign up with Google",
			link: getGoogleConsent(from),
		},
		{
			id: 1,
			text: "apple",
			image: Apple,
			image2: AppleGrey,
			description: "Sign Up with Apple",
			link: null,
		},
		{
			id: 2,
			text: "facebook",
			image: Facebook,
			image2: FacebookGrey,
			description: "Sign up with Facebook",
			link: null,
		},
	];

	const handleClick = (id, link) => {
		// if link is null show alert("coming soon")
		// setActiveButton(id);
		// if (link === null) {
		// 	alert("Hey there! This feature is coming soon. Stay tuned!");
		// } else {
		// 	window.location.href = link;
		// }
		alert(
			"Hey there champion! This feature is coming soon. Stay tuned!. You can sign up with email and password for now",
		);
	};

	return (
		<div className="w-full flex  items-center">
			{buttons.map((button) => (
				<div
					style={{ cursor: "pointer" }}
					key={button.id}
					className={` flex gap-3 justify-center items-center h-[38px] rounded-lg mr-3 ${
						activeButton === button.id
							? "  w-5/6  bg-eb-primary "
							: "w-[36px] bg-[#9F9FCA] hover:bg-eb-primary border-none "
					}`}
					onClick={() => handleClick(button.id, button.link)}
				>
					<button
						className={` focus:outline-none ${
							activeButton === button.id ? "font-bold" : "font-medium"
						}`}
					>
						{activeButton === button.id ? (
							<img src={button.image} alt={button.text} />
						) : (
							<img src={button.image2} alt="signupoption" />
						)}
					</button>
					{activeButton === button.id && (
						<p className=" text-sm text-white font-bold">{button.description}</p>
					)}
				</div>
			))}

			{/* <div>
  

const [expandedButton, setExpandedButton] = useState(null);



const firstButton = buttons[0];
 

  const handleClick = (buttonIndex) => { 
    setExpandedButton(buttonIndex);
  };




{buttons.map((button, index) => (
        <div
          key={index}
          className={`flex gap-3   justify-center items-center border h-[35px] border-eb-primary  rounded-lg mx-3 ${
            expandedButton ===  index ? "w-[412px]" : "w-[36px] bg-[#9F9FCA] hover:bg-eb-primary border-none "
          }`}
          onClick={() => handleClick(index)}
        >
        <img src={button.text} alt='textree' />
         
          {expandedButton ===    index && (
            <p className="text-sm text-eb-primary font-bold">{button.description}</p>
          )}
        </div>
      ))}
  </div> */}

			{/* <div>
      <div className="flex justify-center">
  <button className="px-4 py-2 bg-blue-500 text-white rounded-md mr-2 transition-all flex-shrink hover:flex-grow focus:flex-grow" onClick={() => console.log("Button 1 clicked")}>
    Button 1
  </button>
  <button className="px-4 py-2 bg-blue-500 text-white rounded-md mr-2 transition-all flex-shrink hover:flex-grow focus:flex-grow" onClick={() => console.log("Button 2 clicked")}>
    Button 2
  </button>
  <button className="px-4 py-2 bg-blue-500 text-white rounded-md mr-2 transition-all flex-shrink hover:flex-grow focus:flex-grow" onClick={() => console.log("Button 3 clicked")}>
    Button 3
  </button>
</div>
      </div> */}
		</div>
	);
};

export default ButtonGroup;
