import httpService from "./httpService";

export async function userOrder(page, limit, keyword) {
	try {
		const response = await httpService.get("/user-order/my/all");
		return response; 
	} catch (error) {
		throw new Error(error.message);
	}
}
export async function userInvoices(page, limit, keyword) {
	let url = `/invoice/user/invoices`;
	if (page) url += `?page=${page}`;
	if (limit) url += `&limit=${limit}`;
	if (keyword) url += `&keyword=${encodeURIComponent(keyword)}`;
	try {
		const response = await httpService.get(url);
		return response; 
	} catch (error) {
		throw new Error(error.message);
	}
}

export async function singleUserOrder(id) {
	try {
		const response = await httpService.get(`/user-order/${id}/single`);
		return response;
	} catch (error) {
		throw new Error(error.message);
	}
}

export async function createReturnOrd(payload) {
	const {
		uniqueIdentifier,
		itemName,
		quantity,
		acceptPolicy,
		reasonForReturn,
		description,
	} = payload;

	try {
		const response = await httpService.post("/return-order/create/new", {
			uniqueIdentifier,
			itemName,
			quantity,
			acceptPolicy,
			reasonForReturn,
			description,
		});
		return response;
	} catch (error) {
		throw new Error(error.message);
	}
}

export async function getAllReturnOrder() {
	try {
		const response = await httpService.get(`/return-order/fetch/user-returns`);
		return response;
	} catch (error) {
		throw new Error(error.message);
	}
}

export async function getASingleReturnOrder(id) {
	try {
		const response = await httpService.get(`/return-order/${id}/view`);
		return response;
	} catch (error) {
		throw new Error(error.message);
	}
}
