import * as Yup from "yup";

export const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    country: "",
    phone: "",
    message: "",
    captcha: "",
  };


export const ContactUsSchema = Yup.object().shape({
    firstname: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  lastname: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
    email: Yup.string()
    .email("Invalid email format")
    .trim()
    .required("The email field is required"),
    phone: Yup.number()
    .typeError("Only digit(s) is allowed")
    .required("phone number is required"),
    message: Yup.string()
    .min(10, "Too Short"),
});