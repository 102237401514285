import httpService from "./httpService";

export async function getAllCategories() {
	try {
		const response = await httpService.get("/category/all");
		return response;
	} catch (error) {
		throw new Error(error.message);
	}
}

export async function getACategory(id) {
	try {
		let url = `/category/${id}`;
		const res = await httpService.get(url);
		return res;
	} catch (error) {
		throw new Error(error.message);
	}
}

export async function viewProductByCategory(id) {
	try {
		let url = `/product/category/${id}`;
		// if (page) url += `?page=${page}`;
		// if (limit) url += `&limit=${limit}`;
		const res = await httpService.get(url);
		return res;
	} catch (error) {
		throw new Error(error.message);
	}
}
