import React, { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useParams } from "react-router-dom";
import httpService from "../../../services/httpService";
import { InvoiceComponent } from "./InvoiceComponent";
import Loader from "components/loader/Loader";
import Footer from "components/footer/Footer";
import Header from "components/home/Header";
import { Container, Box, Button } from "@mui/material";

const Invoice = () => {
	const [loading, setLoading] = useState(true);
	const [invoice, setInvoice] = useState({});
	const [error, setError] = useState(null);
	const { id: invoiceId } = useParams();

	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	useEffect(() => {
		const fetchInvoice = async () => {
			try {
				setLoading(true);
				const response = await httpService.get(
					`/invoice/e-com/${invoiceId}/single`,
				);

				setInvoice(response.invoice);
			} catch (error) {
				console.error("Error fetching invoice:", error);
				setError("Failed to fetch invoice");
			} finally {
				setLoading(false);
			}
		};

		fetchInvoice();
	}, [invoiceId]);

	if (loading) {
		return <Loader />;
	}

	if (error) {
		return <div>Error: {error}</div>;
	}

	return (
		<>
			<Header />
			<Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
				<Box
					ref={componentRef}
					sx={{
						p: 2,
						border: "1px solid #ccc",
						borderRadius: 2,
						backgroundColor: "#fff",
					}}
				>
					<InvoiceComponent invoice={invoice} loading={loading} error={error} />
				</Box>
				<Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
					<Button variant="contained" color="primary" onClick={handlePrint}>
						Print Invoice
					</Button>
				</Box>
			</Container>
			<Footer />
		</>
	);
};

export default Invoice;
