import React from 'react';
import Header from 'components/home/Header';

const GeneralAccount = () => {
  return (
    <div>
      <Header />
       
        {/* TITLE */}
        <p className='text-[33px] font-bold m-[40px] text-eb-primary'>General Account</p>

       <div className='m-[40px] w-[60%] bg-eb-secondary p-6 rounded-lg'>
         <div className='bg-white p-4 rounded-2xl'>
           <p className='text-[#111827] text-[20px] font-bold'>My Personal Information </p>
           <hr className='my-6' />

           <div className='my-6'>
               Picture
               </div>

               {/* FORM */}

               <div>
               <div className='grid grid-cols-2 mb-6 gap-6'>
               <div>
                   <p className='text-[#718096] mb-2'>First Name</p>
                    <input className='p-4 w-[100%] h-[56px] bg-[#FAFAFA] rounded-xl' placeholder='Joshua'  />
                   </div>


                   <div>
                   <p className='text-[#718096] mb-2'>Last Name</p>
                    <input className='p-4 w-[100%]  h-[56px] bg-[#FAFAFA] rounded-xl' placeholder='Eze'  />
                   </div>
                   </div>





                   <div className='grid grid-cols-2 mb-6 gap-6'>
               <div>
                   <p className='text-[#718096] mb-2'>Email</p>
                    <input className='p-4 w-[100%] h-[56px] bg-[#FAFAFA] rounded-xl' placeholder='joshuaeze@gmail.com'  />
                   </div>


                   <div>
                   <p className='text-[#718096] mb-2'>Phone Number (Optional)</p>
                    <input className='p-4 w-[100%]  h-[56px] bg-[#FAFAFA] rounded-xl' placeholder='(234) 813-867-76832'  />
                   </div>
                   </div>



                   <p className='text-[#111827] text-[20px] font-bold  mb-5'>Personal Address</p>

                   <div className='grid grid-cols-2 mb-6 gap-6'>
               <div>
                   <p className='text-[#718096] mb-2'>Country or Region</p>
                    <input className='p-4 w-[100%] h-[56px] bg-[#FAFAFA] rounded-xl' placeholder='Nigeria'  />
                   </div>


                   <div>
                   <p className='text-[#718096] mb-2'>City</p>
                    <input className='p-4 w-[100%]  h-[56px] bg-[#FAFAFA] rounded-xl' placeholder='Abuja'  />
                   </div>
                   </div>

                   <div className='grid grid-cols-2 mb-6 gap-6'>
               <div>
                   <p className='text-[#718096] mb-2'>Address</p>
                    <input className='p-4 w-[100%] h-[56px] bg-[#FAFAFA] rounded-xl' placeholder='House 39, Churchlong Street, Wuse, Abuja.'  />
                   </div>


                   <div>
                   <p className='text-[#718096] mb-2'>Postal Code</p>
                    <input className='p-4 w-[100%]  h-[56px] bg-[#FAFAFA] rounded-xl' placeholder='000000'  />
                   </div>
                   </div>



                   





               </div>


               <div className='my-8 flex justify-end'>
                   <div className='bg-eb-primary rounded-xl text-white text-[14px] p-4 w-[103px] '> 
                       <p>Edit Profile</p>
                   </div>

               </div>


         </div>
       </div>



    </div>
  )
}

export default GeneralAccount;