import React, { useState, useEffect, useMemo } from 'react';
import { useTable, usePagination  } from 'react-table';
import { getAllReturnOrder } from 'services/myOrderCRUD';
import { useNavigate } from 'react-router-dom';


const ROGTable = () => {
    const [totalReturn, setTotalReturn] = useState([]);
	const navigate = useNavigate();

	// GET ALL SHIPMENT

	useEffect(() => {
		const getAllReturnData = async () => {
			try {
				const response = await getAllReturnOrder();
				setTotalReturn(response.data);
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};
		getAllReturnData();
	}, []);

    const columns = useMemo(
		() => [
			{ Header: "Order Number", accessor: "order.orderNumber" },
			{ Header: "Acceptance Policy ", accessor: "acceptPolicy" },
			{
				Header: "Shipment Tracking No.",
				accessor: "order.shipmentTrackingNumber",
			},
			{ Header: "Return Status", accessor: "returnStatus" },
			{ Header: "Products", accessor: "itemName" },
			{ Header: "Quantity", accessor: "quantity" },
			{ Header: "Reasons", accessor: "reasonForReturn" },
			{ Header: "Tracking No", accessor: "returnTrackingNumber" },
			// { Header: "Created date", accessor: "createdAt" },
		],
		[],
	);

    const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		prepareRow,
		state,
		
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		pageOptions,
		gotoPage,
	} = useTable(
		{
			columns,
			data: totalReturn,
			initialState: { pageIndex: 0, pageSize: 10 },
		},
		
		usePagination,
	);



  return (
    <> 
     	<table {...getTableProps()} className="md:w-[100%] overflow-x-auto">
				<thead
					style={{
						fontSize: "12px",
					}}
				>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th {...column.getHeaderProps()} className="text-start  font-normal pr-8 md:pr-0">
									{column.render("Header")}

									<hr className="mt-4" />
								</th>
							))}
						</tr>
					))}
				</thead>

				<tbody {...getTableBodyProps()}>
					{page.map((row) => {
						prepareRow(row);
						const rowData = row.original;
						const rId = rowData._id;
						const myAcceptancePolicy = rowData.acceptPolicy;

						return (
							<tr
								{...row.getRowProps()}
								className="py-7 hover:bg-[#F3F3FB] cursor-pointer pr-8 md:pr-0"
								
							>
								{row.cells.map((cell) => (
									<td
										{...cell.getCellProps()}
										style={{
											fontSize: "12px",
										}}
										className="py-4"
									>
										{" "}
										{cell.column.id === "acceptPolicy" ? (
											<>{myAcceptancePolicy === true ? <p> Yes</p> : <p> No</p>} </>
										) : (
											<span> {cell.render("Cell")} </span>
										)}
									</td>
								))}
							</tr>
						);
					})}
				</tbody>
			</table>
			<div className="flex gap-3 mt-5">
				<button
					onClick={() => previousPage()}
					disabled={!canPreviousPage}
					className=""
				>
					Previous
				</button>
				<div>
					Page{" "}
					<input
						type="number"
						value={state.pageIndex + 1}
						onChange={(e) => {
							const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
							gotoPage(pageNumber);
						}}
						className="border w-10 "
					/>{" "}
					of {pageOptions.length}
				</div>
				<button onClick={() => nextPage()} disabled={!canNextPage}>
					Next
				</button>
			</div>
    </>
  )
}

export default ROGTable;