import * as Yup from "yup";

export const initialValues = {
  email: "",
  password: "",
  firstName: "",
  lastName: "",
  country: "",
  phone: "",
};

export const RegisterSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email format")
    .trim()
    .required("The email field is required"),
  password: Yup.string()
    .min(6, "Password must be at least six (6) characters.")
    .required('password is required')
    ,
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  phone: Yup.number()
    .typeError("Only digit(s) is allowed")
    .required("phone number is required"),
    confirm_password: Yup.string().oneOf([Yup.ref("password"),
     null], "Passwords don't match").required('confirm password is required'),
});
